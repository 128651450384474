import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Table,
  Button
} from "reactstrap";
import { API } from "aws-amplify";
import { getTrack, getChamp, resultsByTrack } from "graphql/queries";
import { getNotDeletedItems } from "utils/Api";
import { formatRaceTime } from "utils/Constants";
import { champJoinsByChampAndType } from "graphql/queries";
import Select from "react-select";
import { tracksByChamp } from "graphql/queries";

const TrackLobbies = (props) => {
  const { champId } = useParams();
  const [trackDrivers, setTrackDrivers] = useState([]);
  const [champDrivers, setChampDrivers] = useState([]);
  const [currentTrack, setCurrentTrack] = useState({});
  const [trackData, setTrackData] = useState({});
  const [champData, setChampData] = useState({});
  const [allTrackList, setAllTrackList] = useState([]);

  useEffect(() => {
    fetchAllTracks();
    if (currentTrack) {
      fetchDriverResultList();
      fetchTrackData();
    }
  }, [currentTrack]);

  const fetchAllTracks = () => {
    API.graphql({
      query: tracksByChamp,
      variables: {
        "champId": champId,
      },
      authMode: 'API_KEY'
    }).then((t) => {
      let tracks = [];
      if (t && t.data && t.data.tracksByChamp) {
        tracks = t.data.tracksByChamp.items || [];
        tracks = getNotDeletedItems(tracks);
      }
      setAllTrackList(tracks.sort((a, b) => compareTrack(a, b)));
    }).catch((e) => console.log("fetch track data error", e));
  }

  const fetchTrackData = () => {
    API.graphql({
      query: getTrack,
      variables: {
        "id": currentTrack
      },
      authMode: 'API_KEY'
    }).then((t) => {
      let track = {};
      if (t && t.data && t.data.getTrack) {
        track = t.data.getTrack || {};
      }
      if (track.champId) {
        fetchChampData(track.champId);
        fetchChampDriverList(track.champId);
      }
      setTrackData(track);
    }).catch((e) => console.log("fetch track data error", e));
  }

  const fetchChampData = (champId) => {
    API.graphql({
      query: getChamp,
      variables: {
        "id": champId
      },
      authMode: 'API_KEY'
    }).then((t) => {
      let champ = {};
      if (t && t.data && t.data.getChamp) {
        champ = t.data.getChamp || {};
      }
      setChampData(champ);
    }).catch((e) => console.log("fetch Champ data error", e));
  }

  const fetchChampDriverList = (champId) => {
    API.graphql({
      query: champJoinsByChampAndType,
      variables: {
        "champId": champId,
        "limit": 1500,
        "type": {
          "eq": "driver"
        },
        filter: {
          "status": {
            "eq": "accepted"
          }
        }
      },
      authMode: 'API_KEY'
    })
      .then((d) => {
        let drivers = []
        if (d && d.data && d.data.champJoinsByChampAndType) {
          drivers = d.data.champJoinsByChampAndType.items || [];
        }
        drivers = getNotDeletedItems(drivers);
        setChampDrivers(drivers);
      }).catch((e) => console.log("fetch champs error", e));
  }


  const fetchDriverResultList = () => {
    API.graphql({
      query: resultsByTrack,
      variables: {
        "trackId": currentTrack,
        "limit": 1500,
      },
      authMode: 'API_KEY'
    }).then((t) => {
      let drivers = [];
      if (t && t.data && t.data.resultsByTrack) {
        drivers = t.data.resultsByTrack.items || [];
        drivers = getNotDeletedItems(drivers);
      }
      setTrackDrivers(drivers);
    }).catch((e) => console.log("fetch track driver result error", e));
  }

  function compareDrivers(a, b) {
    const aFinalTime = a.dq ? Number.MAX_SAFE_INTEGER : (a.finalTime || Number.MAX_SAFE_INTEGER);
    const bFinalTime = b.dq ? Number.MAX_SAFE_INTEGER : (b.finalTime || Number.MAX_SAFE_INTEGER);

    if (aFinalTime < bFinalTime) {
      return -1;
    }
    else if (aFinalTime > bFinalTime) {
      return 1;
    }
    return 0;
  }

  function compareForceTop(a, b) {
    const aForceTop = a.forceTop ? -1 : Number.MAX_SAFE_INTEGER
    const bForceTop = b.forceTop ? -1 : Number.MAX_SAFE_INTEGER

    if (aForceTop < bForceTop) {
      return -1;
    }
    else if (aForceTop > bForceTop) {
      return 1;
    }
    return 0;
  }

  function compareDriverNames(a, b) {
    let nameA = a["psnId"] || "";
    let nameB = b["psnId"] || "";

    if (nameA.toLowerCase() < nameB.toLowerCase()) {
      return -1;
    }
    if (nameA.toLowerCase() > nameB.toLowerCase()) {
      return 1;
    }
    return 0;
  }

  const getAllDrivers = () => {
    const driversWithNotime = champDrivers.filter(cd => {
      return trackDrivers.find(td => td.driverId == cd.driverId) == null;
    }).sort((a, b) => compareDriverNames(a, b));

    let allDrivers = trackDrivers
      .sort((a, b) => (compareForceTop(a,b) || compareDrivers(a, b)) || compareDriverNames(a, b));

    allDrivers.push(...driversWithNotime)
    return allDrivers;
  }

  const getRaceTimeLabel = (result, field) => {
    return result.dq ? "DQ" : formatRaceTime(result[field]);
  }

  const renderDriverPerLobby = () => {
    const perChunk = champData.maxDriverPerLobby || 100;
    const drivers = getAllDrivers();
    return drivers
      .reduce((all, one, i) => {
        const ch = Math.floor(i / perChunk);
        all[ch] = [].concat((all[ch] || []), one);
        return all
      }, [])
      .map((ld, i) => {
        return (
          <Col md={{ size: "8", offset: "2" }}>
            <Card>
              <CardHeader>
                <h5 className="title">Sala {i + 1}</h5>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="12">
                    <Table responsive>
                      <thead className="text-primary">
                        <tr>
                          <th className="text-center">Posicion</th>
                          <th className="text-center">Piloto</th>
                          <th className="text-center">Tiempo</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ld.map((e, i) => {
                          return (
                            <tr key={`track-${e.id}`}>
                              <td className="text-center">{i + 1}</td>
                              <td className="text-center">{e.psnId}</td>
                              <td className="text-center">{getRaceTimeLabel(e, "finalTime")}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        );
      });
  }

  const getSelectTrack = () => {
    const trackOptions = getTrackOptions();
    return trackOptions.find(d => d.value === currentTrack) || "";
  }

  function compareTrack(a, b) {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  }

  const getTrackOptions = () => {
    const tracks = allTrackList
      .map((c, index) => {
        const trackValue = index > 0 ? allTrackList[index - 1].id : c.id;
        return { value: trackValue, label: `${c.order} - ${c.name}` };
      });
    tracks.shift();
    return tracks;
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <h5 className="title">Ordenamiento de Salas</h5>
              </CardHeader>
              <CardBody>
                <p>
                  Seleccione una fecha para cargar el ordenamiento de Sala
                </p>
                <Row>
                  <Col md="1"></Col>
                  <Col md="3">
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="singleSelect"
                      value={getSelectTrack()}
                      onChange={(option) => {
                        setCurrentTrack(option.value);
                      }
                      }
                      options={getTrackOptions()}
                      placeholder="Fecha"
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          {renderDriverPerLobby()}
        </Row>
      </div>
    </>
  );

}

export default TrackLobbies;
