/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:66f6f1cf-80d6-4e28-97ba-3fce378f670a",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_0lP5BpZo7",
    "aws_user_pools_web_client_id": "k5pi46a18bef42ks4qmksupn3",
    "oauth": {
        "domain": "gtchamps-prod.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://gtchamps.com/admin/user-profile/",
        "redirectSignOut": "https://gtchamps.com/auth/login/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://zuomw65rtfbnhhhoq5zhmwpfre.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-inrfxd526ngxpl2v3cbu6abyya",
    "aws_user_files_s3_bucket": "gtchamps-images140422-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
