
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Table,
  Col,
} from "reactstrap";
import { useHistory } from "react-router-dom";

const CountFanWheel = props => {
  let history = useHistory();

  const [resultEntries, setResultEntries] = useState([]);
  const [csvFile, setCsvFile] = useState();

  useEffect(() => {
    if (csvFile) {
      loadResultEntries(csvFile);
    }
  }, [csvFile]);

  const processCSV = (str, delim = ',') => {
    const headers = str.slice(0, str.indexOf('\n')).split(delim);
    const rows = str.slice(str.indexOf('\n') + 1).split('\n');

    const csvArray = rows.map(row => {
      const values = row.split(delim);
      const eachObject = headers.reduce((obj, header, i) => {
        obj[header] = values[i];
        return obj;
      }, {})
      return eachObject;
    })
    const entries = csvArray.reduce((psnIDList, vote) => {
      debugger;
      const psnId = (vote["Comment"] || "")
      .replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '')
      .trim().toLowerCase();
      (psnIDList[psnId] = psnIDList[psnId] || []).push(vote);
      return psnIDList;
    }, {});

    setResultEntries(entries);
  }

  const loadResultEntries = (csvFile) => {
    const reader = new FileReader();

    reader.onload = function (e) {
      const text = e.target.result;
      processCSV(text);
    }

    reader.readAsText(csvFile);

  }
  const renderDriverVotes = () => {
    if (!resultEntries || Object.keys(resultEntries).length === 0) {
      return null;
    }

    return Object.keys(resultEntries).sort().map((driver) => {
      return (
        <tr key={`driver-${driver}`}>
          <td>{resultEntries[driver][0].Comment}</td>
          <td>{resultEntries[driver].length || 0}</td>
        </tr>
      )
    }
    );
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Recuento votos Fan Wheel
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Form action="#" onSubmit={(e) => false} className="form-horizontal" method="get">
                  <Row>
                    <Label sm="2">Archivo de Comentarios</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input
                          type="file"
                          accept='.csv'
                          onChange={(e) => setCsvFile(e.target.files[0])}
                        >
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={{ size: '10', offset: "2" }}>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>Piloto</th>
                            <th>Votos</th>
                          </tr>
                        </thead>
                        <tbody>
                          {resultEntries && renderDriverVotes()}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={{ size: '4' }}>
                      <Button className="btn btn-danger" onClick={() => { history.push("/admin/champs") }} color="danger">
                        <i className="nc-icon nc-simple-remove" />
                        Cancelar
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default CountFanWheel;