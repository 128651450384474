import React, { useEffect, useState } from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import { API, Storage } from "aws-amplify";
import { getTeam } from "graphql/queries";
import { createTeam, updateTeam } from "graphql/mutations";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useHistory, useParams } from "react-router-dom";
import { DATATYPES } from "utils/Constants";
import { cleanUpData } from "utils/Api";
import { champJoinsByTeam } from "graphql/queries";
import { updateChampJoin } from "graphql/mutations";
import { getNotDeletedItems } from "utils/Api";
import { showAlert } from '../../../utils/Alert';
import NotificationAlert from "react-notification-alert";
const alertRef = React.createRef();

const AddTeam = props => {
  let history = useHistory();
  const { id: teamId, leadId } = useParams();
  const [alert, setAlert] = useState(null);
  const [teamData, setTeamData] = useState({});
  const [teamImage, setTeamImage] = useState(null);

  useEffect(() => {
    if (teamId) {
      loadTeamData(teamId);
    }
  }, [teamId]);

  const loadTeamData = (teamId) => {
    API.graphql({
      query: getTeam,
      variables: {
        id: teamId
      },
      authMode: 'API_KEY'
    }).then((t) => {
      let team = {}
      if (t && t.data && t.data.getTeam) {
        team = t.data.getTeam || {};
      }
      setTeamData(team);
    }).catch((e) => console.log("fetch Team data error", e));
  }

  const getTeamImagePath = (teamId) => {
    return `teams/image/${teamId}.jpg`;
  }

  const updateTeamImage = async (teamId, file) => {
    try {
      if (teamId) {
        const imagePath = getTeamImagePath(teamId);
        await Storage.put(imagePath, file, {
          contentType: 'image/jpg'
        });
        updateTeamData('teamImage', imagePath)
      } else {
        setTeamImage(file);
      }

    } catch (error) {
      console.log('Error uploading file: ', error);
      showAlert(alertRef, "danger", "No fue posible guardar la imagen");
    }
  }

  const updateTeamData = (field, value, datatype) => {
    let newTeamData = {
      ...teamData,
      leadId
    };
    if (DATATYPES.INT === datatype) {
      value = parseInt(value);
    }
    newTeamData[field] = value;
    setTeamData(newTeamData);
  }

  const getFormValue = (field) => {
    return teamData[field] === undefined ? "" : teamData[field];
  }

  const updateChampJoinData = async () => {
    let champJoins = [];
    if (teamData.id) {
      let champJoinsData = await API.graphql({
        query: champJoinsByTeam,
        variables: {
          "teamId": teamData.id
        },
        authMode: 'API_KEY'
      })
      if (champJoinsData && champJoinsData.data && champJoinsData.data.champJoinsByTeam) {
        champJoins = champJoinsData.data.champJoinsByTeam.items || [];
        champJoins = getNotDeletedItems(champJoins);
      }
    }

    return champJoins.map(cj => {
      const newChampJoin = {
        ...cj,
        teamName: teamData.name
      }
      return API.graphql({
        query: updateChampJoin,
        variables: {
          "input": cleanUpData(newChampJoin)
        },
        authMode: 'API_KEY'
      })
    });
  }

  const addTeam = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const operationName = (teamData && teamData.id) ? "updateTeam" : "createTeam";
    const operation = operationName === "updateTeam" ? updateTeam : createTeam;
    let promises = [];
    promises.push(
      API.graphql({
        query: operation,
        variables: {
          "input": cleanUpData(teamData)
        },
        authMode: 'API_KEY'
      })
    );
    promises.push(updateChampJoinData());
    Promise.all(promises).then(async (r) => {
      if (teamImage) {
        const savedChampData = r[0].data[operationName];
        await updateTeamImage(savedChampData.id, teamImage);
      }
      setAlert(
        (
          <ReactBSAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Datos Guardados"
            onConfirm={() => {
              const redirect = leadId ? "/admin/myTeam" : "/admin/teams";
              history.push(redirect);
            }}
            confirmBtnBsStyle="info"
            btnSize=""
          />
        )
      );
    }).catch(e => {
      console.log("save error", e);
      setAlert(
        (
          <ReactBSAlert
            danger
            style={{ display: "block", marginTop: "-100px" }}
            title="Datos no guardados"
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="cancel"
            btnSize=""
          />
        )
      );
    });

  }

  return (
    <>
      {alert}
      <NotificationAlert ref={alertRef} />
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  {teamId ? 'Modificar' : 'Agregar'} Escuderia
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Form action="#" onSubmit={(e) => addTeam(e)} className="form-horizontal" method="get">
                  <Row>
                    <Label sm="2">Nombre</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="text" value={getFormValue("name")} onChange={e => updateTeamData('name', e.target.value)} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Pais</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="text" value={getFormValue("country")} onChange={e => updateTeamData('country', e.target.value)} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Lider</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="text" value={getFormValue("lider")} onChange={e => updateTeamData('lider', e.target.value)} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Instagram</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="text" value={getFormValue("instagram")} onChange={e => updateTeamData('instagram', e.target.value)} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Logo Equipo</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input
                          type="file"
                          onChange={(e) => updateTeamImage(teamId, e.target.files[0])}
                        >
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={{ size: '2', offset: '3' }}>
                      <Button color="success">
                        <span className="btn-label">
                          <i className="nc-icon nc-check-2" />
                        </span>
                        {teamId ? 'Modificar' : 'Agregar'}
                      </Button>
                    </Col>
                    <Col sm={{ size: '4' }}>
                      <Button className="btn btn-danger" type="button" color="danger"
                        onClick={(e) => {
                          history.push(leadId ? "/admin/user-profile" : "/admin/teams");
                        }}>
                        <i className="nc-icon nc-simple-remove" />
                        Cancelar
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AddTeam;