
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useParams } from "react-router-dom";
import { showAlert } from '../../utils/Alert';
import NotificationAlert from "react-notification-alert";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap"; 
import { API } from "aws-amplify";
import { teamJoinsByTeam, getDriver } from "graphql/queries";
import { deleteTeamJoin, updateDriver } from "graphql/mutations";
import { getNotDeletedItems } from "utils/Api";
import { cleanUpData } from "utils/Api";

const JoinTeam = props => {
  const history = useHistory();
  const [driverList, setDriverList] = useState([]);
  const [alert, setAlert] = useState(null);
  const [initialLoad, setInitialLoad] = useState(false);
  const alertRef = React.createRef();
  const { id:teamId } = useParams();

  useEffect(() => {
    fetchDriverList();
  },[initialLoad]);

  const fetchDriverList = () =>{
    API.graphql( {
      query: teamJoinsByTeam,
      variables:{"teamId": teamId },
      authMode: 'API_KEY'
    }).then((d)=>{
      let drivers = []
      if(d && d.data && d.data.teamJoinsByTeam){
        drivers = d.data.teamJoinsByTeam.items || [];
      }
      drivers = getNotDeletedItems(drivers);
      setDriverList(drivers);
      setInitialLoad(true);
    }).catch((e)=>console.log("fetch teams joins error", e));    
  }

  const acceptDriver = (teamID, driverId, version, entryId) => {
    API.graphql({
      query: getDriver,
      variables:{
        "id": driverId        
      },
      authMode: 'API_KEY'
    }).then(d =>{
      if(d && d.data && d.data.getDriver && d.data.getDriver.id){
        const driverData =  {
          ...d.data.getDriver,
          "teamId" : teamID,
        };
        API.graphql({
          query: updateDriver,
          variables:{
            "input": cleanUpData(driverData)            
          },
          authMode: 'API_KEY'
        }).then(r =>{
          showAlert(alertRef, "success", "Piloto agregado correctamente al equipo" );
          deleteElement(entryId, version, false);
        }).catch(e =>{
            showAlert(alertRef, "danger", "No fue posible aceptar el piloto" );
            console.log("update driver team request error", e)
        }); 
      }
    });
  }

  const deleteElement = (id, version, showAlerts) => {
    API.graphql({
      query: deleteTeamJoin,
      variables:{
       input: {
         "id": id,
         "_version":version
       } 
      },
      authMode: 'API_KEY'
    }).then(r => {
      if(showAlerts){
        setAlert(
          (
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Rechazado Exitosamente"
              onConfirm={() => {
                setAlert(null);
                fetchDriverList();
                }
              }            
              confirmBtnBsStyle="info"
              btnSize=""
            />          
          )
        );
      }
      fetchDriverList();
    }).catch(e =>{
      console.log("save error",e);
      if(showAlerts){
        setAlert(
          (
            <ReactBSAlert
            danger
            style={{ display: "block", marginTop: "-100px" }}
            title="Error al rechazar al piloto"
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="cancel"
            btnSize=""
          />
          )
        );
      }
      fetchDriverList();
    });
    
  }

  const renderDriversList = () =>{
    return driverList.map((e)=>{
      return (
        <tr key={`driver-${e.id}`}>        
          <td className="text-center">{e.name}</td>          
          <td className="text-center">{e.psnId}</td>         
          <td className="text-right">
            <Button
              className="btn-icon"
              color="success"
              id={`driver-accept-${e.id}`}
              size="sm"
              type="button"
              onClick={() => acceptDriver(teamId, e.driverId, e._version, e.id)}
            >
              <i className="fa fa-check" />
            </Button>{" "}
            <UncontrolledTooltip
              delay={2}
              target={`driver-accept-${e.id}`}
            >
              Aceptar
            </UncontrolledTooltip>
            <Button
              className="btn-icon"
              color="danger"
              id={`driver-eject-${e.id}`}
              size="sm"
              type="button"
              onClick ={() => deleteElement(e.id, e._version, true)}
            >
              <i className="fa fa-times" />
            </Button>{" "}
            <UncontrolledTooltip
              delay={2}
              target={`driver-eject-${e.id}`}
            >
              Rechazar
            </UncontrolledTooltip>                       
          </td>
        </tr> 
      );
    })    
  }

  return (
    <>
      {alert}
      <NotificationAlert ref={alertRef} />
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Solicitudes de Ingreso
                  <Button
                      onClick ={()=> history.push(`/admin/myTeam/`)}
                      className="btn btn-info pull-right header-link"
                      id="tooltip8694850302"
                      size="sm"
                      type="button"
                    >
                      Lista Pilotos
                  </Button>                         
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th className="text-center">Nombre Piloto</th>
                      <th className="text-center">PSN ID</th>
                      <th className="text-right">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderDriversList()}                                                   
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default JoinTeam;