import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getUserData, updateUserProperties, createCustomUserData } from '../../utils/Session';
import { showAlert } from '../../utils/Alert';
import TeamMembers from 'views/pages/TeamMembers';
import NotificationAlert from "react-notification-alert";
import { IMAGE_CLOUDFRONT } from "utils/Aws";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Modal,
} from "reactstrap";
import { DataStore, API } from "aws-amplify";
import { TeamJoin } from "models";
import { listTeams, getDriver } from "graphql/queries";
import { getNotDeletedItems } from "utils/Api";
import { deleteTeam, updateDriver, createDriver } from "graphql/mutations";
import { cleanUpData } from "utils/Api";
const NO_TEAM_LABEL = "Sin Equipo";

const MyTeam = (props) => {
  const history = useHistory();
  const [userData, setUserData] = useState({});
  const [driverData, setDriverData] = useState({});
  const [reloadMemberList, setReloadMemberList] = useState(1);
  const [teamData, setTeamData] = useState({});
  const [teamList, setTeamList] = useState([]);
  const [teamRequestId, setTeamRequestId] = useState();
  const [openJoinModal, setOpenJoinModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [psnIdUpdated, setPsnIdUpdated] = useState(false);
  const alertRef = React.createRef();

  useEffect(() => {
    getUserData()
      .then(data => {
        if (!data.driverId) {
          API.graphql({
            query: createDriver,
            variables: {
              "input": {
                user: data.username,
                name: data.name
              }
            },
            authMode: 'API_KEY'
          }).then(async (r) => {
            const savedChampData = r.data.createDriver;
            getDriverData(savedChampData.id);
            const newUserData = {
              ...data,
              driverId: savedChampData.id
            };

            setUserData(savedChampData);
            const userProperties = createCustomUserData(newUserData);
            updateUserProperties(userProperties);
            getTeamData(newUserData.username);
          }).catch((e) => {
            console.log("Error getting driver data", e);
          })
        } else {
          getDriverData(data.driverId);
          getTeamData(data.username);
          setUserData(data);
        }
      })
      .catch((e) => {
        console.log("Error getting user data", e);
      });
    fetchTeamList();
  }, []);

  const isTeamCreated = () => {
    return teamData && teamData.name;
  }

  const getTeamLogo = (driver) => {
    let logo = "";
    if (driver && driver.teamId) {
      const driverTeamData = teamList.find(t => t.id === driver.teamId);
      logo = driverTeamData && driverTeamData.teamImage ? driverTeamData.teamImage : "";
    } else {
      logo = teamData && teamData.teamImage;
    }
    return logo ? `${IMAGE_CLOUDFRONT}/${logo}` : logo;
  }

  const compareTeam = (a, b) => {
    let nameA = a.name || "";
    let nameB = b.name || "";

    if (nameA.toLowerCase() < nameB.toLowerCase()) {
      return -1;
    }
    if (nameA.toLowerCase() > nameB.toLowerCase()) {
      return 1;
    }
    return 0;
  }

  const fetchTeamList = () => {
    API.graphql({
      query: listTeams,
      authMode: 'API_KEY'
    }).then((d) => {
      let teams = []
      if (d && d.data && d.data.listTeams) {
        teams = d.data.listTeams.items || [];
      }
      teams = getNotDeletedItems(teams);

      setTeamList(teams.sort((a, b) => compareTeam(a, b)));
    }).catch((e) => console.log("fetch teams error", e));
  }

  const getTeamData = (username) => {
    API.graphql({
      query: listTeams,
      variables: {
        filter: {
          "leadId": {
            "eq": username
          }
        }
      },
      authMode: 'API_KEY'
    }).then((d) => {
      let teams = {}
      if (d && d.data && d.data.listTeams) {
        teams = d.data.listTeams.items || [];
      }
      teams = getNotDeletedItems(teams);
      if (teams.length > 0) {
        setTeamData(teams[0]);
      }else{
        setTeamData({});
      }
    })
      .catch((e) => {
        console.log("Error getting team data", e);
      });
  }

  const getDriverData = (driverId) => {
    API.graphql({
      query: getDriver,
      variables: {
        id: driverId
      },
      authMode: 'API_KEY'
    }).then(d => {
      let driver = {};
      if (d && d.data && d.data.getDriver) {
        driver = d.data.getDriver || {};
      }
      setDriverData(driver);
      if (driver && driver.psnId) {
        setPsnIdUpdated(true);
      }
    });
  }

  const openJoinTeamModal = (driverData) => {
    if (!driverData || !driverData.psnId || !psnIdUpdated) {
      showAlert(alertRef, "danger", "Por favor ingrese su PSn ID y actualice su perfil, antes de unirse a un equipo");
      return;
    }
    toggleJoinTeamModal();
  }

  const toggleJoinTeamModal = () => {
    setOpenJoinModal(!openJoinModal);
  }

  const toggleDeleteTeamModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  }

  const deleteTeamAction = () => {
    debugger;
    API.graphql({
      query: deleteTeam,
      variables: {
        input: {
          "id": teamData.id,
          "_version": teamData._version
        }
      },
      authMode: 'API_KEY'
    })
      .then((d) => {
        toggleDeleteTeamModal();
        getTeamData(userData.username);
        fetchTeamList();
      })
      .catch((e) => {
        debugger
        console.log("Error getting team data", e);
      });
  }

  const requestJoinToTeam = () => {
    if (!teamRequestId) {
      showAlert(alertRef, "danger", "Por favor seleccione un equipo antes de continuar");
      return;
    }

    DataStore.save(new TeamJoin({
      teamId: teamRequestId,
      driverId: driverData.id,
      name: userData.name,
      psnId: driverData.psnId
    })).then((j) => {
      showAlert(alertRef, "success", "Se ha enviado la solicitud de ingreso al equipo correctamente");
      toggleJoinTeamModal();
    }).catch(e => {
      console.log("Error requesting team join", e);
    })
  }

  const joinAsDriver = () => {
    const newDriverData = {
      ...driverData,
      "teamId": teamData.id,
    };
    API.graphql({
      query: updateDriver,
      variables: {
        "input": cleanUpData(newDriverData)
      },
      authMode: 'API_KEY'
    }).then(r => {
      showAlert(alertRef, "success", "Te agregaste como piloto del equipo");
      setReloadMemberList(reloadMemberList + 1);
      getDriverData(newDriverData.id);
    }).catch(e => {
      showAlert(alertRef, "danger", "No fue posible agregarte como el piloto");
      console.log("update driver team request error", e)
    });
  }

  const notCurrentTeam = (team) => {
    if (driverData && driverData.teamId) {
      return driverData.teamId !== team.id;
    }
    return true;
  }

  const getTeamName = (driver) => {
    let teamName = NO_TEAM_LABEL;
    if (driver && driver.teamId) {
      const driverTeamData = teamList.find(t => t.id === driver.teamId);
      teamName = driverTeamData && driverTeamData.name ? driverTeamData.name : NO_TEAM_LABEL;
    } else {
      teamName = (teamData && teamData.name) ? teamData.name : teamName
    }
    return teamName
  }

  const getJoinButtonLabel = () => {
    return getTeamName(driverData) == NO_TEAM_LABEL ? "Unirse a Equipo" : "Cambiar de Equipo";
  }

  return (
    <>
      <div className="higest-z-index">
        <NotificationAlert ref={alertRef} />
      </div>
      <Modal
        isOpen={openJoinModal}
        toggle={toggleJoinTeamModal}
      >
        <div className="modal-header justify-content-center">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleJoinTeamModal()}
          >
            <i className="nc-icon nc-simple-remove" />
          </button>
          <h4 className="title title-up">Unirse a Equipo</h4>
        </div>
        <div className="modal-body">
          <p>
            Una vez seleccionado el equipo al que se desea unir de click sobre el boton de <b>ENVIAR SOLICITUD</b>. Posteriormente el jefe del equipo debe aceptar tu solicitud

          </p>
          <FormGroup>
            <label>Equipo</label>
            <Input
              type="select"
              onChange={(v) => setTeamRequestId(v.target.value)}
            >
              <option>Seleccione uno</option>
              {teamList.filter(t => notCurrentTeam(t)).map(t => (
                <option key={`option-${t.id}`} value={t.id}>{t.name}</option>
              ))}
            </Input>
          </FormGroup>
        </div>
        <div className="modal-footer">
          <div className="left-side">
            <Button
              className="btn-link"
              color="success"
              data-dismiss="modal"
              type="button"
              onClick={() => requestJoinToTeam()}
            >
              Enviar Solicitud
            </Button>
          </div>
          <div className="divider" />
          <div className="right-side">
            <Button
              className="btn-link"
              color="danger"
              type="button"
              onClick={() => toggleJoinTeamModal()}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={openDeleteModal}
        toggle={toggleDeleteTeamModal}
      >
        <div className="modal-header justify-content-center">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleDeleteTeamModal()}
          >
            <i className="nc-icon nc-simple-remove" />
          </button>
          <h4 className="title title-up">Eliminar Equipo</h4>
        </div>
        <div className="modal-body">
          <p>
            Esta Seguro que desea eliminar el equipo?
          </p>
        </div>
        <div className="modal-footer">
          <div className="left-side">
            <Button
              className="btn-link"
              color="success"
              data-dismiss="modal"
              type="button"
              onClick={() => deleteTeamAction()}
            >
              Eliminar Equipo
            </Button>
          </div>
          <div className="divider" />
          <div className="right-side">
            <Button
              className="btn-link"
              color="danger"
              type="button"
              onClick={() => toggleDeleteTeamModal()}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </Modal>

      <div className="content">
        <Row>
          <Col md="6">
            <Card>
              <CardHeader>
                <h5 className="title">Mi Equipo</h5>
              </CardHeader>
              <CardBody>
                {getTeamLogo(driverData) && (
                  <Row>
                    <Col md="5">
                      <img src={getTeamLogo(driverData)} />
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col md="9">
                    <FormGroup>
                      <label>Nombre Equipo</label>
                      <Input
                        value={getTeamName(driverData)}
                        disabled
                        type="input"
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>Rol en Equipo</label>
                      <Input
                        value={(teamData && teamData.name) ? "Lider" : "Piloto"}
                        disabled
                        type="text"
                      />
                    </FormGroup>
                    <Row>
                      <Col md="6">
                        <Button
                          className={isTeamCreated() ? "hidden" : "btn btn-info"}
                          id="tooltip8694850302"
                          size="sm"
                          type="button"
                          onClick={() => openJoinTeamModal(driverData)}
                        >
                          {getJoinButtonLabel()}
                        </Button>
                      </Col>
                      <Col md="6">
                        <Button
                          onClick={() => {
                            history.push(`/admin/add-team/${userData.username}`)
                          }}
                          className={isTeamCreated() ? "hidden" : "btn btn-success"}
                          color="success"
                          id="tooltip8694850302"
                          size="sm"
                          type="button"
                        >
                          Crear escuderia
                    </Button>
                      </Col>
                      <Col md="6">
                        <Button
                          onClick={() => {
                            joinAsDriver()
                          }}
                          className={isTeamCreated() && driverData.teamId != teamData.id ? "btn btn-success" : "hidden"}
                          color="success"
                          id="tooltip8694850302"
                          size="sm"
                          type="button"
                        >
                          Incluirme como piloto en el equipo
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md="6" className={isTeamCreated() ? "" : "hidden"}>
            <Card>
              <CardHeader>
                <h5 className="title">Informacion Equipo</h5>
              </CardHeader>
              <CardBody>
                <Row>
                  <Label sm="3">Nombre</Label>
                  <Col sm="8">
                    <FormGroup>
                      <Input disabled type="text" value={teamData && teamData.name} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Label sm="3">Pais</Label>
                  <Col sm="8">
                    <FormGroup>
                      <Input disabled type="text" value={teamData && teamData.country} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Label sm="3">Lider</Label>
                  <Col sm="8">
                    <FormGroup>
                      <Input disabled type="text" value={teamData && teamData.lider} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Label sm="3">Instagram</Label>
                  <Col sm="8">
                    <FormGroup>
                      <Input disabled type="text" value={teamData && teamData.instagram} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Button
                      onClick={() => {
                        history.push(`/admin/edit-team/${teamData.id}/${userData.username}`)
                      }}
                      className="btn btn-success"
                      color="success"
                      id="tooltip8694850302"
                      size="sm"
                      type="button"
                    >
                      Editar escuderia
                    </Button>
                  </Col>
                  <Col md="6">
                    <Button
                      onClick={() => toggleDeleteTeamModal()}
                      className="btn btn-success"
                      color="danger"
                      size="sm"
                      type="button"
                    >
                      Eliminar Equipo
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div className={isTeamCreated() ? "" : "hidden"}>
          <TeamMembers teamId={teamData.id} reloadMembers={reloadMemberList} />
        </div>
      </div>
    </>
  );

}

export default MyTeam;
