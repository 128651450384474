import React, { useEffect, useState } from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import { API } from "aws-amplify";
import { createTeam, updateTeam } from "graphql/mutations";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useHistory, useParams} from "react-router-dom";
import { DATATYPES } from "utils/Constants";
import { cleanUpData } from "utils/Api";
import { getResult } from "graphql/queries";
import { calculateRaceTime } from "utils/Constants";
import { RACE_TIME_FORMAT } from "utils/Constants";
import { FormattedInput } from "@buttercup/react-formatted-input";
import { formatRaceTime } from "utils/Constants";
import { isNumeric } from "utils/Constants";
import { updateResult } from "graphql/mutations";
import { DEFAULT_RESULT_TIME } from "utils/Constants";

const EditResult = props => {
  let history = useHistory();
  const [alert, setAlert] = useState(null);
  const [resultData, setResultData] = useState({});
  const {resultId } = useParams();

  useEffect(() => {
    if(resultId){
      loadResultData(resultId);
    }
  }, [resultId]); 

  const loadResultData = (resultId) => {
    API.graphql( {
      query: getResult,
      variables:{
        id:resultId
      },
      authMode: 'API_KEY'
    }).then((t)=>{
      let result = {}
      if(t && t.data && t.data.getResult){
        result = t.data.getResult || {};
      }
      setResultData(result);
    }).catch((e)=>console.log("fetch Result data error", e));
  }

  const updateResultData  =(field, value, datatype) => {
    let newResultData = {
      ...resultData
    };

    if(DATATYPES.INT === datatype){
      value = parseInt(value);
    }
    if(DATATYPES.RESULT_TIME === datatype && value !== DEFAULT_RESULT_TIME && value.length === 12){
      value = calculateRaceTime(value);
      if(field ==="penalties"){
        newResultData["finalTime"] = ( newResultData["raceTime"] || 0 ) +  value;      
      }
    }
    if(DATATYPES.BOOLEAN === datatype){
      value = value.checked || false ;
    }
    

    newResultData[field] = value;
    setResultData(newResultData);
  }

  const getFormValue = (field) => {
    return resultData[field] === undefined ? "" : resultData[field];
  }

  const saveResult = (e) =>{
    e.preventDefault();
    e.stopPropagation();

    API.graphql({
      query: updateResult,
      variables:{
        "input": cleanUpData(resultData)
      },
      authMode: 'API_KEY'
    })
    .then(r =>{
      setAlert(
        (
          <ReactBSAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Datos Guardados"
            onConfirm={() => {
              history.push(`/admin/track-results/${resultData.trackId}`);
             } }            
            confirmBtnBsStyle="info"
            btnSize=""
          />          
        )
      );
    }).catch(e =>{
      console.log("save error",e);
      setAlert(
        (
          <ReactBSAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="Datos no guardados"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="cancel"
          btnSize=""
        />
        )
      );
    });

  }

  const getRaceTime = (field) => {
    let value  = getFormValue(field);
    return isNumeric(value) ? formatRaceTime(value) : (value || "");
  }

  return (
    <>
      {alert}
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Modificar Resultado de carrera
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Form action="#" onSubmit={(e)=> saveResult(e)} className="form-horizontal" method="get">
                  <Row>
                    <Label sm="2">Posicion</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="text" value={getFormValue("position")} onChange={e => updateResultData('position',e.target.value,DATATYPES.INT)} />                        
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Sala</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="text" value={getFormValue("lobby")} onChange={e => updateResultData('lobby',e.target.value,DATATYPES.INT)} />                        
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Tiempo Carrera</Label>
                    <Col sm="10">
                      <FormGroup>
                      <FormattedInput
                        className="form-control"
                        format={RACE_TIME_FORMAT}
                        value={getRaceTime("raceTime")}
                        onFocus={e=> e.target.select()}
                        onChange={(formattedValue, raw) => { updateResultData("raceTime", formattedValue, DATATYPES.RESULT_TIME)}}
                        placeholder={DEFAULT_RESULT_TIME}
                        /> 
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Penalizaciones</Label>
                    <Col sm="10">
                      <FormGroup>
                      <FormattedInput
                        className="form-control"
                        format={RACE_TIME_FORMAT}
                        value={getRaceTime("penalties")}
                        onFocus={e=> e.target.select()}
                        onChange={(formattedValue, raw) => { updateResultData("penalties", formattedValue, DATATYPES.RESULT_TIME)}}
                        placeholder={DEFAULT_RESULT_TIME}
                        /> 
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Tiempo Final</Label>
                    <Col sm="10">
                      <FormGroup>
                      <FormattedInput
                        className="form-control"
                        format={RACE_TIME_FORMAT}
                        value={getRaceTime("finalTime")}
                        onFocus={e=> e.target.select()}
                        onChange={(formattedValue, raw) => { updateResultData("finalTime", formattedValue, DATATYPES.RESULT_TIME)}}
                        placeholder={DEFAULT_RESULT_TIME}
                        /> 
                      </FormGroup>
                    </Col>
                  </Row> 
                  <Row>
                    <Label sm="2">Piloto</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="text" disabled value={getFormValue("psnId")} onChange={e => updateResultData('psnId',e.target.value,DATATYPES.INT)} />                        
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Priorizar en Ordenamiento</Label>
                    <Col sm="10">
                      <FormGroup check inline>
                        <Label check>
                          <Input 
                            checked={getFormValue("forceTop")}
                            onChange={(e)=>updateResultData("forceTop",e.target, DATATYPES.BOOLEAN)}
                            type="checkbox"
                          />
                          <span className="form-check-sign" /> &nbsp;
                        </Label>
                      </FormGroup>   
                    </Col>     
                  </Row>
                  <Row>
                    <Label sm="2">DQ</Label>
                    <Col sm="10">
                      <FormGroup check inline>
                        <Label check>
                          <Input 
                            checked={getFormValue("dq")}
                            onChange={(e)=>updateResultData("dq",e.target, DATATYPES.BOOLEAN)}
                            type="checkbox"
                          />
                          <span className="form-check-sign" /> &nbsp;
                        </Label>
                      </FormGroup>   
                    </Col>     
                  </Row>
                  <Row>
                    <Col sm={{size:'2', offset:'3'}}>
                      <Button color="success">
                        <span className="btn-label">
                          <i className="nc-icon nc-check-2" />
                        </span>
                        Modificar
                      </Button> 
                    </Col>
                    <Col sm={{size:'4'}}>
                      <a className="btn btn-danger" href="#" color="danger"
                      onClick={(e)=>history.push(`/admin/track-results/${resultData.trackId || ""}`)}>
                        <i className="nc-icon nc-simple-remove" />
                        Cancelar
                      </a>
                    </Col>
                  </Row>               
                </Form>
              </CardBody>
            </Card>
          </Col>          
        </Row>
      </div>
    </>
  );
}

export default EditResult;