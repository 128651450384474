
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import { showAlert } from 'utils/Alert';
import { API } from "aws-amplify";
import { createPoint } from "graphql/mutations";
import { getTrack, champJoinsByChamp, getChamp } from "graphql/queries";
import { useHistory } from "react-router-dom";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { DATATYPES } from "utils/Constants";
import { cleanUpData } from "utils/Api";
import { DEFAULT_RESULT_TIME,calculateRaceTime } from "utils/Constants";
import { getNotDeletedItems } from "utils/Api";
import { resultsByTrack } from "graphql/queries";
import { formatRaceTime } from "utils/Constants";
import { pointSystemByChamp } from "graphql/queries";
const alertRef = React.createRef();

const TrackPoints = props => {
  const [trackData, setTrackData] = useState({});
  const [pointsData, setPointsData] = useState([]);
  const [pointSystemData, setPointSystemData] = useState({});
  const [lobby, setLobby] = useState("");
  const [drivers, setDrivers] = useState([]);
  const {trackId } = useParams();
  let history = useHistory();

  useEffect(() => {
    if(trackId){
      getTrackData(trackId);
    }
  }, [trackId]); 

  const getTrackData = (trackId) => {
    API.graphql({
      query: getTrack,
      variables:{
        "id": trackId
      },
      authMode: 'API_KEY'
    }).then((ps)=>{
      let track = {};
      if(ps && ps.data && ps.data.getTrack){
        track = ps.data.getTrack || {};
      }
      if(track && track.champId){
        fetchChampDrivers(track.champId);
        fetchPointSystem(track.champId);
      }
      setTrackData(track);
    }).catch((e)=>console.log("fetch track data error", e));    
  }

  const fetchChampDrivers = (champId) => {
    API.graphql({
      query: champJoinsByChamp,
      variables:{
        "champId": champId,
        "filter":{
          "status":{
            "eq":"accepted"
          }
        }
      },
      authMode: 'API_KEY'
    }).then((d)=>{
      let drivers = [];
      if(d && d.data && d.data.champJoinsByChamp){
        drivers = d.data.champJoinsByChamp.items || {};
      }
      drivers = getNotDeletedItems(drivers);
      setDrivers(drivers);
    }).catch((e)=>console.log("fetch champ drivers data error", e));    
  }

  const fetchPointSystem = (champId) => {
    API.graphql({
      query: pointSystemByChamp,
      variables:{
        "champId": champId
      },
      authMode: 'API_KEY'
    }).then((d)=>{
      let pointSystem = [];
      if(d && d.data && d.data.pointSystemByChamp){
        pointSystem = d.data.pointSystemByChamp.items || [];
        pointSystem = getNotDeletedItems(pointSystem);
      }
      setPointSystemData(pointSystem[0] || {});
    }).catch((e)=>console.log("fetch champ data error", e));    
  }

  const updateResultRowData = (position, field, value, datatype) =>{
    let previousData = (pointsData && pointsData[position]) ? pointsData[position] : {trackId};
    
    let newResultEntry = {
      ...previousData
    }
    
    if(DATATYPES.INT === datatype){
      value = value === "" ? "0" : value;
      value = parseInt(value);
    }
    if(DATATYPES.RESULT_TIME === datatype && value !== DEFAULT_RESULT_TIME && value.length === 12){
      value = calculateRaceTime(value);
    }

    if(DATATYPES.BOOLEAN === datatype){
      value = value.checked || false ;
    }
    if(DATATYPES.OBJECT === datatype){
      newResultEntry = {
        ...newResultEntry,
        ...value
      };
    }else{
      newResultEntry[field] = value;
    }

    let newPointsData = [
      ...pointsData,
    ];

    newPointsData[position] = newResultEntry;
    setPointsData(newPointsData);
  }

  const getPointsValue = (position, field, defaultValue = "") => {
    let previousData = (pointsData && pointsData[position]) ? pointsData[position] : {position};
    return previousData[field] === undefined ?  defaultValue : previousData[field];    
  }

  const getSavePromeses = () =>{
    let results = pointsData || [];

    return results
    .map(r => API.graphql({
      query: createPoint,
      variables:{
        "input": cleanUpData(r)
      },
      authMode: 'API_KEY'
    }));
  }

  const saveResult = (e) =>{
    e.preventDefault();
    e.stopPropagation();

    const savePromises = getSavePromeses(); 
    Promise.all(savePromises)
    .then((ps)=>{
      showAlert(alertRef, "success", "Puntos Generados Correctamente" );
      setTimeout(()=>{
        history.push("/admin/admin-calendar/"+(trackData && trackData.champId ? trackData.champId: ""));
      },2000);      
    }).catch(e=>{
      console.log("error result data", e);
      showAlert(alertRef, "danger", "No fue posible generar los puntos" );
    });    
  }

  function compareDrivers( a, b ) {
    const aFinalTime = a.dq ? Number.MAX_SAFE_INTEGER : (a.finalTime || Number.MAX_SAFE_INTEGER);
    const bFinalTime = b.dq ? Number.MAX_SAFE_INTEGER : (b.finalTime || Number.MAX_SAFE_INTEGER);

    if ( aFinalTime < bFinalTime ){
      return -1;
    }
    else if ( aFinalTime > bFinalTime ){
      return 1;
    }
    return 0;
  }

  const calculateDriverPoints = (position, isDQ)=>{
    let points = 0;
    
    if(pointSystemData && pointSystemData.formula && !isDQ){
      let formula = pointSystemData.formula;
      formula = formula.replaceAll("NS", lobby);
      formula = formula.replaceAll(" ", "");
      formula = formula.replaceAll(",", ".");
      formula = formula.replaceAll("PP", (pointSystemData[`p${position}`] || 0 ));
      try{
        points = Math.round(eval(formula));
      }catch(e){
        showAlert(alertRef, "danger", "La formula del sistema de puntos no es valida" );
        points = 0;
      }
    }

    return points;
  }

  const tableHeaders = () =>{
    return (
      <Row key="headers">
        <Col sm="1">{""}</Col>
        <Col sm="2"><h6>Position</h6></Col>
        <Col sm="2"><h6>Piloto</h6></Col>
        <Col sm="2"><h6>Tiempo Final</h6></Col>
        <Col sm="1"><h6>DQ</h6></Col>
        <Col sm="2"><h6>Puntos</h6></Col>    
      </Row>
    );
  }

  const generatePointData = (resultsData) =>{    
    return resultsData
      .sort((a,b)=>compareDrivers(a,b))
      .map((e,i) => {
        const position  = i+1;
        return {
          trackId,
          driverId: e.driverId,
          teamId: e.teamId,
          champId: trackData.champId,
          points: calculateDriverPoints(position,e.dq),
          psnId: e.psnId,
          dq: e.dq,
          raceTime: e.raceTime
        }
      });
  }

  const getResultEntries = () =>{
    let rows =[];
    if(pointsData.length){
      rows.push(tableHeaders());
    }
    pointsData.forEach((e,i) => {
      rows.push(
        (
        <Row key={`position${i}`}>
          <Col sm="1">{""}</Col>
          <Col sm="2">P{i+1}</Col>
          <Col sm="2"> {e.psnId}</Col>
          <Col sm="2"> {formatRaceTime(e.raceTime)}</Col>
          <Col sm="1">{e.dq && "DQ"}</Col>
          <Col sm="2">
            <Input 
              type="text"               
              value={getPointsValue(i,"points",e.points)} onChange={e => updateResultRowData(i,'points',e.target.value,DATATYPES.INT)} />
          </Col>    
        </Row>
        )
      );
    });
    return rows;
  }

  const loadTrackResult = (e) => {    
    API.graphql({
      query: resultsByTrack,
      variables:{
        "trackId": trackId,
        "limit": 1500,
        "filter":{
          "lobby":{
            "eq":lobby
          }
        }        
      },
      authMode: 'API_KEY'
    }).then((d)=>{
      let results = [];
      if(d && d.data && d.data.resultsByTrack){
        results = d.data.resultsByTrack.items || {};
      }
      results = getNotDeletedItems(results);
      const pointData = generatePointData(results);
      setPointsData(pointData);
    }).catch((e)=>console.log("fetch track results data error", e));    

  }

  return (
    <>
      <NotificationAlert ref={alertRef} />
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Generar Puntos de Sala</CardTitle>
              </CardHeader>
              <CardBody>
                <Form action="#" onSubmit={(e)=> saveResult(e)} className="form-horizontal" method="get">
                  <Row>
                    <Label sm="2">Numero de Sala</Label>                    
                    <Col sm="4">
                      <FormGroup>
                        <Input 
                          type="text"
                          value={lobby} 
                          onChange={e => setLobby(e.target.value)}
                         />                        
                      </FormGroup>                      
                    </Col>
                    <Col sm="4" className="loadDataButton">
                      <Button type="button" color="success" onClick={(e)=>loadTrackResult()}>
                        <span className="btn-label">
                          <i className="nc-icon nc-check-2" />
                        </span>
                          Cargar Resultados
                      </Button> 
                    </Col>
                  </Row>
                  <hr/>
                  <h4> Resultado(s)</h4>
                  {getResultEntries()}
                  <Row>
                    <Col sm={{size:'2', offset:'3'}}>
                      <Button color="success">
                        <span className="btn-label">
                          <i className="nc-icon nc-check-2" />
                        </span>
                          Guardar
                      </Button> 
                    </Col>
                    <Col sm={{size:'4'}}>
                      <Button 
                        className="btn btn-danger" 
                        onClick={()=> 
                          history.push("/admin/admin-calendar/"+(trackData && trackData.champId ? trackData.champId: ""))
                        }
                        color="danger">
                        <i className="nc-icon nc-simple-remove" />
                          Cancelar
                      </Button>
                    </Col>
                  </Row>                
                </Form>
              </CardBody>
            </Card>
          </Col>          
        </Row>
      </div>
    </>
  );
}

export default TrackPoints;