
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { showAlert } from 'utils/Alert';
import Linkify from 'linkifyjs/react';
import NotificationAlert from "react-notification-alert";

import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Form,
  Label,
  FormGroup,
  Input,
  Row,
  Col,
  Button
} from "reactstrap";
import { FormattedInput } from "@buttercup/react-formatted-input";
import { DATATYPES } from "utils/Constants";
import API from "@aws-amplify/api";
import { entriesByClaim, getClaim, getTrack, getDriver, champJoinsByChamp } from "graphql/queries";
import { createClaimEntry, updateClaim } from "graphql/mutations";
import { getNotDeletedItems, cleanUpData } from "utils/Api";
import { getUserData } from "utils/Session";
import { RACE_TIME_FORMAT } from "utils/Constants";
import { DEFAULT_RESULT_TIME } from "utils/Constants";
import { isNumeric } from "utils/Constants";
import { formatRaceTime, calculateRaceTime } from "utils/Constants";
const alertRef = React.createRef();

const ClaimEntries = props => {
  const {claimId } = useParams();  
  const [claimEntry, setClaimEntry] = useState({});
  const [claimData, setClaimData] = useState({});
  const [driverData, setDriverData] = useState({}); 
  const [userData, setUserData] = useState({}); 
  const [driverList, setDriverList] = useState([]);
  const [claimEntries, setClaimEntries] = useState([]);  
  const [trackData, setTrackData] = useState({});

  useEffect(() => {
    loadInitialData()
  }, [claimId]);  

  const loadInitialData = () =>{
    fetchClaimEntries();
    fetchClaimData();
    getDriverPsnId();
  }
  
  const getDriverPsnId = () =>{
    getUserData().then(d =>{
      setUserData(d);
      API.graphql( {
        query: getDriver,
        variables:{
          id:d.driverId
        },
        authMode: 'API_KEY'
      }).then((t)=>{
        let driver = {}
        if(t && t.data && t.data.getDriver){
          driver = t.data.getDriver || {};
        }
        setDriverData(driver);
      })
      .catch((e)=>console.log("fetch driver data error", e));
    });
  }

  const fetchClaimData = () =>{
    API.graphql( {
      query: getClaim,
      variables:{
        id:claimId
      },
      authMode: 'API_KEY'
    }).then((t)=>{
      let claim = {}
      if(t && t.data && t.data.getClaim){
        claim = t.data.getClaim || {};
      }
      if(claim.champId){
        fetchDriverList(claim.champId);
      }
      if(claim.trackId){
        fetchTrackData(claim.trackId);
      }
      setClaimData(claim);
    })
    .catch((e)=>console.log("fetch claim data error", e));
  }

  const fetchTrackData = (trackId) =>{
    API.graphql( {
      query: getTrack,
      variables:{
        id:trackId
      },
      authMode: 'API_KEY'
    }).then((t)=>{
      let track = {}
      if(t && t.data && t.data.getTrack){
        track = t.data.getTrack || {};
      }
      setTrackData(track);
    })
    .catch((e)=>console.log("fetch driver data error", e));
  }

  const fetchDriverList = (champId) => {
    API.graphql( {
      query: champJoinsByChamp,
      variables:{        
        "champId": champId ,
        "limit": 1500,
        filter:{
          "status": {
            "eq":"accepted"
          },
          "type" :{
            "eq":"driver"
          },
        }
      },
      authMode: 'API_KEY'
    })
    .then((c)=>{
      let drivers = []
      if(c && c.data && c.data.champJoinsByChamp){
        drivers = c.data.champJoinsByChamp.items || [];
      }
      drivers = getNotDeletedItems(drivers);
      setDriverList(drivers);
    }).catch((e)=>console.log("fetch champs error", e));
  }

  const fetchClaimEntries = () => {
    API.graphql( {
      query: entriesByClaim,
      variables:{        
        "claimId": claimId,
        "limit": 1500
      },      
      authMode: 'API_KEY'
    })
    .then((d)=>{
      let claimEntriesData = []
      if(d && d.data && d.data.entriesByClaim){
        claimEntriesData = d.data.entriesByClaim.items || [];
        claimEntriesData = getNotDeletedItems(claimEntriesData);
      }
      setClaimEntries(claimEntriesData);
    }).catch((e)=>console.log("fetch champs error", e));
  }

  const updateClainEntryData  = (field, value, datatype) => {
    let newClaimEntry = {
      ...claimEntry,
      claimId,
      psnId:driverData.psnId,
      driverId:driverData.id
    };

    if(DATATYPES.RESULT_TIME === datatype && value !== DEFAULT_RESULT_TIME && value.length === 12){
      value = calculateRaceTime(value);      
    }

    newClaimEntry[field] = value;
    setClaimEntry(newClaimEntry);
  }

  const getFormValue = (field) => {
    return claimEntry[field] === undefined ? "" : claimEntry[field];
  }
  
  const addClaimEntry = (e) =>{
    e.preventDefault();
    API.graphql({
      query: createClaimEntry,
      variables:{
        "input": claimEntry
      },
      authMode: 'API_KEY'
    })
    .then(r =>{
      showAlert(alertRef, "success", "Informe agregado correctamente" );
      fetchClaimEntries();
      setClaimEntry([]);
    }).catch(e=>{
      console.log("Error Creating claim", e);
      showAlert(alertRef, "danger", "No fue posible agregar el reporte del caso, intentelo mas tarde" );
    });
  }

  const addClaimResolve = (e) =>{
    e.preventDefault();
    let promises = [];

    promises.push(
      API.graphql({
        query: updateClaim,
        variables:{
          "input": cleanUpData({
            ...claimData,
            status:"closed",
            penalty: claimEntry.penalty
          })
        },
        authMode: 'API_KEY'
      }));

    promises.push(
      API.graphql({
        query: createClaimEntry,
        variables:{
          "input": {
            ...claimEntry,
            psnId:"Comisario",
            driverId: "N/A"
          }
        },
        authMode: 'API_KEY'
      }));
    Promise.all(promises).then(r =>{
      showAlert(alertRef, "success", "Reclamo Resuelto exitosamente" );
      loadInitialData();
      setClaimEntry([]);
    }).catch(e=>{
      console.log("Error resolving claim", e);
      showAlert(alertRef, "danger", "No fue posible resolver el reclamo, intentelo mas tarde" );
    });
  }

  const isDriverInClaim = () =>{
    const driverId = driverData.id || "";    
    return claimData && claimData.status === "open" && 
      (claimData.authorID === driverId || claimData.driverClaimedId === driverId ); 
  }

  const isAdminUser = () => {
    return !isDriverInClaim() && claimData.status === "open" &&  userData && userData.roleId === "admin";
  }

  const getRaceTime = (field) => {
    let value  = getFormValue(field);
    return isNumeric(value) ? formatRaceTime(value) : (value || "");
  }

  const addResolveForm = () =>{
    if(isAdminUser())
      return (
        <Row>
            <Col md={{size:"10", offset:"1"}}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    Resolucion de caso
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Form action="#" onSubmit={(e)=>addClaimResolve(e)} className="form-horizontal" method="get">
                    <Row>
                      <Label sm="2">Resolucion Caso</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Input type="textarea" value={getFormValue("description")} onChange={e => updateClainEntryData('description', e.target.value)} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Penalizaciones</Label>
                      <Col sm="10">
                        <FormGroup>
                        <FormattedInput
                          className="form-control"
                          format={RACE_TIME_FORMAT}
                          value={getRaceTime("penalty")}
                          onFocus={e=> e.target.select()}
                          onChange={(formattedValue, raw) => { updateClainEntryData("penalty", formattedValue, DATATYPES.RESULT_TIME)}}
                          placeholder={DEFAULT_RESULT_TIME}
                          /> 
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Button color="success" className="pull-right">
                          <span className="btn-label">
                            <i className="nc-icon nc-check-2" />
                          </span>Resolver Caso
                        </Button>
                      </Col>
                    </Row>              
                  </Form>
                </CardBody>
              </Card>
            </Col>          
          </Row>
      );
    else{
      return null;
    }
  }

  const addInfoForm = ()=> {
    if(isDriverInClaim())
      return (
        <Row>
            <Col md={{size:"10", offset:"1"}}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    Informe de Caso
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Form action="#" onSubmit={(e)=>addClaimEntry(e)} className="form-horizontal" method="get">
                    <Row>
                      <Label sm="2">Descripcion</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Input type="textarea" value={getFormValue("description")} onChange={e => updateClainEntryData('description', e.target.value)} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Enlaces de Videos</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Input type="textarea" value={getFormValue("resource")} onChange={e => updateClainEntryData('resource', e.target.value)} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Button color="success" className="pull-right">
                          <span className="btn-label">
                            <i className="nc-icon nc-check-2" />
                          </span>Agregar
                        </Button>
                      </Col>
                    </Row>              
                  </Form>
                </CardBody>
              </Card>
            </Col>          
          </Row>
      );
    else{
      return null;
    }
  }

  const getTimeLinePosition = (ce) =>{
     return ce.driverId === claimData.driverClaimedId || ce.driverId === "N/A" ? "timeline-inverted" : "";
  }

  const getTimeLineEntryColor = (ce) =>{
    if(ce.driverId === "N/A")
      return "warning";
    return ce.driverId === claimData.driverClaimedId ? "danger" : "primary";
 }

 const getClaimResolution = (ce) => {
  if(!ce.penalty){
    return (<b>Incidente de Carrera</b>)
  }
  if(ce.penalty){
    return (<b>Penalizacion de {formatRaceTime(ce.penalty)} para {getDriverName(claimData.driverClaimedId)}</b>)
  }
 }

  const getDriverName = (driverId) => {
    const driver= driverList.find(d => d.driverId === driverId) || {};
    return driver.psnId || "";
  }

  const getTrackLabel = () =>{
    if(trackData.id){
      return `${trackData.order} - ${trackData.name}`;
    }
    return "";    
  }
  const compareEntries = ( a, b ) => {
    const aCreatedAt = a.createdAt || "";
    const bCreatedAt = b.createdAt || "";
    if ( aCreatedAt.toLowerCase() < bCreatedAt.toLowerCase() ){
      return -1;
    }
    if ( aCreatedAt.toLowerCase() > bCreatedAt.toLowerCase() ){
      return 1;
    }
    return 0;
  }

  const claimInformacion = () =>{
    if(claimEntries.length > 0){
      const claims= claimEntries.sort((a,b)=>compareEntries(a,b)).map((ce,i) =>{
        const messageFromAdmin = ce.driverId === "N/A";
        return (
          <li key={`claim-entry-${i}`} className={getTimeLinePosition(ce)}>
            <div className={`timeline-badge ${getTimeLineEntryColor(ce)}`}>
              <i className="nc-icon nc-single-copy-04" />
            </div>
            <div className="timeline-panel">
              <div className="timeline-heading">
                <Badge color={getTimeLineEntryColor(ce)} pill>
                  {ce.psnId}
                </Badge>
              </div>
              <div className="timeline-body">
                <p>
                  {ce.description}
                </p>
                {ce.resource && (<p>
                  <b>Videos: </b>
                  <Linkify>
                    {ce.resource}
                  </Linkify>
                </p> )}
              </div>
              {messageFromAdmin && (
                <>
                  <hr/>
                  <div className="timeline-footer">
                    {getClaimResolution(ce)}
                  </div>
                </>
                  )
              }          
            </div>
          </li>
        )
      });
      return (
        <>
          <Row>
            <Col md="12">
              <h3>Informacion de Caso</h3>
            </Col>
          </Row>
          <Row>
            <Col md={{size:"6", offset:"0"}}>
              <Card>              
                <CardBody>
                   <p><b> Sala #:</b> {claimData.lobby}</p>
                   <p><b> Fecha  :</b> {getTrackLabel()}</p>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>       
            <Col md="12">
                <Card className="card-timeline card-plain">
                  <CardBody>
                    <ul className="timeline"> 
                      {claims}
                    </ul>
                  </CardBody>
                </Card>
            </Col>
          </Row>
        </>
      )
    }
    return null;
  }

  return (
    <>
      <NotificationAlert ref={alertRef} />
      <div className="content">
        <div className="header text-center">
          <h3 className="title">Reclamo {getDriverName(claimData.authorID)} contra {getDriverName(claimData.driverClaimedId)}</h3>
        </div>
        {addInfoForm()}
        {claimInformacion()}
        {addResolveForm()}
      </div>
    </>
  );  
}

export default ClaimEntries;
