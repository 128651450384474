
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import NotificationAlert from "react-notification-alert";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap"; 
import { API } from "aws-amplify";
import { claimsByTrack, champJoinsByChamp } from "graphql/queries";
import { getNotDeletedItems } from "utils/Api";
import { getTrack } from "graphql/queries";
import { formatRaceTime } from "utils/Constants";

const ClaimsByTrack = props => {
  const history = useHistory();
  const {trackId } = useParams();  
  const [driverList, setDriverList] = useState([]);
  const [claims, setClaims] = useState([]);
  const [trackData, setTrackData] = useState({});
  
  const alertRef = React.createRef();

  useEffect(() => {   
    getTrackData(trackId);
    fetchTrackClaims(trackId);
  },[trackId]);

  const getTrackData = (trackId) =>{
    API.graphql( {
      query: getTrack,
      variables:{
        id:trackId
      },
      authMode: 'API_KEY'
    }).then((t)=>{
      let track = {}
      if(t && t.data && t.data.getTrack){
        track = t.data.getTrack || {};
      }
      if(track.champId){      
        fetchDriverList(track.champId);
      }
      setTrackData(track);
    })
    .catch((e)=>console.log("fetch driver data error", e));
  }

  const fetchTrackClaims = (trackId) => {
    API.graphql({
      query: claimsByTrack,
      variables:{
        "trackId": trackId,
        "limit": 1500,
      },
      authMode: 'API_KEY'
    }).then((d)=>{
      let claims = [];
      if(d && d.data && d.data.claimsByTrack){
        claims = d.data.claimsByTrack.items || {};
      }
      setClaims(claims);
    }).catch((e)=>console.log("fetch champ Claims data error", e));    
  }

  const fetchDriverList = (champId) => {
    API.graphql( {
      query: champJoinsByChamp,
      variables:{        
        "champId": champId ,
        "limit": 1500,
        filter:{
          "status": {
            "eq":"accepted"
          },
          "type" :{
            "eq":"driver"
          },
        }
      },
      authMode: 'API_KEY'
    })
    .then((c)=>{
      let drivers = []
      if(c && c.data && c.data.champJoinsByChamp){
        drivers = c.data.champJoinsByChamp.items || [];
      }
      drivers = getNotDeletedItems(drivers);
      setDriverList(drivers);
    }).catch((e)=>console.log("fetch champs error", e));
  }

  const renderDriversList = () =>{
    return claims.map((e)=>{
      return (
        <tr key={`driver-${e.id}`}>        
          <td className="text-center">{getTrackName()}</td>
          <td className="text-center">{getDriverName(e.authorID)}</td>          
          <td className="text-center">{getDriverName(e.driverClaimedId)}</td>          
          <td className="text-center">
            <span className={`btn  ${e.status ==="open" ? "btn-success":"btn-danger"}`}>{getCaseStatus(e.status)}</span>
          </td>  
          <td className="text-center">{e.penalty ?  formatRaceTime(e.penalty) : ""}</td>                
          <td className="text-right"> 
          <Button
              className="btn-icon"
              color="success"
              id={`tooltips-claim-${e.id}`}
              size="sm"
              type="button"
              onClick={() => history.push(`/admin/view-claim/${e.id}`)}
            >
              <i className="fa fa-eye" />
            </Button>{" "}
            <UncontrolledTooltip
              target={`tooltips-claim-${e.id}`}
            >
              Ver Caso
            </UncontrolledTooltip>         
               
          </td>
        </tr> 
      );
    })    
  }

  const getTrackName = () =>{
    return trackData.name || "";
  }

  const getCaseStatus = (status) => {
    if(status=="open")
      return "Abierto";
    else
      return "Resuelto"
  }

  const getDriverName = (driverId) => {
    const driver= driverList.find(d => d.driverId === driverId) || {};
    return driver.psnId || "";
  }

  return (
    <>
      <NotificationAlert ref={alertRef} />     
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="12">
                    <CardTitle tag="h4">                  
                      Reclamos Fecha {`${trackData.order} - ${trackData.name}`}
                    </CardTitle>
                  </Col>
                </Row>                                        
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th className="text-center">Fecha</th>
                      <th className="text-center">Piloto Reclamante</th>
                      <th className="text-center">Piloto Reclamado</th>
                      <th className="text-center">Estado</th>
                      <th className="text-center">Penalizacion</th>
                      <th className="text-right">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderDriversList()}                                                   
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ClaimsByTrack;