
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { showAlert } from 'utils/Alert';
import NotificationAlert from "react-notification-alert";
import { useParams } from "react-router-dom";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Modal,
  FormGroup,
  Input,
  UncontrolledTooltip
} from "reactstrap";
import { API } from "aws-amplify";
import { claimsByChamp, champJoinsByChamp, listChamps, tracksByChamp } from "graphql/queries";
import { createClaim } from "graphql/mutations";
import { getNotDeletedItems } from "utils/Api";
import { getUserData } from "utils/Session";
import Select from "react-select";
import { getDriver } from "graphql/queries";
import { formatRaceTime } from "utils/Constants";

const Claims = props => {
  const { champId } = useParams();
  const history = useHistory();
  const [driverList, setDriverList] = useState([]);
  const [currentTrack, setCurrentTrack] = useState("");
  const [currentDriver, setCurrentDriver] = useState("");
  const [currentLobby, setCurrentLobby] = useState("");
  const [openClaimModal, setOpenClaimModal] = useState(false);
  const [driverId, setDriverId] = useState(0);
  const [allTrackList, setAllTrackList] = useState([]);
  const [allChampList, setAllChampList] = useState([]);
  const [claims, setClaims] = useState([]);
  const [driverData, setDriverData] = useState({});
  const [userData, setUserData] = useState({});
  const alertRef = React.createRef();

  useEffect(() => {
    fetchAllChampList();
    if (champId) {
      fetchDriverList(champId);
      fetchAllTracks(champId);
    }
    getDriverData();
  }, [champId]);

  const getDriverData = () => {
    getUserData().then(d => {
      setUserData(d);
      setDriverId(d.driverId);
      API.graphql({
        query: getDriver,
        variables: {
          id: d.driverId
        },
        authMode: 'API_KEY'
      }).then((t) => {
        let driver = {}
        if (t && t.data && t.data.getDriver) {
          driver = t.data.getDriver || {};
        }
        setDriverData(driver);
        fetchChampClaims(champId, driver);
      })
        .catch((e) => console.log("fetch driver data error", e));
    });
  }

  const getClaimFilters = (driverData) => {
    let filter = null;
    if (userData.roleId !== "admin") {
      filter = {
        "or": [
          {
            "driverClaimedId": {
              "eq": driverData.id
            }
          },
          {
            "authorID": {
              "eq": driverData.id
            }
          }
        ]
      };
    }
    return filter;
  }

  const fetchChampClaims = (champId, driverData) => {
    const filter = getClaimFilters(driverData);
    API.graphql({
      query: claimsByChamp,
      variables: {
        "champId": champId,
        "limit": 1500,
        filter
      },
      authMode: 'API_KEY'
    }).then((d) => {
      let claims = [];
      if (d && d.data && d.data.claimsByChamp) {
        claims = d.data.claimsByChamp.items || {};
      }
      setClaims(claims);
    }).catch((e) => console.log("fetch champ Claims data error", e));
  }


  const fetchAllChampList = () => {
    API.graphql({
      query: listChamps,
      authMode: 'API_KEY'
    }).then((c) => {
      let champs = []
      if (c && c.data && c.data.listChamps) {
        champs = c.data.listChamps.items || [];
      }
      champs = getNotDeletedItems(champs);
      setAllChampList(champs);
    }).catch((e) => console.log("fetch champs error", e));
  }

  const fetchAllTracks = (champId) => {
    API.graphql({
      query: tracksByChamp,
      variables: {
        "champId": champId,
        filter: {
          'and': [
            {
              "claimAble": {
                "eq": true
              }
            },
            {
              "prequaly": {
                "ne": true
              }
            }
          ]
        }
      },
      authMode: 'API_KEY'
    }).then((t) => {
      let tracks = [];
      if (t && t.data && t.data.tracksByChamp) {
        tracks = t.data.tracksByChamp.items || [];
        tracks = getNotDeletedItems(tracks);
      }
      setAllTrackList(tracks);
    }).catch((e) => console.log("fetch track data error", e));
  }

  const fetchDriverList = (champId) => {
    API.graphql({
      query: champJoinsByChamp,
      variables: {
        "champId": champId,
        "limit": 1500,
        filter: {
          "status": {
            "eq": "accepted"
          },
          "type": {
            "eq": "driver"
          },
        }
      },
      authMode: 'API_KEY'
    })
      .then((c) => {
        let drivers = []
        if (c && c.data && c.data.champJoinsByChamp) {
          drivers = c.data.champJoinsByChamp.items || [];
        }
        drivers = getNotDeletedItems(drivers);
        setDriverList(drivers);
      }).catch((e) => console.log("fetch champs error", e));
  }

  const compareClaims = (a, b) => {
    const aCreatedAt = a.createdAt || "";
    const bCreatedAt = b.createdAt || "";
    if (aCreatedAt.toLowerCase() < bCreatedAt.toLowerCase()) {
      return -1;
    }
    if (aCreatedAt.toLowerCase() > bCreatedAt.toLowerCase()) {
      return 1;
    }
    return 0;
  }

  const renderDriversList = () => {
    return claims.sort((a, b) => compareClaims(a, b)).map((e) => {
      return (
        <tr key={`driver-${e.id}`}>
          <td className="text-center">{getTrackName(e.trackId)}</td>
          <td className="text-center">{getDriverName(e.authorID)}</td>
          <td className="text-center">{getDriverName(e.driverClaimedId)}</td>
          <td className="text-center">{getCaseStatus(e.status)}</td>
          <td className="text-center">{e.penalty ? formatRaceTime(e.penalty) : ""}</td>
          <td className="text-right">
            <Button
              className="btn-icon"
              color="success"
              id={`tooltips-claim-${e.id}`}
              size="sm"
              type="button"
              onClick={() => history.push(`/admin/view-claim/${e.id}`)}
            >
              <i className="fa fa-eye" />
            </Button>{" "}
            <UncontrolledTooltip
              target={`tooltips-claim-${e.id}`}
            >
              Ver Caso
            </UncontrolledTooltip>

          </td>
        </tr>
      );
    })
  }

  const getTrackName = (trackId) => {
    const track = allTrackList.find(d => d.id === trackId) || {};
    return track.name || "";
  }

  const getCaseStatus = (status) => {
    if (status == "open")
      return "Abierto";
    else
      return "Resuelto"
  }

  const getSelectDriver = () => {
    const driverOptions = getDriverOptions();
    return driverOptions.find(d => d.value === currentDriver) || "";
  }

  const getDriverName = (driverId) => {
    const driver = driverList.find(d => d.driverId === driverId) || {};
    return driver.psnId || "";
  }

  function compareDriver(a, b) {
    const aName = a.psnId || "";
    const bName = b.psnId || "";
    if (aName.toLowerCase() < bName.toLowerCase()) {
      return -1;
    }
    if (aName.toLowerCase() > bName.toLowerCase()) {
      return 1;
    }
    return 0;
  }

  const getDriverOptions = () => {
    return driverList
      .sort((a, b) => compareDriver(a, b))
      .map(c => {
        return { value: c.driverId, label: `${c.psnId}` };
      });
  }

  const getSelectedTrack = () => {
    const trackOptions = getTrackOptions();
    return trackOptions.find(d => d.value === currentTrack) || "";
  }

  function compareTrack(a, b) {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  }

  const getTrackOptions = () => {
    return allTrackList
      .sort((a, b) => compareTrack(a, b))
      .filter(t => !t.prequaly)
      .map(c => {
        return { value: c.id, label: `${c.order} - ${c.name}` };
      });
  }

  const getSelectChamp = () => {
    const champOption = getChampOptions();
    return champOption.find(d => d.value === champId) || "";
  }

  const getChampOptions = () => {
    return allChampList.map(c => {
      return { value: c.id, label: c.name };
    });
  }

  const toggleClaimModal = () => {
    setOpenClaimModal(!openClaimModal);
  }

  const createNewClaim = () => {
    if (!currentTrack) {
      showAlert(alertRef, "danger", "Por favor seleccione un fecha del campeonato antes de crear el reclamo");
      return;
    }
    if (!currentDriver) {
      showAlert(alertRef, "danger", "Por favor seleccione un piloto para crear un reclamado");
      return;
    }
    if (!currentLobby) {
      showAlert(alertRef, "danger", "Por favor ingrese el numero de sala crear un reclamado");
      return;
    }


    API.graphql({
      query: createClaim,
      variables: {
        "input": {
          authorID: driverId,
          driverClaimedId: currentDriver,
          champId: champId,
          trackId: currentTrack,
          lobby: currentLobby,
          status: "open",
          penalty: 0
        }
      },
      authMode: 'API_KEY'
    })
      .then(r => {
        history.push(`/admin/view-claim/${r.data.createClaim.id}`);
      }).catch(e => {
        console.log("Error Creating claim", e);
        showAlert(alertRef, "danger", "No fue posible crear el caso, intentelo mas tarde");
      });
  }

  const cancelClaim = () => {
    setCurrentTrack("");
    setCurrentDriver("")
    setCurrentLobby("")
    toggleClaimModal();
  }

  const ableToCreateClaim = () => {
    return champId && userData.roleId !== "admin";
  }

  return (
    <>
      <NotificationAlert ref={alertRef} />
      <Modal
        isOpen={openClaimModal}
        toggle={toggleClaimModal}
      >
        <div className="modal-header justify-content-center">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleClaimModal()}
          >
            <i className="nc-icon nc-simple-remove" />
          </button>
          <h4 className="title title-up">Crear un Reclamo</h4>
        </div>
        <div className="modal-body">
          <FormGroup>
            <label>Fecha</label>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              name="singleSelect"
              value={getSelectedTrack()}
              onChange={(option) => {
                setCurrentTrack(option.value);
              }
              }
              options={getTrackOptions()}
              placeholder="Fecha"
            />
          </FormGroup>
          <FormGroup>
            <label>Numero de Sala</label>
            <Input
              type="number"
              value={currentLobby}
              onChange={(e) => {
                setCurrentLobby(e.target.value);
              }
              }
            />
          </FormGroup>
          <FormGroup>
            <label>Piloto Reclamo</label>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              name="singleSelect"
              value={getSelectDriver()}
              onChange={(option) => {
                setCurrentDriver(option.value);
              }
              }
              options={getDriverOptions()}
              placeholder="Piloto"
            />
          </FormGroup>
        </div>
        <div className="modal-footer">
          <div className="left-side">
            <Button
              className="btn-link"
              color="success"
              data-dismiss="modal"
              type="button"
              onClick={() => createNewClaim()}
            >
              Crear Reclamo
            </Button>
          </div>
          <div className="divider" />
          <div className="right-side">
            <Button
              className="btn-link"
              color="danger"
              type="button"
              onClick={() => cancelClaim()}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </Modal>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="12">
                    <CardTitle tag="h4">
                      Mis Reclamos
                      {ableToCreateClaim() && (<Button
                        onClick={() => toggleClaimModal()}
                        className="btn btn-info pull-right header-link"
                        id="tooltip8694850302"
                        size="sm"
                        type="button"
                      >
                        Crear Reclamo
                      </Button>)}
                    </CardTitle>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th className="text-center">Fecha</th>
                      <th className="text-center">Piloto Reclamante</th>
                      <th className="text-center">Piloto Reclamado</th>
                      <th className="text-center">Estado</th>
                      <th className="text-center">Penalizacion</th>
                      <th className="text-right">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderDriversList()}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Claims;