
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import NotificationAlert from "react-notification-alert";
import { API } from "aws-amplify";
import { getDriver, getChamp } from "graphql/queries";
import { useHistory } from "react-router-dom";

import {
  Card,
  CardBody,
  Row,
  Col
} from "reactstrap";
import { getUserData } from "utils/Session";
const alertRef = React.createRef();

const ChampDashboard = props => {
  const history = useHistory();
  const { champId } = useParams();
  const [champData, setChampData] = useState([]);
  const [userData, setUserData] = useState({});
  const [driverData, setDriverData] = useState({});

  useEffect(() => {
    fetchChampData(champId);
    getUserData().then(ud => {
      setUserData(ud);
      getDriverData(ud.driverId);
    });
  }, [champId]);

  const fetchChampData = (champId) => {
    API.graphql({
      query: getChamp,
      variables: {
        id: champId
      },
      authMode: 'API_KEY'
    })
      .then((c) => {
        let champ = []
        if (c && c.data && c.data.getChamp) {
          champ = c.data.getChamp || {};
        }
        setChampData(champ);
      }).catch((e) => console.log("fetch Champ error", e));
  }

  const getDriverData = (driverId) => {
    API.graphql({
      query: getDriver,
      variables: {
        id: driverId
      },
      authMode: 'API_KEY'
    }).then(d => {
      let driver = {};
      if (d && d.data && d.data.getDriver) {
        driver = d.data.getDriver || {};
      }
      setDriverData(driver);
    });
  }

  const goToUrl = (e, url) => {
    e.preventDefault();
    history.push(url);
  }

  return (
    <>
      <div className="higest-z-index">
        <NotificationAlert ref={alertRef} />
      </div>
      <div className="content">
        <Row>
          <Col md="12">
            <h4>Campeonato {champData.name}</h4>
          </Col>
          <Col md="4">
            <a className="champ-dashboard-link"
              href="#"
              onClick={(e) => goToUrl(e,`/admin/drivers-champ/${champData.id}`)}>
              <Card>
                <img src={require("assets/img/dashboard/driver-championship.jpg")}></img>
                <CardBody>
                  <h5 className="text-center"><b>Tabla Pilotos</b></h5>
                </CardBody>
              </Card>
            </a>
          </Col>
          <Col md="4">
            <a className="champ-dashboard-link"
              href="#"
              onClick={(e) => goToUrl(e,`/admin/team-champ/${champData.id}`)}>
              <Card>
                <img src={require("assets/img/dashboard/team-championship.jpg")}></img>
                <CardBody>
                  <h5 className="text-center"><b>Tabla Escuderias</b></h5>
                </CardBody>
              </Card>
            </a>
          </Col>
          <Col md="4">
            <a className="champ-dashboard-link"
              href="#"
              onClick={(e) => goToUrl(e,`/admin/nation-champ/${champData.id}`)}>
              <Card>
                <img src={require("assets/img/dashboard/nations.jpg")}></img>
                <CardBody>
                  <h5 className="text-center"><b>Tabla Naciones</b></h5>
                </CardBody>
              </Card>
            </a>
          </Col>
          <Col md="4">
            <a className="champ-dashboard-link"
              href="#"
              onClick={(e) => goToUrl(e,`/admin/track-lobby-result/${champData.id}`)}>
              <Card>
                <img src={require("assets/img/dashboard/race-result.jpg")}></img>
                <CardBody>
                  <h5 className="text-center"><b>Resultados Carrera</b></h5>
                </CardBody>
              </Card>
            </a>
          </Col>
          <Col md="4">
            <a className="champ-dashboard-link"
              href="#"
              onClick={(e) => goToUrl(e,`/admin/claims/${champData.id}`)}>
              <Card>
                <img src={require("assets/img/dashboard/claim.jpg")}></img>
                <CardBody>
                  <h5 className="text-center"><b>Mis Reclamos</b></h5>
                </CardBody>
              </Card>
            </a>
          </Col>
          <Col md="4">
            <a className="champ-dashboard-link"
              href="#"
              onClick={(e) => goToUrl(e,`/admin/track-lobbies/${champData.id}`)}>
              <Card>
                <img src={require("assets/img/dashboard/lobby-order.jpg")}></img>
                <CardBody>
                  <h5 className="text-center"><b>Orden de Salas</b></h5>
                </CardBody>
              </Card>
            </a>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ChampDashboard;