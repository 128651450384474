
import React from "react";

import {
  Button,  
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

const Teams = props => {
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Escuderias
                    <a
                      href="/admin/add-team"
                      className="btn btn-success pull-right"
                      color="success"
                      id="tooltip8694850302"
                      size="sm"
                      type="button"
                    >
                      <i className="fa fa-plus" /> Agregar
                    </a>{" "}
                    <UncontrolledTooltip
                      delay={2}
                      target="tooltip8694850302"
                    >
                      Agregar
                    </UncontrolledTooltip>       
                  </CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th className="text-center">Nombre Escuderia</th>
                      <th className="text-center">Pais</th>
                      <th className="text-center">Lider</th>
                      <th className="text-right">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-center">TRR</td>
                      <td className="text-center">Costa Rica</td>
                      <td className="text-center">TRR_mavichpe</td>
                      <td className="text-right">
                        <Button
                          className="btn-icon"
                          color="success"
                          id="tooltip366246651"
                          size="sm"
                          type="button"
                        >
                          <i className="fa fa-edit" />
                        </Button>{" "}
                        <UncontrolledTooltip
                          delay={2}
                          target="tooltip366246651"
                        >
                          Edit
                        </UncontrolledTooltip>                        
                      </td>
                    </tr>                    
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Teams;