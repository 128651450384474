
import React from "react";

import {
  Table,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";

const MyPoints = props => {
  
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Mis Puntos</CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Fecha</th>
                      <th>Pista</th>
                      <th>Posicion</th>
                      <th>Puntos</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Spa</td>
                      <td>10</td>
                      <td>36</td>
                    </tr><tr>
                      <td>2</td>
                      <td>Spa</td>
                      <td>10</td>
                      <td>36</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Spa</td>
                      <td>10</td>
                      <td>36</td>
                    </tr>  
                    <tr>
                      <td>4</td>
                      <td>Spa</td>
                      <td>10</td>
                      <td>36</td>
                    </tr> 
                    <tr>
                      <td>5</td>
                      <td>Spa</td>
                      <td>10</td>
                      <td>36</td>
                    </tr>  
                    <tr>
                      <td>6</td>
                      <td>Spa</td>
                      <td>10</td>
                      <td>36</td>
                    </tr> 
                    <tr>
                      <td colSpan="3">
                        <div className="pull-right">
                        <h5><b> Total </b></h5>
                        </div>
                      </td>                      
                      <td> <h5><b>36</b></h5></td>
                    </tr>                               
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>         
        </Row>
      </div>
    </>
  );
}

export default MyPoints;