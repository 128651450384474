// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Champ, ChampJoin, PointSystem, Team, TeamJoin, Driver, Track, Result, Point, Claim, ClaimEntry } = initSchema(schema);

export {
  Champ,
  ChampJoin,
  PointSystem,
  Team,
  TeamJoin,
  Driver,
  Track,
  Result,
  Point,
  Claim,
  ClaimEntry
};