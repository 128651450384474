
import Buttons from "views/components/Buttons.js";
import Calendar from "views/Calendar.js";
import Charts from "views/Charts.js";
import ExtendedForms from "views/forms/ExtendedForms.js";
import ExtendedTables from "views/tables/ExtendedTables.js";
import FullScreenMap from "views/maps/FullScreenMap.js";
import GoogleMaps from "views/maps/GoogleMaps.js";
import GridSystem from "views/components/GridSystem.js";
import Icons from "views/components/Icons.js";
import TermAndConditions from "views/pages/TermAndConditions.js";
import Login from "views/pages/Login.js";
import PasswordReset from "views/pages/PasswordReset.js";
import ValidateAccount from "views/pages/ValidateAccount.js";
import Logout from "views/pages/Logout.js";
import Notifications from "views/components/Notifications.js";
import Panels from "views/components/Panels.js";
import ReactTables from "views/tables/ReactTables.js";
import Register from "views/pages/Register.js";
import RegularForms from "views/forms/RegularForms.js";
import Drivers from "views/pages/Drivers.js";
import ChampDashboard from "views/pages/ChampDashboard.js";
import Teams from "views/pages/Teams.js";
import Nations from "views/pages/Nations.js";
import Champs from "views/pages/admin/Champs.js";
import TrackLobbyResults from "views/pages/admin/TrackLobbyResults.js";
import JoinChamp from "views/pages/admin/JoinChamp.js";
import CountFanWheel from "views/pages/admin/CountFanWheel.js";
import AddChamp from "views/pages/admin/AddChamp.js";
import Claims from "views/pages/admin/Claims.js";
import ClaimEntries from "views/pages/admin/ClaimEntries.js";
import ClaimsByTrack from "views/pages/admin/ClaimsByTrack.js";
import TrackLobbies from "views/pages/admin/TrackLobbies.js";
import MyPoints from "views/pages/MyPoints.js";
import AdminTeams from "views/pages/admin/Teams.js";
import TrackResults from "views/pages/admin/TrackResults.js";
import AddResults from "views/pages/admin/AddResults.js";
import TrackPoints from "views/pages/admin/TrackPoints.js";
import TrackTeamPoints from "views/pages/admin/TrackTeamPoints.js"
import TrackNationPoints from "views/pages/admin/TrackNationPoints.js";
import AddTeam from "views/pages/admin/AddTeam.js";
import EditResult from "views/pages/admin/EditResult.js";
import JoinTeams from "views/pages/JoinTeam.js";
import ResponseChampsJoin from "views/pages/admin/ResponseChampsJoin.js";
import ChampMembers from "views/pages/admin/ChampMembers.js";
import TeamMembers from "views/pages/TeamMembers.js";
import AdminCalendar from "views/pages/admin/Calendar.js";
import AddTrack from "views/pages/admin/AddTrack.js";
import PointSystem from "views/pages/admin/PointSystem.js";
import RaceResults from "views/pages/admin/RaceResults.js";
import AddRaceResults from "views/pages/admin/AddRaceResults.js";
import RegularTables from "views/tables/RegularTables.js";
import SweetAlert from "views/components/SweetAlert.js";
import Timeline from "views/pages/Timeline.js";
import Typography from "views/components/Typography.js";
import UserProfile from "views/pages/UserProfile.js";
import MyTeam from "views/pages/MyTeam.js";
import ValidationForms from "views/forms/ValidationForms.js";
import VectorMap from "views/maps/VectorMap.js";
import Widgets from "views/Widgets.js";
import Wizard from "views/forms/Wizard.js";

const routes = [
  { 
    collapse: true,
    name: "Campeonatos",
    icon: "nc-icon nc-single-copy-04",
    state: "champCollapse",
    views: [
      {
        path: "/register-champ",
        name: "Lista Campeonatos",
        mini: "LC",
        component: JoinChamp,
        layout: "/admin",
        private: true,
      },
      {
        path: "/myTeam",
        name: "Mi Equipo",
        mini: "ME",
        component: MyTeam,
        layout: "/admin",
        hide:true,
        private: true,
      },
      {
        path: "/champ/dashboard/:champId",
        name: "Dashboard Campeonato",
        mini: "D",
        component: ChampDashboard,
        layout: "/admin",
        private: true,
        hide:true
      },
      {
        path: "/drivers-champ/:champId",
        name: "Tabla Pilotos",
        mini: "P",
        component: Drivers,
        layout: "/admin",
        private: true,
        hide:true
      },
      {
        path: "/team-champ/:champId",
        name: "Tabla Escuderias",
        mini: "E",
        component: Teams,
        layout: "/admin",
        private: true,
        hide:true
      }, 
      {
        path: "/nation-champ/:champId",
        name: "Tabla Naciones",
        mini: "TC",
        component: Nations,
        layout: "/admin",
        private: true,
        hide:true
      },
      {
        path: "/track-lobby-result/:champId",
        name: "Resultados por Fecha",
        mini: "E",
        component: TrackLobbyResults,
        layout: "/admin",
        private: true,
        hide:true
      },  
      {
        path: "/claims/:champId",
        name: "Mis Reclamos",
        mini: "E",
        component: Claims,
        layout: "/admin",
        private: SVGComponentTransferFunctionElement,
        hide:true
      }, 
      {
        path: "/view-claim/:claimId",
        name: "Ver Reclamos",
        mini: "AR",
        component: ClaimEntries,
        layout: "/admin",
        private: true,
        hide:true
      },
    ],
  },
  { 
    collapse: true,
    name: "Reclamos",
    icon: "nc-icon nc-book-bookmark",
    state: "casesCollapse",
    hide:true,
    views: [
      {
        path: "/list-cases",
        name: "Lista Reclamos",
        mini: "LR",
        private: true,        
        component: Timeline,
        layout: "/admin",
      },
      {
        path: "/add-case",
        name: "Crear Reclamo",
        mini: "CR",
        component: Login,
        layout: "/auth",
        private: true,
      },      
    ],
  },
  {
    path: "/charts",
    hide:true,
    name: "Mis puntos",
    icon: "nc-icon nc-chart-bar-32",
    component: MyPoints,
    layout: "/admin",
    private: true,
  },
  {
    path: "/calendar",
    hide:true,
    name: "Calendario (Combos)",
    icon: "nc-icon nc-calendar-60",
    component: Calendar,
    layout: "/admin",
    private: true,
  },
  { 
    collapse: true,
    name: "Admin",
    icon: "nc-icon nc-layout-11",
    state: "adminCollapse",
    role: "admin",
    views: [
      {
        path: "/champs",
        name: "Campeonato",
        mini: "C",
        private: true,
        component: Champs,
        layout: "/admin",
      },
      {
        path: "/add-champ",
        name: "Agregar Campeonato",
        mini: "-",
        private: true,
        component: AddChamp,
        layout: "/admin",
      },
      {
        path: "/edit-champ/:id",
        hide:true,
        name: "editar Campeonato",
        mini: "-",
        private: true,
        component: AddChamp,
        layout: "/admin",
      },
      
      {
        path: "/champ-members/:id/",
        name: "Lista de pilotos",
        mini: "-",
        private: true,
        hide:true,
        component: ChampMembers,
        layout: "/admin",
      },
      {
        path: "/view-champ-request/:id/",
        hide:true,
        name: "Solicitudes de Ingreso",
        mini: "-",
        private: true,
        component: ResponseChampsJoin,
        layout: "/admin",
      },
      {
        path: "/teams",
        name: "Escuderias",
        mini: "E",
        private: true,
        component: AdminTeams,
        layout: "/admin",
      },
      {
        path: "/add-team/:leadId",
        name: "Agregar Escuderia",
        mini: "-",
        private: true,
        component: AddTeam,
        layout: "/admin",
      },
      {
        path: "/edit-team/:id/:leadId",
        hide:true,
        name: "Editar Escuderia",
        mini: "-",
        private: true,
        component: AddTeam,
        layout: "/admin",
      },
      {
        path: "/team-members/:id/",
        hide:true,
        name: "Miembre de Equipo",
        mini: "-",
        private: true,
        component: TeamMembers,
        layout: "/admin",
      },
      {
        path: "/team-join-request/:id/",
        hide:true,
        name: "Solicitudes de Ingreso",
        mini: "-",
        private: true,
        component: JoinTeams,
        layout: "/admin",
      },
      {
        path: "/admin-calendar/:id",
        name: "Calendario",
        mini: "C",
        component: AdminCalendar,
        layout: "/admin",
        private: true,
      },
      {
        path: "/track-results/:id",
        name: "Resultados",
        mini: "R",
        component: TrackResults,
        layout: "/admin",
        private: true,
      },
      {
        path: "/add-track-results/:trackId/",
        name: "Resultados",
        mini: "R",
        component: AddResults,
        layout: "/admin",
        private: true,
      }, 
      {
        path: "/edit-track-result/:resultId/",
        name: "Editar REsultado",
        mini: "R",
        component: EditResult,
        layout: "/admin",
        private: true,
      },         
      {
        path: "/track-lobbies/:champId/",
        name: "Orden de Salas",
        mini: "OS",
        component: TrackLobbies,
        layout: "/admin",
        private: true,
      }, 
      {
        path: "/track-points/:trackId/",
        name: "Generar Puntos de Fecha",
        mini: "DP",
        component: TrackPoints,
        layout: "/admin",
        private: true,
      },  
      {
        path: "/track-team-points/:trackId/",
        name: "Generar Puntos de Equipo Fecha",
        mini: "TP",
        component: TrackTeamPoints,
        layout: "/admin",
        private: true,
      },  
      {
        path: "/track-nation-points/:trackId/",
        name: "Generar Puntos de Naciones Fecha",
        mini: "NP",
        component: TrackNationPoints,
        layout: "/admin",
        private: true,
      },     
      {
        path: "/add-track",
        name: "Agregar Fecha",
        mini: "-",
        private: true,
        component: AddTrack,
        layout: "/admin",
      },
      {
        path: "/edit-track/:id",
        hide:true,
        name: "Editar Fecha",
        mini: "-",
        private: true,
        component: AddTrack,
        layout: "/admin",
      },
      {
        path: "/point-system/:id",
        name: "Sistema de puntos",
        mini: "SP",
        component: PointSystem,
        layout: "/admin",
        private: true,
      },
      {
        path: "/race-result",
        name: "Resultado Carrera",
        mini: "RC",
        component: RaceResults,
        layout: "/admin",
        private: true,
      },   
      {
        path: "/add-race-result",
        name: "Agregar Resultado Carrera",
        mini: "-",
        component: AddRaceResults,
        layout: "/admin",
        private: true,
      },
      {
        path: "/countr-fanwheel",
        name: "Recuento FanWheel",
        mini: "FW",
        component: CountFanWheel,
        layout: "/admin",
        private: true,
      },   
      {
        path: "/track-claims/:trackId",
        name: "Reclamos",
        mini: "-",
        component: ClaimsByTrack,
        layout: "/admin",
        private: true,
        hide:true
      },      
    ],
  },
  { 
    collapse: true,
    name: "Pages",
    icon: "nc-icon nc-book-bookmark",
    state: "pagesCollapse",
    role: "admin",
    views: [
      {
        path: "/timeline",
        name: "Timeline",
        mini: "T",
        private: true,
        component: Timeline,
        layout: "/admin",
      },
      {
        path: "/login",
        name: "Login",
        mini: "L",
        component: Login,
        layout: "/auth",
        private: false,
      },
      {
        path: "/register",
        name: "Register",
        mini: "R",
        component: Register,
        layout: "/auth",
        private: false
      },
      {
        path: "/logout",
        name: "logout",
        mini: "LS",
        component: Logout,
        layout: "/auth",
        private: false,
      },
      {
        path: "/password-forgot",
        name: "Recuperar contrasena",
        mini: "rc",
        component: PasswordReset,
        layout: "/auth",
        private: false,
      },
      {
        path: "/confirmation",
        name: "Confirmacion de cuenta",
        mini: "CC",
        component: Login,        
        layout: "/auth",
        private: false,
        customProps:{
          confirmation:true,
        },
      },
      {
        path: "/validate-account",
        name: "Validar Cuenta",
        mini: "VC",
        component: ValidateAccount,
        layout: "/auth",
        private: false,        
      },      
      {
        path: "/user-profile",
        name: "UserProfile",
        mini: "UP",
        component: UserProfile,
        layout: "/admin",
        private: true,
      },
    ],
  },
  {
    collapse: true,
    name: "Components",
    icon: "nc-icon nc-layout-11",
    state: "componentsCollapse",
    role: "admin",
    views: [
      {
        path: "/buttons",
        name: "Buttons",
        mini: "B",
        component: Buttons,
        layout: "/admin",
        private: true,
      },
      {
        path: "/grid-system",
        name: "Grid System",
        mini: "GS",
        component: GridSystem,
        layout: "/admin",
        private: true,
      },
      {
        path: "/panels",
        name: "Panels",
        mini: "P",
        component: Panels,
        layout: "/admin",
        private: true,
      },
      {
        path: "/sweet-alert",
        name: "Sweet Alert",
        mini: "SA",
        component: SweetAlert,
        layout: "/admin",
        private: true,
      },
      {
        path: "/notifications",
        name: "Notifications",
        mini: "N",
        component: Notifications,
        layout: "/admin",
        private: true,
      },
      {
        path: "/icons",
        name: "Icons",
        mini: "I",
        component: Icons,
        layout: "/admin",
        private: true,
      },
      {
        path: "/typography",
        name: "Typography",
        mini: "T",
        component: Typography,
        layout: "/admin",
        private: true,
      },
    ],
  },
  {
    collapse: true,
    role: "admin",
    name: "Forms",
    icon: "nc-icon nc-ruler-pencil",
    state: "formsCollapse",
    views: [
      {
        path: "/regular-forms",
        name: "Regular Forms",
        mini: "RF",
        component: RegularForms,
        layout: "/admin",
        private: true,
      },
      {
        path: "/extended-forms",
        name: "Extended Forms",
        mini: "EF",
        component: ExtendedForms,
        layout: "/admin",
        private: true,
      },
      {
        path: "/validation-forms",
        name: "Validation Forms",
        mini: "VF",
        component: ValidationForms,
        layout: "/admin",
        private: true,
      },
      {
        path: "/wizard",
        name: "Wizard",
        mini: "W",
        component: Wizard,
        layout: "/admin",
        private: true,
      },
    ],
  },
  {
    collapse: true,
    name: "Tables",
    icon: "nc-icon nc-single-copy-04",
    state: "tablesCollapse",
    role: "admin",
    views: [
      {
        path: "/regular-tables",
        name: "Regular Tables",
        mini: "RT",
        component: RegularTables,
        layout: "/admin",
        private: true,
      },
      {
        path: "/extended-tables",
        name: "Extended Tables",
        mini: "ET",
        component: ExtendedTables,
        layout: "/admin",
        private: true,
      },
      {
        path: "/react-tables",
        name: "React Tables",
        mini: "RT",
        component: ReactTables,
        layout: "/admin",
        private: true,
      },
    ],
  },
  {
    collapse: true,
    role: "admin",
    name: "Maps",
    icon: "nc-icon nc-pin-3",
    state: "mapsCollapse",
    views: [
      {
        path: "/google-maps",
        name: "Google Maps",
        mini: "GM",
        component: GoogleMaps,
        layout: "/admin",
        private: true,
      },
      {
        path: "/full-screen-map",
        name: "Full Screen Map",
        mini: "FSM",
        component: FullScreenMap,
        layout: "/admin",
        private: true,
      },
      {
        path: "/vector-map",
        name: "Vector Map",
        mini: "VM",
        component: VectorMap,
        layout: "/admin",
        private: true,
      },
    ],
  },
  {
    role: "admin",
    path: "/widgets",
    name: "Widgets",
    icon: "nc-icon nc-box",
    component: Widgets,
    layout: "/admin",
    private: true,
  },
  {
    role: "admin",
    path: "/charts",
    name: "Charts",
    icon: "nc-icon nc-chart-bar-32",
    component: Charts,
    layout: "/admin",
    private: true,
  },
  {
    role: "admin",
    path: "/calendar",
    name: "Calendar",
    icon: "nc-icon nc-calendar-60",
    component: Calendar,
    layout: "/admin",
    private: true,
  },
  {
    role: "admin",
    path: "/term-and-conditions",
    name: "Politicas de Uso y privacidad",
    icon: "P",
    component: TermAndConditions,
    layout: "/auth",
    private: false,
  },
];

export default routes;
