import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Col,
  Row,
} from "reactstrap";

const TermAndConditions = props => {

  return (
    <>
      <div className="term-page">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="12">
                <Card>
                  <CardHeader>
                    <CardHeader>
                      <h3 className="header text-center">Privacidad y Terminos de uso</h3>
                    </CardHeader>
                  </CardHeader>
                  <CardBody>
                    <p>
                    <b>SECCIÓN 1 - ¿QUÉ HACEMOS CON TU INFORMACIÓN?</b>
                    </p>
                    <p>
                    Cuando compras algo de nuestra tienda, como parte del proceso de compra venta, nosotros recolectamos la información personal que nos
                    das tales como nombre, dirección y correo electrónico.
                    </p>
                    <p>
                    Cuando navegas en nuestra tienda, también recibimos de manera automática la dirección de protocolo de internet de tu computadora (IP)
                    con el fin de proporcionarnos información que nos ayuda a conocer acerca de su navegador y sistema operativo.
                    </p>
                    <p>
                    Email marketing (if applicable): Con tu permiso, podremos enviarte correos electrónicos acerca de nuestra tienda, nuevos productos y otras actualizaciones
                    </p>
                    <p>
                    <b>SECTION 2 - CONSENTIMIENTO</b>
                    </p>
                    <p>
                    Cómo obtienen mi consentimiento?
                    </p>
                    <p>
                    Cuando nos provees tu información personal para completar una transacción, verificar tu tarjeta de crédito, crear una órden,  concertar un envío o hacer una devolución, implicamos que aceptas la recolección y uso por esa razón específica solamente.
                    Si te pedimos tu información personal por una razón secundaria, como marketing, te pediremos directame tu expreso consentimiento, o te daremos la oportunidad de negarte.
                    </p>
                    <p>
                    ¿Cómo puedo anular mi consentimientoÇ
                    </p>
                    <p>
                    Si luego de haber aceptado cambias de opinión, puedes anular tu consentimiento para que te contactemos, por la recolección, uso o divulgación de tu información, en cualquier momento, contactándonos a mchacon@mavisoftcr.com o escribiéndonos a: GT Champs San Jose Costa Rica, San Jose, SJ, 10105, Costa Rica
                    </p>
                    <p>
                    <b>SECCIÓN 3 - DIVULGACIÓN</b>
                    </p>
                    <p>
                    Podemos divulgar tu información personal si se nos requiere por ley o si violas nuestros Términos de Servicio.
                    </p>
                    <p>                    
                    <b>SECCIÓN 4 - SERVICES DE TERCERAS PARTES</b>
                    </p>
                    <p>
                    En general, los proveedores de terceras partes utilizados por nosotros solo recopilarán, usarán y divulgarán tu información en la medida que sea necesaria para que les permita desempeñar los servicios que nos proveen.

                    Sin embargo, algunos proveedores de servicios de terceros, tales como pasarelas de pago y otros procesadores de transacciones de pago, tienen sus propias poliíticas de privacidad con respecto a la información que estamos obligados a proporcionarles para las transacciones relacionadas con las compras.

                    Para estos proveedores, te recomendamos que leas las poliíticas de privacidadpara que puedas entender la manera en que tu información personal será manejada.

                    En particular, recuerda que algunos proveedores pueden estar ubicados o contar con instalaciones que se encuentran en una jurisdicción diferente a ti o nosotros.  Asique si deseas proceder con una transacción que involucra los servicios de un proveedor a terceros, tu información puede estar sujeta a las leyes de la jurisdicción (jurisdicciones) en que se encuentra el proveedor de servicios o sus instalaciones.

                    A modo de ejemplo, si te encuentras en Canadá y tu transacción es procesada por una pasarela de pago con sede en Estados Unidos, entonces tu información personal utilizada para completar la transacción puede ser sujeto de divulgación en virtud de la legislación de Estados Unidos, incluyendo la Ley Patriota.

                    Una vez que abandonas el sitio web de nuestra tienda o te rediriges a un sitio o aplicación de terceros, ya no estás siendo regulados por la presente Política de Privacidad o los Términos de Servicio de nuestra página web.

                    Enlaces

                    Cuando haces clic en enlaces de nuestra tienda, puede que seas redirigido fuera de nuestro sitio.  No somos reponsables por las prácticas de privacidad de otros sitios y te recomendamos leer sus normas de privacidad.
                    </p>
                    <p>
                    <b>SECCIÓN 5 - SEGURIDAD</b>
                    </p>
                    <p>
                    Para proteger tu información personal, tomamos precauciones razonables y seguimos las mejores prácticas de la industria para asegurarnos de que no haya pérdida de manera inapropiada, mal uso, acceso, divulgación, alteración o destrucción de la misma.
                    </p>
                    <p>
                    SI nos proporcionas la información de tu tarjeta de crédito, dicha información es encriptada mediante la tecnología Secure Socket Layer (SSL) y se almacena con un cifrado AES-256.  Aunque ningún método de transmisión a través de Internet o de almacenamiento electrónico es 100% seguro, seguimos todos los requisitos de PCI-DSS e implementamos normas adicionales aceptadas por la industria.
                    </p>
                    <p>
                    <b>SECCIÓN 6 - EDAD DE CONSENTIMIENTO</b>
                    </p>
                    <p>
                    Al utilizar este sitio, declaras que tienes al menos la mayoría de edad en tu estado o provincia de residencia, o que tienes la mayoría de edad en tu estado o provincia de residencia y que nos has dado tu consentimiento para permitir que cualquiera de tus dependientes menores use este sitio.
                    </p>
                    <p>
                    <b>SECCIÓN 7 - CAMBIOS A ESTA POLÍTICA DE PRIVACIDAD</b>
                    </p>
                    <p>
                    Nos reservamos el derecho de modificar esta política de privacidad en cualquier momento, asique por favor revísala frecuentemente.  Cambios y aclaraciones entrarán en vigencia inmediatamente después de su publicación en el sitio web.  Si hacemos cambios materiales a esta política, notificaremos aquí que ha sido actualizada, por lo que cual estás enterado de qué información recopilamos, cómo y bajo qué circunstancias, si las hubiere, la utilizamos y/o divulgamos.

                    Si nuestra tienda es adquirida o fusionada con otra empresa, tu información puede ser transferida a los nuevos propietarios, para que podamos seguir vendiéndote productos.

                    </p>
                    <p>
                    <b>PREGUNTAS E INFORMACIÓN DE CONTACTO</b>
                    </p>
                    <p>
                    Si quieres: acceder, corregir, enmendar o borrar cualquier información personal que poseamos sobre ti, registrar una queja, o simplemente quieres más información contacta a nuestro Oficial de Cumplimiento de Privacidad mchacon@mavisoftcr.com o por correopostal a GT Champs

                    [Re: Privacy Compliance Officer]

                    [San Jose Costa Rica, San Jose, SJ, 10105, Costa Rica]
                    </p>
                  </CardBody>
                </Card>
              </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{                      
          backgroundImage: `url(${require("assets/img/bg/gt-champs/cars2.jpg")})`,          
          }}
        />
      </div>
      </>
    );
}

export default TermAndConditions;
