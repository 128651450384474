
import React, { useState, useEffect } from "react";
import {useParams} from "react-router-dom";
import { useHistory } from "react-router-dom";

import { API } from "aws-amplify";
import { tracksByChamp } from "graphql/queries";
import { deleteTrack } from "graphql/mutations";
import ReactBSAlert from "react-bootstrap-sweetalert";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import { getNotDeletedItems } from "utils/Api";

const Calendar = props => {
  const [trackList, setTrackList] = useState([]);
  const [alert, setAlert] = useState(null);
  let history = useHistory();
  const { id: champId } = useParams();

  useEffect(() => {
    fetchTrackList();
  }, []);

  const fetchTrackList = () =>{
    API.graphql( {
      query: tracksByChamp,
      variables:{
        "champId":champId
      },
      authMode: 'API_KEY'
    }).then((t)=>{
      let tracks = [];
      if(t && t.data && t.data.tracksByChamp){
        tracks = t.data.tracksByChamp.items || [];
        tracks = getNotDeletedItems(tracks);
      }
      setTrackList(tracks);
    }).catch((e)=>console.log("fetch track error", e));
  }

  const deleteElement = (id, version) => {
    API.graphql({
      query: deleteTrack,
      variables:{
       input: {
         "id": id,
         "_version":version
       } 
      },
      authMode: 'API_KEY'
    })
    .then((c) =>{
      setAlert(
        (
          <ReactBSAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Eliminado Exitosamente"
            onConfirm={() => {
              setAlert(null);
              fetchTrackList();
              }
            }            
            confirmBtnBsStyle="info"
            btnSize=""
          />          
        )
      );
    }).catch(e =>{
      console.log("save error",e);
      setAlert(
        (
          <ReactBSAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="Datos no Eliminados"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="cancel"
          btnSize=""
        />
        )
      );
    });
    
  }

  function compareOrder( a, b ) {
    if ( a.order < b.order ){
      return -1;
    }
    if ( a.order > b.order ){
      return 1;
    }
    return 0;
  }

  const renderTrackList = () =>{
    return trackList.sort((a, b)=> compareOrder(a,b)).map((e)=>{
      console.log("track data",e);
      return (
        <tr key={`track-${e.order}`}>
          <td className="text-center">{e.order}</td>
          <td className="text-center">{e.name}</td>
          <td className="text-center">{e.date}</td>
          <td className="text-center">{e.laps}</td>
          <td className="text-right">
          <Button
              className="btn-icon"
              color="normal"
              id={`track-team-cl-${e.id}`}
              size="sm"
              type="button"
              onClick={() => history.push(`/admin/track-claims/${e.id}`)}
            >
              <i className="nc-icon nc-paper" />
          </Button>{" "}
          <UncontrolledTooltip
            delay={2}
            target={`track-team-cl-${e.id}`}
          >
            Reclamos
          </UncontrolledTooltip>
          <Button
              className="btn-icon"
              color="normal"
              id={`track-nation-p-${e.id}`}
              size="sm"
              type="button"
              onClick={() => history.push(`/admin/track-nation-points/${e.id}`)}
            >
              <i className="nc-icon nc-diamond" />
          </Button>{" "}
          <UncontrolledTooltip
            delay={2}
            target={`track-nation-p-${e.id}`}
          >
            Generar Puntos Naciones
          </UncontrolledTooltip>
          <Button
              className="btn-icon"
              color="normal"
              id={`track-team-p-${e.id}`}
              size="sm"
              type="button"
              onClick={() => history.push(`/admin/track-team-points/${e.id}`)}
            >
              <i className="nc-icon nc-diamond" />
          </Button>{" "}
          <UncontrolledTooltip
            delay={2}
            target={`track-team-p-${e.id}`}
          >
            Generar Puntos Equipos
          </UncontrolledTooltip>
          <Button
              className="btn-icon"
              color="normal"
              id={`track-order-p-${e.id}`}
              size="sm"
              type="button"
              onClick={() => history.push(`/admin/track-points/${e.id}`)}
            >
              <i className="nc-icon nc-diamond" />
          </Button>{" "}
          <UncontrolledTooltip
            delay={2}
            target={`track-order-p-${e.id}`}
          >
            Generar Puntos
          </UncontrolledTooltip>
          <Button
              className="btn-icon"
              color="normal"
              id={`track-order-o-d-${e.id}`}
              size="sm"
              type="button"
              onClick={() => history.push(`/admin/track-lobbies/${e.id}`)}
            >
              <i className="nc-icon nc-bullet-list-67" />
          </Button>{" "}
          <UncontrolledTooltip
            delay={2}
            target={`track-order-o-d-${e.id}`}
          >
            Generar Orden de Salas
          </UncontrolledTooltip>
          <Button
              className="btn-icon"
              color="normal"
              id={`track-result-r-r-${e.id}`}
              size="sm"
              type="button"
              onClick={() => history.push(`/admin/track-results/${e.id}`)}
            >
              <i className="nc-icon nc-controller-modern" />
          </Button>{" "}
          <UncontrolledTooltip
            delay={2}
            target={`track-result-r-r-${e.id}`}
          >
            Registro de Resultados
          </UncontrolledTooltip>
            <Button
              className="btn-icon"
              color="success"
              id={`track-edit-${e.id}`}
              size="sm"
              type="button"
              onClick={() => history.push(`/admin/edit-track/${e.id}`)}
            >
              <i className="fa fa-edit" />
            </Button>{" "}
            <UncontrolledTooltip
              delay={2}
              target={`track-edit-${e.id}`}
            >
              Edit
            </UncontrolledTooltip>
            <Button
              className="btn-icon"
              color="danger"
              id={`track-delete-${e.id}`}
              size="sm"
              type="button"
              onClick ={() => deleteElement(e.id,e._version)}
            >
              <i className="fa fa-times" />
            </Button>{" "}
            <UncontrolledTooltip
              delay={2}
              target={`track-delete-${e.id}`}
            >
              Eliminar
            </UncontrolledTooltip>  
          </td>
        </tr> 
      );

    })
    
  }

  return (
    <>
    {alert}
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Calendario
                    <Button
                      onClick={()=>{
                        history.push('/admin/add-track');
                      }}
                      className="btn btn-success pull-right header-link"
                      color="success"
                      id="tooltip8694850302"
                      size="sm"
                      type="button"
                    >
                      <i className="fa fa-plus" /> Agregar
                    </Button>{" "}
                    <UncontrolledTooltip
                      delay={2}
                      target="tooltip8694850302"
                    >
                      Agregar
                    </UncontrolledTooltip>       
                  </CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                    <th className="text-center"># Fecha</th>
                      <th className="text-center">Pista</th>
                      <th className="text-center">Fecha</th>
                      <th className="text-center">Vueltas</th>
                      <th className="text-right">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderTrackList()}                                       
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Calendar;