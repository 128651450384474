
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { showAlert } from 'utils/Alert';
import NotificationAlert from "react-notification-alert";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
} from "reactstrap"; 
import { API } from "aws-amplify";
import { champJoinsByChamp } from "graphql/queries";
import { updateChampJoin } from "graphql/mutations";
import { getNotDeletedItems } from "utils/Api";
import Select from "react-select";
import {DRIVER_TEAM_FILTER_OPTIONS} from "utils/Constants";

const ResponseChampsJoin = props => {
  const history = useHistory();
  const [driverList, setDriverList] = useState([]);
  const [filterType, setFilterType] = useState("all");  
  const [initialLoad, setInitialLoad] = useState(false);
  const alertRef = React.createRef();
  const { id:champId } = useParams();

  useEffect(() => {
    fetchDriverList();
  },[initialLoad]);

  const fetchDriverList = () => {
    API.graphql( {
      query: champJoinsByChamp,
      variables:{        
        "champId": champId ,
        "limit": 1500,
        filter:{
          "status": {
            "eq":"pending"
          } 
        }
      },
      authMode: 'API_KEY'
    })
    .then((c)=>{
      let drivers = []
      if(c && c.data && c.data.champJoinsByChamp){
        drivers = c.data.champJoinsByChamp.items || [];
      }
      drivers = getNotDeletedItems(drivers);
      setDriverList(drivers);
      setInitialLoad(true);
    }).catch((e)=>console.log("fetch champs error", e));
  }

  const updateChampJoinCall = (champJoinId, status, version) =>{
    return  API.graphql({
      query: updateChampJoin,
      variables:{
       input: {
        "id": champJoinId,
        "status" : status,
        "_version":version
       } 
      },
      authMode: 'API_KEY'
    });
  }

  const acceptDriver = (champJoinId, version) => {   
    const updatePromise = updateChampJoinCall(champJoinId, "accepted", version);
    updatePromise.then(r =>{
      showAlert(alertRef, "success", "Piloto agregado correctamente al campeonato" );
      fetchDriverList();
    }).catch(e =>{
        showAlert(alertRef, "danger", "El piloto no fue agregado correctamente al campeonato" );
        console.log("update driver champ request error", e)
    }); 

  }

  const rejectDriver = (champJoinId, version) => {   
    const updatePromise = updateChampJoinCall(champJoinId, "rejected", version);
    updatePromise.then(r =>{
      showAlert(alertRef, "success", "Piloto rechazado correctamente del campeonato" );
      fetchDriverList();
    }).catch(e =>{
        showAlert(alertRef, "danger", "El piloto no fue rechazado correctamente del campeonato" );
        console.log("update driver champ request error", e)
    }); 

  }

  const renderDriversList = () =>{
    let drivers = [];
    if(filterType !=="all"){
      drivers = driverList.filter(d => d.type === filterType);
    }else{
      drivers = driverList;
    }
    return drivers.map((e)=>{
      return (
        <tr key={`driver-${e.id}`}>        
          <td className="text-center">{e.type ==="team" ? e.teamName :e.name}</td>          
          <td className="text-center">{e.psnId}</td>         
          <td className="text-right">
            <Button
              className="btn-icon"
              color="success"
              id={`accept-driver-${e.id}`}
              size="sm"
              type="button"
              onClick={() => acceptDriver( e.id, e._version)}
            >
              <i className="fa fa-check" />
            </Button>{" "}            
            <Button
              className="btn-icon"
              color="danger"
              id={`reject-driver-${e.id}`}
              size="sm"
              type="button"
              onClick ={() => rejectDriver(e.id, e._version)}
            >
              <i className="fa fa-times" />
            </Button>{" "}                                 
          </td>
        </tr> 
      );
    })    
  }

  return (
    <>
      <NotificationAlert ref={alertRef} />
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
               <Row>
                <Col md="12">
                  <CardTitle tag="h4">
                    Solicitudes de Ingreso a Campeonato      
                    <Button
                        onClick ={()=> history.push(`/admin/champ-members/${champId}/`)}
                        className="btn btn-info pull-right header-link"
                        id="tooltip8694850302"
                        size="sm"
                        type="button"
                      >
                        Lista de Miembros
                    </Button>                                
                  </CardTitle>
                </Col>
                <Col md="4">
                  <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="singleSelect"
                      value={DRIVER_TEAM_FILTER_OPTIONS.find(o => o.value === filterType)}
                      onChange={(option) => setFilterType(option.value)}
                      options={DRIVER_TEAM_FILTER_OPTIONS}
                      placeholder="Mostar Todo"
                    />  
                  </Col>   
                </Row>       
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th className="text-center">Nombre Piloto / Equipo</th>
                      <th className="text-center">PSN ID</th>
                      <th className="text-right">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderDriversList()}                                                   
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ResponseChampsJoin;