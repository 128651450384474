import React, {useLayoutEffect} from "react";
import { Redirect } from 'react-router-dom';
import { logout } from '../../utils/Session';

// reactstrap components

const Login = props => {  
  
  useLayoutEffect(()=>{
    logout();
  })
  return (
    <Redirect to="/auth/login"/>
    );
}

export default Login;
