
import React, { useEffect, useLayoutEffect, useState } from "react";
import { champJoinsByDriver, pointsByChamp, champJoinsByChampAndType, tracksByChamp, getDriver } from "graphql/queries";
import { useParams } from "react-router";

import {
  Table,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import { getUserData } from "utils/Session";
import { API } from "aws-amplify";
import { getNotDeletedItems } from "utils/Api";
import { countryList } from 'utils/Constants';

const Nations = props => {
  const { champId } = useParams();
  const [champDriverList, setChampDriverList] = useState([]);
  const [driverData, setDriverData] = useState({});
  const [driverResultList, setDriverResultList] = useState([]);
  const [nationChampStatus, setNationChampStatus] = useState([]);
  const [nationList, setNationList] = useState([]);
  const [champTracks, setChampTracks] = useState([]);
  const [userData, setUserData] = useState({});
  const [initialLoad, setInitialLoad] = useState(false);

  useEffect(() => {
    createNationsResult()
  }, [champDriverList.length, driverResultList.length, champTracks.length]);

  useLayoutEffect(() => {
    if (!userData.id) {
      fetchUserData();
    }
    if (champId) {
      fetchChampDrivers(champId)
      fetchChampTracks(champId);
      fetchDriversResult(champId);
    }
  }, [initialLoad, champId]);

  const fetchChampDrivers = (champId) => {
    API.graphql({
      query: champJoinsByChampAndType,
      variables: {
        "champId": champId,
        "limit": 1500,
        "type": {
          "eq": "driver"
        },
        filter: {
          "status": {
            "eq": "accepted"
          }
        }
      },
      authMode: 'API_KEY'
    })
      .then((d) => {
        let drivers = []
        if (d && d.data && d.data.champJoinsByChampAndType) {
          drivers = d.data.champJoinsByChampAndType.items || [];
        }
        drivers = getNotDeletedItems(drivers);
        createNationList(drivers);
        setChampDriverList(drivers);
      }).catch((e) => console.log("fetch champs driver error", e));
  }

  const createNationList = (driver) => {
    const nations = driver.reduce(function (nl, x) {
      const countryInList = nl.filter((n) => n.countryCode === x.country).length > 0;
      if (!countryInList) {
        nl.push({
          countryCode: x.country,
          country: countryList[x.country]
        })
      }
      return nl;
    }, []);
    setNationList(nations.filter((n) => n.country));
  }

  const getDriverData = (driverId) => {
    API.graphql({
      query: getDriver,
      variables: {
        id: driverId
      },
      authMode: 'API_KEY'
    }).then(d => {
      let driver = {};
      if (d && d.data && d.data.getDriver) {
        driver = d.data.getDriver || {};
      }
      setDriverData(driver);
    });
  }

  const fetchUserData = () => {
    getUserData()
      .then(data => {
        if (data.driverId) {
          fetchChampList(data.driverId);
          setUserData(data);
          getDriverData(data.driverId);
        }
      })
      .catch((e) => {
        console.log("Error getting user data", e);
      });
  }

  function compareOrder(a, b) {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  }

  function compareNation(a, b, field) {
    let nameA = a[field] || "";
    let nameB = b[field] || "";

    if (nameA.toLowerCase() < nameB.toLowerCase()) {
      return -1;
    }
    if (nameA.toLowerCase() > nameB.toLowerCase()) {
      return 1;
    }
    return 0;
  }

  const compareNationPoints = (a, b) => {
    let nameA = a["totalPoints"] || 0;
    let nameB = b["totalPoints"] || 0;

    if (nameA > nameB) {
      return -1;
    }
    if (nameA < nameB) {
      return 1;
    }
    return 0;
  }

  const fetchChampTracks = (champId) => {
    API.graphql({
      query: tracksByChamp,
      variables: {
        "champId": champId,
        filter: {
          "prequaly": {
            "ne": true
          }
        }
      },
      authMode: 'API_KEY'
    })
      .then((t) => {
        let tracks = []
        if (t && t.data && t.data.tracksByChamp) {
          tracks = t.data.tracksByChamp.items || [];
        }
        tracks = getNotDeletedItems(tracks);
        setChampTracks(tracks.sort((a, b) => compareOrder(a, b)));
      }).catch((e) => console.log("fetch champs error", e));
  }

  const fetchDriversResult = (champId) => {
    API.graphql({
      query: pointsByChamp,
      variables: {
        "champId": champId,
        "limit": 1500,
      },
      authMode: 'API_KEY'
    })
      .then((p) => {
        let points = []
        if (p && p.data && p.data.pointsByChamp) {
          points = p.data.pointsByChamp.items || [];
        }
        points = getNotDeletedItems(points);
        setDriverResultList(points);
        setInitialLoad(true);
      }).catch((e) => console.log("fetch champs error", e));
  }

  const fetchChampList = (driverId) => {
    API.graphql({
      query: champJoinsByDriver,
      variables: {
        "driverId": driverId,
        filter: {
          "status": {
            "eq": "accepted"
          }
        }
      },
      authMode: 'API_KEY'
    })
      .then((c) => {
        let champs = []
        if (c && c.data && c.data.champJoinsByDriver) {
          champs = c.data.champJoinsByDriver.items || [];
        }
        champs = getNotDeletedItems(champs);
      }).catch((e) => console.log("fetch champs error", e));
  }

  const getTrackPoint = (track, trackPoints) => {
    if (track.pointsVisible)
      return (trackPoints && trackPoints.points) ? trackPoints.points : 0;
    return 0;
  }
  const getNationTotalPoint = (driverResults) => {
    const tracksWithPointsVisible = champTracks.filter(ct => ct.pointsVisible);
    return driverResults.reduce((total, dr) => {
      if (tracksWithPointsVisible.find(t => t.id === dr.trackId)) {
        return total + (dr.points || 0);
      } else return total;
    }, 0);
  }

  const createNationsResult = () => {
    const champStatus = nationList.map(c => {
      const nationsResults = driverResultList.filter(dr => dr.nationCode === c.countryCode && dr.champId === champId);
      const totalPoints = getNationTotalPoint(nationsResults);
      const trackResult = champTracks.map(ct => {
        const trackPoints = nationsResults.find(dr => dr.trackId === ct.id);
        const driverNames = trackPoints ? trackPoints.driversInclude || "" : "";
        const points = getTrackPoint(ct, trackPoints);
        return {
          "trackId": ct.id,
          "points": points,
          "includeDriver": driverNames
        }
      });
      return {
        "countryCode": c.countryCode,
        "country": c.country,
        "totalPoints": totalPoints,
        "trackResult": trackResult
      }
    }).sort((a, b) => compareNationPoints(a, b) || compareNation(a, b, "country"));
    setNationChampStatus(champStatus);
    setInitialLoad(true);
  }

  const renderNationsData = () => {
    if (!nationChampStatus || nationChampStatus.length === 0) {
      return null;
    }

    return nationChampStatus.map((d, i) => {
      return (
        <tr className={(driverData && driverData.country === d.countryCode ? "currentUser" : "")} key={`driver-${i}`}>
          <td>P{i + 1}</td>
          <td>{d.country}</td>
          {
            d.trackResult.map(t => (
              <td key={`track-header-${d.countryCode}-${t.trackId}`}>
                <div id={`id-track-header-${d.countryCode}-${t.trackId}`}
                  className={`point-table__group-register ${t.includeDriver && "add-pointer-cursor"}`}>
                  {t.points}
                </div>
                {t.includeDriver && (<UncontrolledTooltip
                  delay={2}
                  placement="left"
                  target={`id-track-header-${d.countryCode}-${t.trackId}`}
                >
                  {t.includeDriver.split(",").map(d => (<>{d}<br /></>))}
                </UncontrolledTooltip>
                )}
              </td>
            )
            )
          }
          <td className="text-right">{d.totalPoints}</td>
        </tr>
      )
    }
    );
  }

  const renderTrackHeaders = () => {
    return champTracks.map(t => (
      <th key={`track-header-${t.order}`}>Fecha {t.order}</th>
    )
    );
  }

  const getCountryFlagImage = (countryCode) =>{
    return `https://flagcdn.com/${countryCode.toLowerCase()}.svg`
  }

  const renderNationPodium = () => {
    if (!nationChampStatus || nationChampStatus.length === 0) {
      return null;
    }

    return nationChampStatus.slice(0, 3).map((d, i) => {
      return d.totalPoints != 0 ? (
        <Col sm={{ size: '4', order: (i == 1 ? "first" : "") }} className={`podium-p${i + 1}`} key={`driver-${i}`}>
          <div className="podium_position">P{i + 1}</div>
            <Col className="podium_image" sm={{ size: '10', offset: '1' }}>
              <img src={getCountryFlagImage(d.countryCode)} />
            </Col>
          <h3 className="text-center"><b>{d.totalPoints}</b></h3>
          <h5 className="text-center"><b>{d.country}</b></h5>
        </Col>
      ) : null;
    }
    );
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="8">
                    <CardTitle tag="h4">Campeonato de Naciones</CardTitle>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  {renderNationPodium()}
                </Row>
                <Table responsive>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Pais</th>
                      {renderTrackHeaders()}
                      <th className="text-right">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {initialLoad && renderNationsData()}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Nations;