/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const deletePointSystem = /* GraphQL */ `
  mutation DeletePointSystem(
    $input: DeletePointSystemInput!
    $condition: ModelPointSystemConditionInput
  ) {
    deletePointSystem(input: $input, condition: $condition) {
      id
      champId
      formula
      p1
      p2
      p3
      p4
      p5
      p6
      p7
      p8
      p9
      p10
      p11
      p12
      p13
      p14
      p15
      p16
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createTeamJoin = /* GraphQL */ `
  mutation CreateTeamJoin(
    $input: CreateTeamJoinInput!
    $condition: ModelTeamJoinConditionInput
  ) {
    createTeamJoin(input: $input, condition: $condition) {
      id
      driverId
      teamId
      name
      psnId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createChamp = /* GraphQL */ `
  mutation CreateChamp(
    $input: CreateChampInput!
    $condition: ModelChampConditionInput
  ) {
    createChamp(input: $input, condition: $condition) {
      id
      name
      maxDriverPerLobby
      startDate
      inscriptionURL
      rulesUrl
      status
      champImage
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateChamp = /* GraphQL */ `
  mutation UpdateChamp(
    $input: UpdateChampInput!
    $condition: ModelChampConditionInput
  ) {
    updateChamp(input: $input, condition: $condition) {
      id
      name
      maxDriverPerLobby
      startDate
      inscriptionURL
      rulesUrl
      status
      champImage
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteChamp = /* GraphQL */ `
  mutation DeleteChamp(
    $input: DeleteChampInput!
    $condition: ModelChampConditionInput
  ) {
    deleteChamp(input: $input, condition: $condition) {
      id
      name
      maxDriverPerLobby
      startDate
      inscriptionURL
      rulesUrl
      status
      champImage
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createChampJoin = /* GraphQL */ `
  mutation CreateChampJoin(
    $input: CreateChampJoinInput!
    $condition: ModelChampJoinConditionInput
  ) {
    createChampJoin(input: $input, condition: $condition) {
      id
      name
      psnId
      driverId
      champId
      status
      type
      teamName
      teamId
      country
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateChampJoin = /* GraphQL */ `
  mutation UpdateChampJoin(
    $input: UpdateChampJoinInput!
    $condition: ModelChampJoinConditionInput
  ) {
    updateChampJoin(input: $input, condition: $condition) {
      id
      name
      psnId
      driverId
      champId
      status
      type
      teamName
      teamId
      country
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteChampJoin = /* GraphQL */ `
  mutation DeleteChampJoin(
    $input: DeleteChampJoinInput!
    $condition: ModelChampJoinConditionInput
  ) {
    deleteChampJoin(input: $input, condition: $condition) {
      id
      name
      psnId
      driverId
      champId
      status
      type
      teamName
      teamId
      country
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPointSystem = /* GraphQL */ `
  mutation CreatePointSystem(
    $input: CreatePointSystemInput!
    $condition: ModelPointSystemConditionInput
  ) {
    createPointSystem(input: $input, condition: $condition) {
      id
      champId
      formula
      p1
      p2
      p3
      p4
      p5
      p6
      p7
      p8
      p9
      p10
      p11
      p12
      p13
      p14
      p15
      p16
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePointSystem = /* GraphQL */ `
  mutation UpdatePointSystem(
    $input: UpdatePointSystemInput!
    $condition: ModelPointSystemConditionInput
  ) {
    updatePointSystem(input: $input, condition: $condition) {
      id
      champId
      formula
      p1
      p2
      p3
      p4
      p5
      p6
      p7
      p8
      p9
      p10
      p11
      p12
      p13
      p14
      p15
      p16
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createTeam = /* GraphQL */ `
  mutation CreateTeam(
    $input: CreateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    createTeam(input: $input, condition: $condition) {
      id
      name
      country
      lider
      leadId
      instagram
      teamImage
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      name
      country
      lider
      leadId
      instagram
      teamImage
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam(
    $input: DeleteTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    deleteTeam(input: $input, condition: $condition) {
      id
      name
      country
      lider
      leadId
      instagram
      teamImage
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateTeamJoin = /* GraphQL */ `
  mutation UpdateTeamJoin(
    $input: UpdateTeamJoinInput!
    $condition: ModelTeamJoinConditionInput
  ) {
    updateTeamJoin(input: $input, condition: $condition) {
      id
      driverId
      teamId
      name
      psnId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteTeamJoin = /* GraphQL */ `
  mutation DeleteTeamJoin(
    $input: DeleteTeamJoinInput!
    $condition: ModelTeamJoinConditionInput
  ) {
    deleteTeamJoin(input: $input, condition: $condition) {
      id
      driverId
      teamId
      name
      psnId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createDriver = /* GraphQL */ `
  mutation CreateDriver(
    $input: CreateDriverInput!
    $condition: ModelDriverConditionInput
  ) {
    createDriver(input: $input, condition: $condition) {
      id
      user
      name
      psnId
      teamId
      country
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateDriver = /* GraphQL */ `
  mutation UpdateDriver(
    $input: UpdateDriverInput!
    $condition: ModelDriverConditionInput
  ) {
    updateDriver(input: $input, condition: $condition) {
      id
      user
      name
      psnId
      teamId
      country
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteDriver = /* GraphQL */ `
  mutation DeleteDriver(
    $input: DeleteDriverInput!
    $condition: ModelDriverConditionInput
  ) {
    deleteDriver(input: $input, condition: $condition) {
      id
      user
      name
      psnId
      teamId
      country
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createTrack = /* GraphQL */ `
  mutation CreateTrack(
    $input: CreateTrackInput!
    $condition: ModelTrackConditionInput
  ) {
    createTrack(input: $input, condition: $condition) {
      id
      order
      laps
      date
      name
      wheather
      prequaly
      claimAble
      pointsVisible
      gas
      tire
      champId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateTrack = /* GraphQL */ `
  mutation UpdateTrack(
    $input: UpdateTrackInput!
    $condition: ModelTrackConditionInput
  ) {
    updateTrack(input: $input, condition: $condition) {
      id
      order
      laps
      date
      name
      wheather
      prequaly
      claimAble
      pointsVisible
      gas
      tire
      champId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteTrack = /* GraphQL */ `
  mutation DeleteTrack(
    $input: DeleteTrackInput!
    $condition: ModelTrackConditionInput
  ) {
    deleteTrack(input: $input, condition: $condition) {
      id
      order
      laps
      date
      name
      wheather
      prequaly
      claimAble
      pointsVisible
      gas
      tire
      champId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createResult = /* GraphQL */ `
  mutation CreateResult(
    $input: CreateResultInput!
    $condition: ModelResultConditionInput
  ) {
    createResult(input: $input, condition: $condition) {
      id
      position
      lobby
      raceTime
      penalties
      finalTime
      trackId
      driverId
      psnId
      teamId
      champId
      dq
      forceTop
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateResult = /* GraphQL */ `
  mutation UpdateResult(
    $input: UpdateResultInput!
    $condition: ModelResultConditionInput
  ) {
    updateResult(input: $input, condition: $condition) {
      id
      position
      lobby
      raceTime
      penalties
      finalTime
      trackId
      driverId
      psnId
      teamId
      champId
      dq
      forceTop
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteResult = /* GraphQL */ `
  mutation DeleteResult(
    $input: DeleteResultInput!
    $condition: ModelResultConditionInput
  ) {
    deleteResult(input: $input, condition: $condition) {
      id
      position
      lobby
      raceTime
      penalties
      finalTime
      trackId
      driverId
      psnId
      teamId
      champId
      dq
      forceTop
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPoint = /* GraphQL */ `
  mutation CreatePoint(
    $input: CreatePointInput!
    $condition: ModelPointConditionInput
  ) {
    createPoint(input: $input, condition: $condition) {
      id
      points
      trackId
      driverId
      champId
      teamId
      psnId
      raceTime
      dq
      nationCode
      pointMembers
      driversInclude
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePoint = /* GraphQL */ `
  mutation UpdatePoint(
    $input: UpdatePointInput!
    $condition: ModelPointConditionInput
  ) {
    updatePoint(input: $input, condition: $condition) {
      id
      points
      trackId
      driverId
      champId
      teamId
      psnId
      raceTime
      dq
      nationCode
      pointMembers
      driversInclude
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePoint = /* GraphQL */ `
  mutation DeletePoint(
    $input: DeletePointInput!
    $condition: ModelPointConditionInput
  ) {
    deletePoint(input: $input, condition: $condition) {
      id
      points
      trackId
      driverId
      champId
      teamId
      psnId
      raceTime
      dq
      nationCode
      pointMembers
      driversInclude
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createClaim = /* GraphQL */ `
  mutation CreateClaim(
    $input: CreateClaimInput!
    $condition: ModelClaimConditionInput
  ) {
    createClaim(input: $input, condition: $condition) {
      id
      authorID
      champId
      trackId
      lobby
      driverClaimedId
      status
      penalty
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateClaim = /* GraphQL */ `
  mutation UpdateClaim(
    $input: UpdateClaimInput!
    $condition: ModelClaimConditionInput
  ) {
    updateClaim(input: $input, condition: $condition) {
      id
      authorID
      champId
      trackId
      lobby
      driverClaimedId
      status
      penalty
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteClaim = /* GraphQL */ `
  mutation DeleteClaim(
    $input: DeleteClaimInput!
    $condition: ModelClaimConditionInput
  ) {
    deleteClaim(input: $input, condition: $condition) {
      id
      authorID
      champId
      trackId
      lobby
      driverClaimedId
      status
      penalty
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createClaimEntry = /* GraphQL */ `
  mutation CreateClaimEntry(
    $input: CreateClaimEntryInput!
    $condition: ModelClaimEntryConditionInput
  ) {
    createClaimEntry(input: $input, condition: $condition) {
      id
      claimId
      psnId
      driverId
      description
      resource
      penalty
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateClaimEntry = /* GraphQL */ `
  mutation UpdateClaimEntry(
    $input: UpdateClaimEntryInput!
    $condition: ModelClaimEntryConditionInput
  ) {
    updateClaimEntry(input: $input, condition: $condition) {
      id
      claimId
      psnId
      driverId
      description
      resource
      penalty
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteClaimEntry = /* GraphQL */ `
  mutation DeleteClaimEntry(
    $input: DeleteClaimEntryInput!
    $condition: ModelClaimEntryConditionInput
  ) {
    deleteClaimEntry(input: $input, condition: $condition) {
      id
      claimId
      psnId
      driverId
      description
      resource
      penalty
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
