import React, {useState, useEffect} from "react";
import { useHistory } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import { showAlert } from '../../utils/Alert';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
} from "reactstrap";

import { Auth, DataStore, Hub } from 'aws-amplify'
import LoadingButton from "components/LoadingButton";
const alertRef = React.createRef();

const Login = props => {
  const history = useHistory();
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [confirmationShown, setConfirmationShown] = useState(false);
  const {confirmation} = props;
  
  useEffect(()=>{
    if(confirmation && !confirmationShown){      
      showAlert(alertRef, "success", "Tu cuenta esta activa por favor inicia sesion.");
      setConfirmationShown(true);
    }
    // get the data getSync redirect to the profile
    const removeListener = Hub.listen("datastore", async (capsule) => {
      const {
        payload: { event },
      } = capsule;
 
      if (event === "ready") {
        history.push('/admin/user-profile')
      }
    });  
 
    return () => {
      removeListener();
    };
  },[confirmation, history, setConfirmationShown, confirmationShown])

  const setFormValue = (fieldName , e) =>{    
    formData[fieldName] = e.target.value;
    setFormData(formData);    
  }

  const singIn = (e) =>{
    e.preventDefault();
    e.stopPropagation();
    if(!formData["username"] || !formData["password"]) {
      showAlert(alertRef, "danger", "Ingrese el email y la contraseña de su cuenta antes de continuar")
    }else{
      setLoading(true);
      const trimUserName = formData["username"].replace(/\s/g, '');
      Auth.signIn(trimUserName, formData["password"])
      .then(r =>{      
        // Start the DataStore, this kicks-off the sync process.
        // once the data is sync-up the user is redirect to the profile
        DataStore.start();          
      })
      .catch(e=>{
        setLoading(false);
        
        if(e && e.code === "UserNotConfirmedException"){
          showAlert(alertRef, "danger", "Por favor active su cuenta, con el enlace que se le envio por correo electronico")
        } 
        if(e && e.code === "UserNotFoundException"){
          showAlert(alertRef, "danger", "No existe un usuario con el correo electrónico ingresado")
        } 
        if(e && e.code === "NotAuthorizedException"){
          let message = "Usuario y/o contraseña incorrecto";
          if(e.message === "Password attempts exceeded"){
            message = "A excedido la cantidad maxima de intentos de inicio de sesion, por favor espere 5 minutos antes de intentar iniciar sesion nuevamente"
          }
          showAlert(alertRef, "danger", message)
        } 
      });
    }
  }

  return (
    <>
      <NotificationAlert ref={alertRef} />
      <div className="login-page">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <Form action="#" onSubmit={(e) => singIn(e)} className="form" method="">
                <Card className="card-login">
                  <CardHeader>
                    <CardHeader>
                      <h3 className="header text-center">Login</h3>
                    </CardHeader>
                  </CardHeader>
                  <CardBody>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input 
                      placeholder="Email" 
                      type="text" 
                      onChange={(e)=>setFormValue("username",e)} />
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Contraseña"
                        type="password"
                        onChange={(e)=>setFormValue("password",e)}
                        autoComplete="off"
                      />
                    </InputGroup>
                    <br/>
                    <a href="#" onClick={(e)=>history.push("/auth/password-forgot")}>Olvide mi contraseña</a>
                    <br/>
                    <br/>
                    <div>
                    <LoadingButton
                      block
                      className="btn-round mb-3"
                      color="success"
                      type="submit"
                      loading={loading}
                    >
                        Iniciar sesion 
                    </LoadingButton>
                    </div>
                    <div>
                    <Button 
                    color="facebook" 
                    block 
                    className="btn-round mb-3" 
                    onClick={() => Auth.federatedSignIn({provider: 'Facebook'})}
                    >
                        <i className="fa fa-facebook-square" />
                        Login
                    </Button>
                    </div>
                    </CardBody>
                  <CardFooter>
                    <Button
                      block
                      href = {"/auth/register"}
                      className="btn btn-default btn-round"
                    >
                        Registrate  
                    </Button>                    
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{                      
          backgroundImage: `url(${require("assets/img/bg/gt-champs/cars2.jpg")})`,          
          }}
        />
      </div>
      </>
    );
}

export default Login;
