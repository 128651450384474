
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import { showAlert } from 'utils/Alert';
import { API } from "aws-amplify";
import { createPointSystem, updatePointSystem } from "graphql/mutations";
import { pointSystemByChamp } from "graphql/queries";
import { useHistory } from "react-router-dom";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { DATATYPES } from "utils/Constants";
import { getNotDeletedItems } from "utils/Api";
import { cleanUpData } from "utils/Api";

const PointSystem = props => {
  const MAXIMO_PILOTOS = 16;
  const [pointSystemData, setPointSystemData] = useState({});
  const alertRef = React.createRef();
  const { id: champId } = useParams();
  let history = useHistory();

  useEffect(() => {
    if(champId){
      loadPointSystemData(champId);
    }
  }, [champId]); 

  const loadPointSystemData = (champId) => {
    API.graphql({
      query: pointSystemByChamp,
      variables:{
        "champId": champId
      },
      authMode: 'API_KEY'
    }).then((ps)=>{
      let pointSystem = {};
      if(ps && ps.data && ps.data.pointSystemByChamp){
        pointSystem = ps.data.pointSystemByChamp.items || [];
        pointSystem = getNotDeletedItems(pointSystem);
        pointSystem = pointSystem[0] || {};
      }
      setPointSystemData(pointSystem);
    }).catch((e)=>console.log("fetch point system error", e));    
  }

  const updatePointSystemData  =(field, value, datatype) => {
    let newPointSystemData = {
      ...pointSystemData,
      champId
    };
    if(DATATYPES.INT === datatype){
      value = parseInt(value);
    }
    newPointSystemData[field] = value;
    setPointSystemData(newPointSystemData);
  }

  const getFormValue = (field) => {
    return pointSystemData[field] === undefined ? "" : pointSystemData[field];
  }

  const savePointSystem = (e) =>{
    e.preventDefault();
    e.stopPropagation();

    const operation = (pointSystemData && pointSystemData.id) ? updatePointSystem : createPointSystem;

    API.graphql({
      query: operation,
      variables:{
        "input": cleanUpData(pointSystemData)
      },
      authMode: 'API_KEY'
    }).then((ps)=>{
      showAlert(alertRef, "success", "Informacion del sistema de puntos actualizada" );
      const data = ps.data.createPointSystem || ps.data.updatePointSystem;
      setPointSystemData(data);
    }).catch(e=>{
      console.log("error update point system data", e);
      showAlert(alertRef, "danger", "No fue posible actualizar la informacion del sistema de puntos " );
    });
    
  }

  const getPointEntries = () =>{
    let rows =[];
    for (let i = 1; i <= MAXIMO_PILOTOS; i++) {
      rows.push(
        (
        <Row key={`position${i}`}>
          <Label sm="2">P{i}</Label>
          <Col sm="2">
            <FormGroup>
              <Input 
                type="number" 
                value={getFormValue(`p${i}`)} 
                onChange={e => updatePointSystemData(`p${i}`,e.target.value,DATATYPES.INT)}
              />                        
            </FormGroup>
          </Col>
        </Row>
        )
      );
    }
    return rows;
  }

  return (
    <>
      <NotificationAlert ref={alertRef} />
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Sistema de Puntos</CardTitle>
              </CardHeader>
              <CardBody>
                <Form action="#" onSubmit={(e)=> savePointSystem(e)} className="form-horizontal" method="get">
                  <Row>
                    <Label sm="2">Formula</Label>                    
                    <Col sm="8">
                      <FormGroup>
                        <Input 
                          type="text"
                          value={getFormValue("formula")} 
                          onChange={e => updatePointSystemData("formula",e.target.value)}
                         />                        
                      </FormGroup>
                      <p>
                        <b>PP</b> = Puntos Posicion    <b>NS</b> = Numero de Sala
                      </p>
                    </Col>
                  </Row>
                  <hr/>
                  <h4> Puntos Posicion</h4>
                  {getPointEntries()}
                  <Row>
                    <Col sm={{size:'2', offset:'3'}}>
                      <Button color="success">
                        <span className="btn-label">
                          <i className="nc-icon nc-check-2" />
                        </span>
                        { champId ? 'Modificar' : 'Agregar' }
                      </Button> 
                    </Col>
                    <Col sm={{size:'4'}}>
                      <Button 
                        className="btn btn-danger" 
                        onClick={()=> history.push("/admin/champs")}
                        color="danger">
                        <i className="nc-icon nc-simple-remove" />
                          Cancelar
                      </Button>
                    </Col>
                  </Row>                
                </Form>
              </CardBody>
            </Card>
          </Col>          
        </Row>
      </div>
    </>
  );
}

export default PointSystem;