import React, { useEffect, useState } from "react";
import { getUserData, updateUserProperties, createCustomUserData } from '../../utils/Session';
import { showAlert } from '../../utils/Alert';
import { countryList } from '../../utils/Constants';
import NotificationAlert from "react-notification-alert";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Modal,
} from "reactstrap";
import { API } from "aws-amplify";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { getDriver } from "graphql/queries";
import { getNotDeletedItems } from "utils/Api";
import { champJoinsByDriver } from "graphql/queries";
import { updateChampJoin, updateDriver, createDriver } from "graphql/mutations";
import { cleanUpData } from "utils/Api";
import LoadingButton from "components/LoadingButton";

const NoProfilePicture = require("assets/img/no-profile-picture.png")

const UserProfile = (props) => {
  const systemAdminPassword = "OTZUdiNyTXtBMyVTcmVQRA=="
  const [userData, setUserData] = useState({});
  const [driverData, setDriverData] = useState({});
  const [openAdminModal, setOpenAdminModal] = useState(false);
  const [adminPassword, setAdminPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const alertRef = React.createRef();

  useEffect(() => {
    getUserData()
      .then(data => {
        if (!data.driverId) {
          API.graphql({
            query: createDriver,
            variables: {
              "input": {
                user: data.username,
                name: data.name
              }
            },
            authMode: 'API_KEY'
          }).then(async (r) => {
            const savedChampData = r.data.createDriver;
            getDriverData(savedChampData.id);
            const newUserData = {
              ...data,
              driverId: savedChampData.id
            };

            setUserData(savedChampData);
            const userProperties = createCustomUserData(newUserData);
            updateUserProperties(userProperties);
          }).catch((e) => {
            console.log("Error getting driver data", e);
          })
        } else {
          getDriverData(data.driverId);
          setUserData(data);
        }
      })
      .catch((e) => {
        console.log("Error getting user data", e);
      });
  }, []);

  const getDriverData = (driverId) => {
    API.graphql({
      query: getDriver,
      variables: {
        id: driverId
      },
      authMode: 'API_KEY'
    }).then(d => {
      let driver = {};
      if (d && d.data && d.data.getDriver) {
        driver = d.data.getDriver || {};
      }
      setDriverData(driver);
    });
  }

  const requestAdminAccess = () => {
    if (adminPassword === atob(systemAdminPassword)) {
      const customData = createCustomUserData({
        ...userData,
        roleId: "admin"
      });
      const userProperties = {
        ...userData,
        ...customData
      }

      updateUserProperties(userProperties).then(() => {
        toggleAdminModal();
        showAlert(alertRef, "success", "Permisos de administrador obtenidos, por favor actualize esta pagina");
      }).catch((e) => {
        console.log("error admin Profile", e);
        showAlert(alertRef, "danger", "No fue posible asignarlo como administrador");
      });
    } else {
      showAlert(alertRef, "danger", "Clave de autorizacion incorrecta");
    }
  }

  const updateChampJoinData = async () => {
    let champJoins = [];
    let champJoinsData = await API.graphql({
      query: champJoinsByDriver,
      variables: {
        "driverId": driverData.id
      },
      authMode: 'API_KEY'
    })
    if (champJoinsData && champJoinsData.data && champJoinsData.data.champJoinsByDriver) {
      champJoins = champJoinsData.data.champJoinsByDriver.items || [];
      champJoins = getNotDeletedItems(champJoins);
    }

    return champJoins.map(cj => {
      const newChampJoin = {
        ...cj,
        psnId: driverData.psnId,
        country: driverData.country
      }
      return API.graphql({
        query: updateChampJoin,
        variables: {
          "input": cleanUpData(newChampJoin)
        },
        authMode: 'API_KEY'
      })
    });
  }

  const updateProfile = (e) => {
    e.preventDefault();
    if (!isLoading) {
      setIsLoading(true);
      const customData = createCustomUserData(userData);
      const userProperties = {
        ...userData,
        ...customData
      }

      updateUserProperties(userProperties).then(() => {
        let promises = [];
        const updatedDriverData = {
          ...driverData
        };
        promises.push(
          API.graphql({
            query: updateDriver,
            variables: {
              "input": cleanUpData(updatedDriverData)
            },
            authMode: 'API_KEY'
          })
        );
        promises.push(updateChampJoinData());
        Promise.all(promises).then(d => {
          setIsLoading(false);
          setAlert(
            (
              <ReactBSAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Datos Guardados"
                onConfirm={() => setAlert(null)}
                onCancel={() => setAlert(null)}
                confirmBtnBsStyle="info"
                btnSize=""
              />
            )
          );
        })
      }).catch((e) => {
        console.log("error saving Profile", e);
        setIsLoading(false);
        setAlert(
          (
            <ReactBSAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Datos no guardados"
              onConfirm={() => setAlert(null)}
              onCancel={() => setAlert(null)}
              confirmBtnBsStyle="cancel"
              btnSize=""
            />
          )
        );
      });
    }
  }

  const updateUserData = (field, value) => {
    let newUserData = {
      ...userData
    };
    newUserData[field] = value;
    setUserData(newUserData);
  }

  const updateDriverData = (field, value) => {
    let newDriverData = {
      ...driverData
    };
    newDriverData[field] = value;
    setDriverData(newDriverData);
  }

  const toggleAdminModal = () => {
    setOpenAdminModal(!openAdminModal);
  }

  return (
    <>
      {alert}
      <div className="higest-z-index">
        <NotificationAlert ref={alertRef} />
      </div>
     <Modal
        isOpen={openAdminModal}
        toggle={toggleAdminModal}
      >
        <div className="modal-header justify-content-center">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleAdminModal()}
          >
            <i className="nc-icon nc-simple-remove" />
          </button>
          <h4 className="title title-up">Ser Administrador</h4>
        </div>
        <div className="modal-body">
          <FormGroup>
            <label>Clave de autorizacion</label>
            <Input
              type="password"
              onChange={(v) => setAdminPassword(v.target.value)}
            />
          </FormGroup>
        </div>
        <div className="modal-footer">
          <div className="left-side">
            <Button
              className="btn-link"
              color="success"
              data-dismiss="modal"
              type="button"
              onClick={() => requestAdminAccess()}
            >
              Enviar Solicitud
            </Button>
          </div>
          <div className="divider" />
          <div className="right-side">
            <Button
              className="btn-link"
              color="danger"
              type="button"
              onClick={() => toggleAdminModal()}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </Modal>
      <div className="content">
        <Form action="#" onSubmit={(e) => updateProfile(e)}>
          <Row>
            <Col md="4">
              <Card className="card-user">
                <div className="image">
                  <img
                    alt="..."
                    src={require("assets/img/bg/gt-champs/gt-sport.png")}
                  />
                </div>
                <CardBody>
                  <div className="author">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="avatar border-gray"
                        src={userData.picture || NoProfilePicture}
                      />
                      <h5 className="title">{userData.name}</h5>
                    </a>
                    <p className="description">{userData.email}</p>
                    <Button
                      className="btn btn-primary hidden"
                      id="tooltip8694850302"
                      size="sm"
                      type="button"
                      onClick={() => toggleAdminModal()}
                    >
                      Ser Admin
                  </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="8">
              <Card>
                <CardHeader>
                  <h5 className="title">Editar Perfil</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col className="pr-1" md="5">
                      <FormGroup>
                        <label>Nombre</label>
                        <Input
                          defaultValue={userData.name}
                          placeholder="Nombre"
                          type="text"
                          onChange={e => updateUserData('name', e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>Email</label>
                        <Input
                          defaultValue={userData.email}
                          placeholder="Email"
                          type="email"
                          onChange={e => updateUserData('email', e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="5">
                      <FormGroup>
                        <label>PSN ID</label>
                        <Input
                          value={(driverData && driverData.psnId) ? driverData.psnId : ""}
                          placeholder="PSN Id"
                          type="text"
                          onChange={e => updateDriverData('psnId', e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="5">
                      <FormGroup>
                        <label>Pais</label>
                        <Input
                          type="select"
                          value={(driverData && driverData.country) ? driverData.country : ""}
                          onChange={e => updateDriverData('country', e.target.value)}
                        >
                          <option>Seleccione uno</option>
                          {Object.keys(countryList).map(countrycode => (
                            <option key={`option-${countrycode}`} value={countrycode}>{countryList[countrycode]}</option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
             </Col>
          </Row>
          <Row>
            <Col sm={{ size: '2', offset: '4' }}>
              <div className="floating-mobile-bottom-div">
                <LoadingButton loading={isLoading} color="success" block="false" type="submit">
                  <span className="btn-label">
                    <i className="nc-icon nc-check-2" />
                  </span>
                Actualizar
              </LoadingButton>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );

}

export default UserProfile;
