export const getNotDeletedItems = (items) => {    
    return items.filter(i => i._deleted == null || !i._deleted);
}

export const cleanUpData = (item) => {  
    let modelData = {...item};
    delete modelData._deleted;
    delete modelData._lastChangedAt;
    delete modelData.createdAt;    
    delete modelData.updatedAt;    
    delete modelData.owner;    
    return modelData;
}
    