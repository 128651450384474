export const DATATYPES = {
    'INT':"int",
    'STRING':"String",
    'CHAMP':"champ",
    'RESULT_TIME':"resultTime",
    'BOOLEAN':"bool",
    'OBJECT':"object"
}

export const RACE_TIME_FORMAT = [
    { char: /\d/, repeat: 2 },
    { exactly: ":" },
    { char: /\d/, repeat: 2 },
    { exactly: ":" },
    { char: /\d/, repeat: 2 },
    { exactly: "." },
    { char: /\d/, repeat: 3 }
]

export const calculateRaceTime = (timeString) =>{
    let time = 0;
    const hourParts = timeString.split(":");
    if(hourParts.length === 3){
        const secondParts = hourParts[2].split(".");

        const hours = Number(hourParts[0]);
        const minutes = Number(hourParts[1]);
        const seconds = Number(secondParts[0]);
        const miliSecond = Number(secondParts[1]);

        time = miliSecond + (seconds*1000)+ (minutes*1000*60) + (hours*1000*60*60);
    }
    return time; 
}

const pad = (value, size ) =>{
    var s = String(value);
    while (s.length < (size || 2)) {s = "0" + s;}
    return s;
}

export const formatRaceTime = (raceTime) =>{
    if(raceTime){
        const HOUR_Millisencods = 1000*60*60;
        const MINUTE_MILLISECONDS = 1000*60;
        const SECONDS_MILLISECONDS = 1000;
        let hours = Math.trunc(raceTime / HOUR_Millisencods);
        let minutes = Math.trunc((raceTime - (hours*HOUR_Millisencods) ) / MINUTE_MILLISECONDS);
        let seconds = Math.trunc((raceTime - (hours*HOUR_Millisencods) - (minutes*MINUTE_MILLISECONDS) ) / SECONDS_MILLISECONDS);
        let milliSeconds = raceTime - (hours * HOUR_Millisencods) - (minutes * MINUTE_MILLISECONDS) - (seconds * SECONDS_MILLISECONDS)
        return ""+pad(hours,2)+":"+pad(minutes,2) +":"+pad(seconds,2)+"."+pad(milliSeconds,3);
    }else{
        return "00:00:00.000";
    }
}

export const isNumeric = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export const DEFAULT_RESULT_TIME = "00:00:00.000"

export const  DRIVER_TEAM_FILTER_OPTIONS = [
    {label:"Mostar Todos",value:"all"},
    {label:"Pilotos",value:"driver"},
    {label:"Equipos",value:"team"}
  ]

export const countryList = {
    "AG": "Antigua and Barbuda",
    "AR": "Argentina",
    "BS": "Bahamas",
    "BB": "Barbados",
    "BZ": "Belize",
    "BM": "Bermuda",
    "BO": "Bolivia",
    "BR": "Brazil",
    "CL": "Chile",
    "CO": "Colombia",
    "CR": "Costa Rica",
    "DO": "Republica Dominicana",
    "EC": "Ecuador",
    "SV": "El Salvador",
    "GT": "Guatemala",
    "HN": "Honduras",
    "MX": "Mexico",
    "NI": "Nicaragua",
    "PA": "Panama",
    "PY": "Paraguay",
    "PE": "Peru",
    "PR": "Puerto Rico",
    "TT": "Trinidad and Tobago",
    "US": "United States of America",
    "UY": "Uruguay",
    "VE": "Venezuela",
};