import {Auth, DataStore} from 'aws-amplify';

export const isLogin = async () => {    
    const user = await Auth.currentAuthenticatedUser().catch((e) => {
        return false;
    });    
    return user.username || false;
}

export const getUserData = async () => {    
    const currentUserInfo = await Auth.currentUserInfo().catch((e) => {
        return false;
    });

    let picture = "";
    if(currentUserInfo && currentUserInfo.attributes.picture){
        const pictureData = JSON.parse(currentUserInfo.attributes.picture);
        if(pictureData.data && pictureData.data.url){
            picture =pictureData.data.url;
        }
    }
    
    const userdata ={
        username : currentUserInfo.username,
        name : currentUserInfo.attributes.name,
        email : currentUserInfo.attributes.email,
        picture,
        driverId : currentUserInfo.attributes['custom:driverId']|| "",
        roleId : currentUserInfo.attributes['custom:roleId']|| ""
    } 
    return userdata;
}

export const createCustomUserData = (properties) => {
    return {
        "custom:driverId": properties.driverId || "",
        "custom:roleId":  properties.roleId || "",
        "custom:role": "",
    }
}

export const updateUserProperties = async (properties) => {
    let newProperties = {
        ...properties
    }
    delete newProperties.driverId;
    delete newProperties.roleId;
    delete newProperties.username;
    delete newProperties.picture;
    
    const user = await Auth.currentAuthenticatedUser().catch((e) => {
        return false;
    });   
    return await Auth.updateUserAttributes(user, newProperties).catch((e) => {
        console.log("Error Update user data",e);
        return false;
    });
}

export const logout = async () => {
    await Auth.signOut();
    await DataStore.clear();
}