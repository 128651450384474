/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getChamp = /* GraphQL */ `
  query GetChamp($id: ID!) {
    getChamp(id: $id) {
      id
      name
      maxDriverPerLobby
      startDate
      inscriptionURL
      rulesUrl
      status
      champImage
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listChamps = /* GraphQL */ `
  query ListChamps(
    $filter: ModelChampFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChamps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        maxDriverPerLobby
        startDate
        inscriptionURL
        rulesUrl
        status
        champImage
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncChamps = /* GraphQL */ `
  query SyncChamps(
    $filter: ModelChampFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChamps(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        maxDriverPerLobby
        startDate
        inscriptionURL
        rulesUrl
        status
        champImage
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getChampJoin = /* GraphQL */ `
  query GetChampJoin($id: ID!) {
    getChampJoin(id: $id) {
      id
      name
      psnId
      driverId
      champId
      status
      type
      teamName
      teamId
      country
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listChampJoins = /* GraphQL */ `
  query ListChampJoins(
    $filter: ModelChampJoinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChampJoins(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        psnId
        driverId
        champId
        status
        type
        teamName
        teamId
        country
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const champJoinsByChamp = /* GraphQL */ `
  query ChampJoinsByChamp(
    $champId: String
    $sortDirection: ModelSortDirection
    $filter: ModelChampJoinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    champJoinsByChamp(
      champId: $champId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        psnId
        driverId
        champId
        status
        type
        teamName
        teamId
        country
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const champJoinsByDriver = /* GraphQL */ `
  query ChampJoinsByDriver(
    $driverId: String
    $sortDirection: ModelSortDirection
    $filter: ModelChampJoinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    champJoinsByDriver(
      driverId: $driverId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        psnId
        driverId
        champId
        status
        type
        teamName
        teamId
        country
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const champJoinsByTeam = /* GraphQL */ `
  query ChampJoinsByTeam(
    $teamId: String
    $sortDirection: ModelSortDirection
    $filter: ModelChampJoinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    champJoinsByTeam(
      teamId: $teamId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        psnId
        driverId
        champId
        status
        type
        teamName
        teamId
        country
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const champJoinsByChampAndType = /* GraphQL */ `
  query ChampJoinsByChampAndType(
    $champId: String
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChampJoinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    champJoinsByChampAndType(
      champId: $champId
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        psnId
        driverId
        champId
        status
        type
        teamName
        teamId
        country
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncChampJoins = /* GraphQL */ `
  query SyncChampJoins(
    $filter: ModelChampJoinFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChampJoins(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        psnId
        driverId
        champId
        status
        type
        teamName
        teamId
        country
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getPointSystem = /* GraphQL */ `
  query GetPointSystem($id: ID!) {
    getPointSystem(id: $id) {
      id
      champId
      formula
      p1
      p2
      p3
      p4
      p5
      p6
      p7
      p8
      p9
      p10
      p11
      p12
      p13
      p14
      p15
      p16
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPointSystems = /* GraphQL */ `
  query ListPointSystems(
    $filter: ModelPointSystemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPointSystems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        champId
        formula
        p1
        p2
        p3
        p4
        p5
        p6
        p7
        p8
        p9
        p10
        p11
        p12
        p13
        p14
        p15
        p16
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const pointSystemByChamp = /* GraphQL */ `
  query PointSystemByChamp(
    $champId: String
    $sortDirection: ModelSortDirection
    $filter: ModelPointSystemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pointSystemByChamp(
      champId: $champId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        champId
        formula
        p1
        p2
        p3
        p4
        p5
        p6
        p7
        p8
        p9
        p10
        p11
        p12
        p13
        p14
        p15
        p16
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPointSystems = /* GraphQL */ `
  query SyncPointSystems(
    $filter: ModelPointSystemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPointSystems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        champId
        formula
        p1
        p2
        p3
        p4
        p5
        p6
        p7
        p8
        p9
        p10
        p11
        p12
        p13
        p14
        p15
        p16
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      name
      country
      lider
      leadId
      instagram
      teamImage
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        country
        lider
        leadId
        instagram
        teamImage
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTeams = /* GraphQL */ `
  query SyncTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTeams(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        country
        lider
        leadId
        instagram
        teamImage
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getTeamJoin = /* GraphQL */ `
  query GetTeamJoin($id: ID!) {
    getTeamJoin(id: $id) {
      id
      driverId
      teamId
      name
      psnId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listTeamJoins = /* GraphQL */ `
  query ListTeamJoins(
    $filter: ModelTeamJoinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamJoins(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        driverId
        teamId
        name
        psnId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const teamJoinsByTeam = /* GraphQL */ `
  query TeamJoinsByTeam(
    $teamId: String
    $sortDirection: ModelSortDirection
    $filter: ModelTeamJoinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamJoinsByTeam(
      teamId: $teamId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        driverId
        teamId
        name
        psnId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTeamJoins = /* GraphQL */ `
  query SyncTeamJoins(
    $filter: ModelTeamJoinFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTeamJoins(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        driverId
        teamId
        name
        psnId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getDriver = /* GraphQL */ `
  query GetDriver($id: ID!) {
    getDriver(id: $id) {
      id
      user
      name
      psnId
      teamId
      country
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listDrivers = /* GraphQL */ `
  query ListDrivers(
    $filter: ModelDriverFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDrivers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        name
        psnId
        teamId
        country
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const driversByTeam = /* GraphQL */ `
  query DriversByTeam(
    $teamId: String
    $sortDirection: ModelSortDirection
    $filter: ModelDriverFilterInput
    $limit: Int
    $nextToken: String
  ) {
    driversByTeam(
      teamId: $teamId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        name
        psnId
        teamId
        country
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const driversByCountry = /* GraphQL */ `
  query DriversByCountry(
    $country: String
    $sortDirection: ModelSortDirection
    $filter: ModelDriverFilterInput
    $limit: Int
    $nextToken: String
  ) {
    driversByCountry(
      country: $country
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        name
        psnId
        teamId
        country
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDrivers = /* GraphQL */ `
  query SyncDrivers(
    $filter: ModelDriverFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDrivers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        user
        name
        psnId
        teamId
        country
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getTrack = /* GraphQL */ `
  query GetTrack($id: ID!) {
    getTrack(id: $id) {
      id
      order
      laps
      date
      name
      wheather
      prequaly
      claimAble
      pointsVisible
      gas
      tire
      champId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listTracks = /* GraphQL */ `
  query ListTracks(
    $filter: ModelTrackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTracks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        order
        laps
        date
        name
        wheather
        prequaly
        claimAble
        pointsVisible
        gas
        tire
        champId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const tracksByChamp = /* GraphQL */ `
  query TracksByChamp(
    $champId: String
    $sortDirection: ModelSortDirection
    $filter: ModelTrackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tracksByChamp(
      champId: $champId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        order
        laps
        date
        name
        wheather
        prequaly
        claimAble
        pointsVisible
        gas
        tire
        champId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTracks = /* GraphQL */ `
  query SyncTracks(
    $filter: ModelTrackFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTracks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        order
        laps
        date
        name
        wheather
        prequaly
        claimAble
        pointsVisible
        gas
        tire
        champId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getResult = /* GraphQL */ `
  query GetResult($id: ID!) {
    getResult(id: $id) {
      id
      position
      lobby
      raceTime
      penalties
      finalTime
      trackId
      driverId
      psnId
      teamId
      champId
      dq
      forceTop
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listResults = /* GraphQL */ `
  query ListResults(
    $filter: ModelResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResults(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        position
        lobby
        raceTime
        penalties
        finalTime
        trackId
        driverId
        psnId
        teamId
        champId
        dq
        forceTop
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const resultsByChamp = /* GraphQL */ `
  query ResultsByChamp(
    $champId: String
    $sortDirection: ModelSortDirection
    $filter: ModelResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resultsByChamp(
      champId: $champId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        position
        lobby
        raceTime
        penalties
        finalTime
        trackId
        driverId
        psnId
        teamId
        champId
        dq
        forceTop
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const resultsByTrack = /* GraphQL */ `
  query ResultsByTrack(
    $trackId: String
    $sortDirection: ModelSortDirection
    $filter: ModelResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resultsByTrack(
      trackId: $trackId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        position
        lobby
        raceTime
        penalties
        finalTime
        trackId
        driverId
        psnId
        teamId
        champId
        dq
        forceTop
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const resultsByDriver = /* GraphQL */ `
  query ResultsByDriver(
    $driverId: String
    $sortDirection: ModelSortDirection
    $filter: ModelResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resultsByDriver(
      driverId: $driverId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        position
        lobby
        raceTime
        penalties
        finalTime
        trackId
        driverId
        psnId
        teamId
        champId
        dq
        forceTop
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const resultsByTeam = /* GraphQL */ `
  query ResultsByTeam(
    $teamId: String
    $sortDirection: ModelSortDirection
    $filter: ModelResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resultsByTeam(
      teamId: $teamId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        position
        lobby
        raceTime
        penalties
        finalTime
        trackId
        driverId
        psnId
        teamId
        champId
        dq
        forceTop
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const resultsByTrackTeam = /* GraphQL */ `
  query ResultsByTrackTeam(
    $trackId: String
    $teamId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resultsByTrackTeam(
      trackId: $trackId
      teamId: $teamId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        position
        lobby
        raceTime
        penalties
        finalTime
        trackId
        driverId
        psnId
        teamId
        champId
        dq
        forceTop
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const resultsByTrackDriver = /* GraphQL */ `
  query ResultsByTrackDriver(
    $trackId: String
    $driverId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resultsByTrackDriver(
      trackId: $trackId
      driverId: $driverId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        position
        lobby
        raceTime
        penalties
        finalTime
        trackId
        driverId
        psnId
        teamId
        champId
        dq
        forceTop
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncResults = /* GraphQL */ `
  query SyncResults(
    $filter: ModelResultFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncResults(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        position
        lobby
        raceTime
        penalties
        finalTime
        trackId
        driverId
        psnId
        teamId
        champId
        dq
        forceTop
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getPoint = /* GraphQL */ `
  query GetPoint($id: ID!) {
    getPoint(id: $id) {
      id
      points
      trackId
      driverId
      champId
      teamId
      psnId
      raceTime
      dq
      nationCode
      pointMembers
      driversInclude
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPoints = /* GraphQL */ `
  query ListPoints(
    $filter: ModelPointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPoints(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        points
        trackId
        driverId
        champId
        teamId
        psnId
        raceTime
        dq
        nationCode
        pointMembers
        driversInclude
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const pointsByChamp = /* GraphQL */ `
  query PointsByChamp(
    $champId: String
    $sortDirection: ModelSortDirection
    $filter: ModelPointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pointsByChamp(
      champId: $champId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        points
        trackId
        driverId
        champId
        teamId
        psnId
        raceTime
        dq
        nationCode
        pointMembers
        driversInclude
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const pointsByTeam = /* GraphQL */ `
  query PointsByTeam(
    $teamId: String
    $sortDirection: ModelSortDirection
    $filter: ModelPointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pointsByTeam(
      teamId: $teamId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        points
        trackId
        driverId
        champId
        teamId
        psnId
        raceTime
        dq
        nationCode
        pointMembers
        driversInclude
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const pointsByTrack = /* GraphQL */ `
  query PointsByTrack(
    $trackId: String
    $sortDirection: ModelSortDirection
    $filter: ModelPointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pointsByTrack(
      trackId: $trackId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        points
        trackId
        driverId
        champId
        teamId
        psnId
        raceTime
        dq
        nationCode
        pointMembers
        driversInclude
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPoints = /* GraphQL */ `
  query SyncPoints(
    $filter: ModelPointFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPoints(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        points
        trackId
        driverId
        champId
        teamId
        psnId
        raceTime
        dq
        nationCode
        pointMembers
        driversInclude
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getClaim = /* GraphQL */ `
  query GetClaim($id: ID!) {
    getClaim(id: $id) {
      id
      authorID
      champId
      trackId
      lobby
      driverClaimedId
      status
      penalty
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listClaims = /* GraphQL */ `
  query ListClaims(
    $filter: ModelClaimFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClaims(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        authorID
        champId
        trackId
        lobby
        driverClaimedId
        status
        penalty
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const claimsByChamp = /* GraphQL */ `
  query ClaimsByChamp(
    $champId: String
    $sortDirection: ModelSortDirection
    $filter: ModelClaimFilterInput
    $limit: Int
    $nextToken: String
  ) {
    claimsByChamp(
      champId: $champId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        authorID
        champId
        trackId
        lobby
        driverClaimedId
        status
        penalty
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const claimsByTrack = /* GraphQL */ `
  query ClaimsByTrack(
    $trackId: String
    $sortDirection: ModelSortDirection
    $filter: ModelClaimFilterInput
    $limit: Int
    $nextToken: String
  ) {
    claimsByTrack(
      trackId: $trackId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        authorID
        champId
        trackId
        lobby
        driverClaimedId
        status
        penalty
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncClaims = /* GraphQL */ `
  query SyncClaims(
    $filter: ModelClaimFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClaims(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        authorID
        champId
        trackId
        lobby
        driverClaimedId
        status
        penalty
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getClaimEntry = /* GraphQL */ `
  query GetClaimEntry($id: ID!) {
    getClaimEntry(id: $id) {
      id
      claimId
      psnId
      driverId
      description
      resource
      penalty
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listClaimEntrys = /* GraphQL */ `
  query ListClaimEntrys(
    $filter: ModelClaimEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClaimEntrys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        claimId
        psnId
        driverId
        description
        resource
        penalty
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const entriesByClaim = /* GraphQL */ `
  query EntriesByClaim(
    $claimId: String
    $sortDirection: ModelSortDirection
    $filter: ModelClaimEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    entriesByClaim(
      claimId: $claimId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        claimId
        psnId
        driverId
        description
        resource
        penalty
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncClaimEntries = /* GraphQL */ `
  query SyncClaimEntries(
    $filter: ModelClaimEntryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClaimEntries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        claimId
        psnId
        driverId
        description
        resource
        penalty
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
