import React, {useState, useEffect} from "react";
import { useHistory } from "react-router-dom";
import { Auth } from 'aws-amplify'
import NotificationAlert from "react-notification-alert";
import { showAlert } from '../../utils/Alert';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

const Register = (props) =>  {
  const history = useHistory();
  const alertRef = React.createRef();
  const [formData, setFormData] = useState({});

  const setFormValue = (fieldName , e) =>{    
    formData[fieldName] = e.target.value;
    setFormData(formData);    
  }

  useEffect(()=>{
    document.body.classList.toggle("register-page");
    return () =>{
      document.body.classList.toggle("register-page");
    }
  })

  const registerUser = (e) =>{
    e.preventDefault();
    const {password, name , repassword, username} = formData;
    if(!username || !name || !password || !repassword){
      showAlert(alertRef, "danger", "Por favor ingrese la informacion solicitada");
      return;
    }

    if(password !== repassword){
      showAlert(alertRef, "danger", "La confirmacion de contraseña y la contraseña no son iguales");
      return;
    }

    const trimUserName = username.replace(/\s/g, '');
    Auth.signUp({
      username : trimUserName,
      password,
      attributes: {
        name:name
      } 
    }).then((u)=>{      
      history.push("/auth/validate-account");      
    }).catch((e)=>{
      console.log("Sign up error",e);
      let message = "";
      switch (e.name) {
        case "UsernameExistsException":
          message = "Ya existe una usuario con el correo ingresado";
          break; 
        case "InvalidPasswordException":
          message = "La contraseña debe tener al menos 8 caracteres";
          break;  
              
        default:
          message = "No ha sido posible crear el usuario";
          break;
      }
      showAlert(alertRef, "danger", message);
    });


  }
  
  return (
    <div className="register-page">
      <NotificationAlert ref={alertRef} />
      <Container>
        <Row>
          <Col className="ml-auto" lg="5" md="5">
            <div className="info-area info-horizontal mt-5">
              <div className="icon icon-primary">
                <i className="nc-icon nc-tv-2" />
              </div>
              <div className="description">
                <h5 className="info-title">Estado de Campeonatos</h5>
                <p className="description">
                  Obten la informacion de tu campeonato en tiempo Real, y ten control de los puntos
                  que has generado en el campeonato
                </p>
              </div>
            </div>
            <div className="info-area info-horizontal">
              <div className="icon icon-primary">
                <i className="nc-icon nc-html5" />
              </div>
              <div className="description">
                <h5 className="info-title">Escuderias</h5>
                <p className="description">
                  Forma tu escuderia y suma puntos para el campeonato de escuderias
                </p>
              </div>
            </div>
            <div className="info-area info-horizontal">
              <div className="icon icon-info">
                <i className="nc-icon nc-atom" />
              </div>
              <div className="description">
                <h5 className="info-title">Administra tus Reclamos</h5>
                <p className="description">
                  Ten visibilidad de los reclamos creados en cada fecha y sus respectivas resoluciones
                </p>
              </div>
            </div>
          </Col>
          <Col className="mr-auto" lg="4" md="6">
            <Card className="card-signup text-center">
              <CardHeader>
                <CardTitle tag="h4">Registro</CardTitle>
                <div className="social">
                  <Button 
                    color="facebook" 
                    block 
                    className="btn-round mb-3" 
                    onClick={() => Auth.federatedSignIn({provider: 'Facebook'})}
                    >
                        <i className="fa fa-facebook-square" />
                        Inicia sesion con Facebook
                    </Button>
                  <p className="card-description">O usa tu correo electrónico</p>
                </div>
              </CardHeader>
              <CardBody>
                <Form action="#" className="form" method="">                                    
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                    placeholder="Nombre" 
                    type="text" 
                    onChange={(e)=>setFormValue("name",e)} 
                    />
                  </InputGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-email-85" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                    placeholder="Email..." 
                    type="email" 
                    onChange={(e)=>setFormValue("username",e)} 
                    />
                  </InputGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-key-25" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                    placeholder="Contraseña" 
                    type="password"
                    onChange={(e)=>setFormValue("password",e)} />
                  </InputGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-key-25" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                    placeholder="Confirme la Contraseña" 
                    type="password"
                    onChange={(e)=>setFormValue("repassword",e)} />
                  </InputGroup>                 
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  className="btn-round"
                  color="info"
                  onClick={(e) => registerUser(e)}
                >
                  Registrarse
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
      <div
        className="full-page-background"
        style={{
          backgroundImage: `url(${require("assets/img/bg/gt-champs/cars.jpg")})`,          
        }}
      />
    </div>
  );
}

export default Register;
