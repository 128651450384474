
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { API, Storage } from "aws-amplify";
import { getChamp } from "graphql/queries";
import { createChamp, updateChamp } from "graphql/mutations";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useHistory, useParams } from "react-router-dom";
import { DATATYPES } from "utils/Constants";
import { cleanUpData } from "utils/Api";
import NotificationAlert from "react-notification-alert";
import { showAlert } from '../../../utils/Alert';
const alertRef = React.createRef();

const AddChamp = props => {
  let history = useHistory();
  const { id: champId } = useParams();

  const [champImage, setChampImage] = useState(null);
  const [alert, setAlert] = useState(null);
  const [champData, setChampData] = useState({});

  useEffect(() => {
    if (champId) {
      loadChampData(champId);
    }
  }, [champId]);

  const loadChampData = (champId) => {
    API.graphql({
      query: getChamp,
      variables: {
        id: champId
      },
      authMode: 'API_KEY'
    }).then((c) => {
      let champ = {}
      if (c && c.data && c.data.getChamp) {
        champ = c.data.getChamp || {};
      }
      setChampData(champ);
    }).catch((e) => console.log("fetch Champ data error", e));
  }

  const updateChampData = (field, value, datatype) => {
    let newChampData = {
      ...champData
    };
    if (DATATYPES.INT === datatype) {
      value = parseInt(value);
    }
    newChampData[field] = value;
    setChampData(newChampData);
  }

  const getFormValue = (field) => {
    return champData[field] === undefined ? "" : champData[field];
  }

  const getChampImagePath = (champId) => {
    return `champs/image/${champId}.jpg`;
  }

  const updateChampImage = async (champId, file) => {
    try {
      if (champId) {
        const imagePath = getChampImagePath(champId);
        await Storage.put(imagePath, file, {
          contentType: 'image/jpg'
        });
        updateChampData('champImage', imagePath)
      } else {
        setChampImage(file);
      }

    } catch (error) {
      console.log('Error uploading file: ', error);
      showAlert(alertRef, "danger", "No fue posible guardar la imagen");
    }
  }

  const addChamp = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const operationName = (champData && champData.id) ? "updateChamp" : "createChamp";
    const operation = operationName === "updateChamp" ? updateChamp : createChamp;
    API.graphql({
      query: operation,
      variables: {
        "input": cleanUpData(champData)
      },
      authMode: 'API_KEY'
    }).then(async (r) => {
      const savedChampData = r.data[operationName];
      if (champImage) {
        if (operation === createChamp) {
          const newChampData = {
            ...savedChampData,
            champImage: getChampImagePath(savedChampData.id)
          };
          await API.graphql({
            query: updateChamp,
            variables: {
              "input": cleanUpData(newChampData)
            },
            authMode: 'API_KEY'
          });
        }

        await updateChampImage(savedChampData.id, champImage);
      }
      setAlert(
        (
          <ReactBSAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Datos Guardados"
            onConfirm={() => history.push("/admin/champs")}
            confirmBtnBsStyle="info"
            btnSize=""
          />
        )
      );
    }).catch(e => {
      console.log("save error", e);
      setAlert(
        (
          <ReactBSAlert
            danger
            style={{ display: "block", marginTop: "-100px" }}
            title="Datos no guardados"
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="cancel"
            btnSize=""
          />
        )
      );
    });

  }
  return (
    <>
      {alert}
      <NotificationAlert ref={alertRef} />
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  {champId ? 'Modificar' : 'Agregar'} Campeonato
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Form action="#" onSubmit={(e) => addChamp(e)} className="form-horizontal" method="get">
                  <Row>
                    <Label sm="2">Nombre</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="text" value={getFormValue("name")} onChange={e => updateChampData('name', e.target.value)} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Pilotos por Sala</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="text" value={getFormValue("maxDriverPerLobby")} onChange={e => updateChampData('maxDriverPerLobby', e.target.value)} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Fecha de Inicio</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="text" value={getFormValue("startDate")} onChange={e => updateChampData('startDate', e.target.value)} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Inscripciones</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="text" value={getFormValue("rulesUrl")} onChange={e => updateChampData('rulesUrl', e.target.value)} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Reglamento</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="text" value={getFormValue("inscriptionURL")} onChange={e => updateChampData('inscriptionURL', e.target.value)} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Estado</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input
                          type="select"
                          value={getFormValue("status")}
                          onChange={(v) => updateChampData('status', v.target.value)}
                        >
                          <option key={"champ-open"} value="open">Abierto</option>
                          <option key={"champ-close"} value="close">Cerrado</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Imagen Campeonato</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input
                          type="file"
                          onChange={(e) => updateChampImage(champId, e.target.files[0])}
                        >
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={{ size: '2', offset: '3' }}>
                      <Button color="success">
                        <span className="btn-label">
                          <i className="nc-icon nc-check-2" />
                        </span>
                        {champId ? 'Modificar' : 'Agregar'}
                      </Button>
                    </Col>
                    <Col sm={{ size: '4' }}>
                      <Button className="btn btn-danger" onClick={() => { history.push("/admin/champs") }} color="danger">
                        <i className="nc-icon nc-simple-remove" />
                        Cancelar
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AddChamp;