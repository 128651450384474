
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import { showAlert } from 'utils/Alert';
import { API } from "aws-amplify";
import { createResult } from "graphql/mutations";
import { getTrack, champJoinsByChamp, getChamp } from "graphql/queries";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { FormattedInput } from "@buttercup/react-formatted-input";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { DATATYPES } from "utils/Constants";
import { cleanUpData } from "utils/Api";
import { RACE_TIME_FORMAT,DEFAULT_RESULT_TIME,calculateRaceTime } from "utils/Constants";

const AddResults = props => {
  const [trackData, setTrackData] = useState({});
  const [resultsData, setResultsData] = useState([]);
  const [champData, setChampData] = useState({});
  const [lobby, setLobby] = useState(1);
  const [drivers, setDrivers] = useState([]);
  const alertRef = React.createRef();
  const {trackId } = useParams();
  let history = useHistory();

  useEffect(() => {
    if(trackId){
      getTrackData(trackId);
    }
   
    if(trackData && trackData.id){
      fetchChampDrivers(trackData.champId);
      fetchChampData(trackData.champId);
    }
  }, [trackId ,trackData.id]); 

  const getTrackData = (trackId) => {
    API.graphql({
      query: getTrack,
      variables:{
        "id": trackId
      },
      authMode: 'API_KEY'
    }).then((ps)=>{
      let track = {};
      if(ps && ps.data && ps.data.getTrack){
        track = ps.data.getTrack || {};
      }
      setTrackData(track);
    }).catch((e)=>console.log("fetch track data error", e));    
  }

  const fetchChampDrivers = (champId) => {
    API.graphql({
      query: champJoinsByChamp,
      variables:{
        "champId": champId,
        "limit": 1500,
        "filter":{
          "status":{
            "eq":"accepted"
          },
          "type":{
            "eq":"driver"
          }
        }
      },
      authMode: 'API_KEY'
    }).then((d)=>{
      let drivers = [];
      if(d && d.data && d.data.champJoinsByChamp){
        drivers = d.data.champJoinsByChamp.items || {};
      }
      setDrivers(drivers);
    }).catch((e)=>console.log("fetch champ drivers data error", e));    
  }

  const fetchChampData = (champId) => {
    API.graphql({
      query: getChamp,
      variables:{
        "id": champId
      },
      authMode: 'API_KEY'
    }).then((d)=>{
      let champ = [];
      if(d && d.data && d.data.getChamp){
        champ = d.data.getChamp || {};
      }
      setChampData(champ);
    }).catch((e)=>console.log("fetch champ data error", e));    
  }

  const clearResultRowData = (position) => {
    let newResultData = [...resultsData];
    if(newResultData[position]){
      delete newResultData[position];
      setResultsData(newResultData);
    }
  }

  const updateResultRowData = (position, field, value, datatype) =>{
    let previousData = (resultsData && resultsData[position]) ? resultsData[position] : {position,lobby,trackId};
    
    let newResultEntry = {
      ...previousData,
      lobby,
      trackId
    }
    
    if(DATATYPES.INT === datatype){
      value = value === "" ? "0" : value;
      value = parseInt(value);
    }
    if(DATATYPES.RESULT_TIME === datatype && value !== DEFAULT_RESULT_TIME && value.length === 12){
      value = calculateRaceTime(value);
    }

    if(DATATYPES.BOOLEAN === datatype){
      value = value.checked || false ;
    }
    if(DATATYPES.OBJECT === datatype){
      newResultEntry = {
        ...newResultEntry,
        ...value
      };
    }else{
      newResultEntry[field] = value;
    }

    let newResultsData = [
      ...resultsData,
    ];

    newResultsData[position] = newResultEntry;
    setResultsData(newResultsData);
  }

  const getResultValue = (position, field) => {
    let previousData = (resultsData && resultsData[position]) ? resultsData[position] : {position};
    return previousData[field] === undefined ?  "" : previousData[field];    
  }

  const getDriverValue = (position) => {
    const value  = getResultValue(position, "driverId");
    const drivers = getDriverOptions();
    return drivers.find(d => d.value === value) || "";
  }

  const getSavePromeses = () =>{
    let results = resultsData || [];

    return results
    .filter(r => r !== undefined)
    .map(r => {
        const result = {
          ...r,
          lobby,
          finalTime: r.raceTime,
          penalties:0 
        };
        API.graphql({
        query: createResult,
        variables:{
          "input": cleanUpData(result)
        },
        authMode: 'API_KEY'
      })
    });
  }

  const saveResult = (e) =>{
    e.preventDefault();
    e.stopPropagation();

    const savePromises = getSavePromeses(); 
    Promise.all(savePromises)
    .then((ps)=>{
      showAlert(alertRef, "success", "Informacion del resultado Guardada" );
      setTimeout(()=>{
        history.push("/admin/track-results/"+(trackData && trackData.id ? trackData.id: ""));
      },2000);      
    }).catch(e=>{
      console.log("error result data", e);
      showAlert(alertRef, "danger", "No fue posible guarda informacion del resultado" );
    });
    
  }

  const getDriverOptions = () =>{
    return drivers.map(d =>{return {value: d.driverId, label: d.psnId, teamId: d.teamId || "N/A" }});
  }

  const getResultEntries = (resultRows) =>{
    let rows =[];
    for (let i = 1; i <= resultRows; i++) {
      rows.push(
        (
        <Row key={`position${i}`}>
          <Label sm="2">P{i}</Label>
          <Col sm="3">
            <FormGroup>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              name="singleSelect"
              value={getDriverValue(i)}
              onChange={(option) =>{
                updateResultRowData(i, null, 
                  {
                    "driverId":option.value,
                    "psnId" : option.label,
                    "teamId" : option.teamId
                  }
                  , DATATYPES.OBJECT);
              }
              }
              options={getDriverOptions()}
              placeholder="Piloto"
            />                     
            </FormGroup>
          </Col>
          <Col sm="2">
            <FormGroup>
              <FormattedInput
                className="form-control"
                format={RACE_TIME_FORMAT}
                onFocus={e=> e.target.select()}
                onChange={(formattedValue, raw) => { updateResultRowData(i, "raceTime",formattedValue,DATATYPES.RESULT_TIME)}}
                placeholder={DEFAULT_RESULT_TIME}
                />                        
            </FormGroup>
          </Col>
          <Col sm="1">
            <FormGroup check inline>
                <Label check>
                  <Input 
                    checked={getResultValue(i,"dq")}
                    onChange={(e)=>updateResultRowData(i,"dq",e.target, DATATYPES.BOOLEAN)}
                    type="checkbox"
                  />
                  <span className="form-check-sign" /> DQ;
                </Label>
            </FormGroup>                           
          </Col>
          <Col sm="1">
            <Button 
              className="btn-icon btn-danger"               
              size = "sm"
              onClick={()=> 
                clearResultRowData(i)
              }
              color="danger">
              <i className="nc-icon nc-simple-remove" />
            </Button>
          </Col>    
        </Row>
        )
      );
    }
    return rows;
  }

  const getResultRowsCount = () =>{
    let rows = 1;
    if(champData && champData.maxDriverPerLobby){
      rows = champData.maxDriverPerLobby;
    }
    return rows;
  }

  return (
    <>
      <NotificationAlert ref={alertRef} />
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Registro de Resultado de Sala</CardTitle>
              </CardHeader>
              <CardBody>
                <Form action="#" onSubmit={(e)=> saveResult(e)} className="form-horizontal" method="get">
                  <Row>
                    <Label sm="2">Numero de Sala</Label>                    
                    <Col sm="6">
                      <FormGroup>
                        <Input 
                          type="text"
                          value={lobby} 
                          onChange={e => setLobby(parseInt((e.target.value || "0")))}
                         />                        
                      </FormGroup>                      
                    </Col>
                  </Row>
                  <hr/>
                  <h4> Resultado(s)</h4>
                  {getResultEntries(getResultRowsCount())}
                  <Row>
                    <Col sm={{size:'2', offset:'3'}}>
                      <Button color="success">
                        <span className="btn-label">
                          <i className="nc-icon nc-check-2" />
                        </span>
                          Agregar
                      </Button> 
                    </Col>
                    <Col sm={{size:'4'}}>
                      <Button 
                        className="btn btn-danger" 
                        onClick={()=> 
                          history.push("/admin/track-results/"+(trackData && trackData.id ? trackData.id: ""))
                        }
                        color="danger">
                        <i className="nc-icon nc-simple-remove" />
                          Cancelar
                      </Button>
                    </Col>
                  </Row>                
                </Form>
              </CardBody>
            </Card>
          </Col>          
        </Row>
      </div>
    </>
  );
}

export default AddResults;