
import React, { useState, useEffect } from "react"; 

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { API } from "aws-amplify";
import { listChamps, getTrack} from "graphql/queries";
import { createTrack, updateTrack } from "graphql/mutations";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useHistory, useParams} from "react-router-dom";
import { DATATYPES } from "utils/Constants";
import { getNotDeletedItems } from "utils/Api";
import { cleanUpData } from "utils/Api";

const AddTrack = props => {
  let history = useHistory();
  const { id:trackId } = useParams();

  const [champList, setChampList] = useState([]);
  const [alert, setAlert] = useState(null);
  const [trackData, setTrackData] = useState({});

  useEffect(() => {
    if(trackId){
      loadTrackData(trackId);
    }
    API.graphql( {
      query: listChamps,
      authMode: 'API_KEY'
    }).then((c)=>{
      let champs = []
      if(c && c.data && c.data.listChamps){
        champs = c.data.listChamps.items || [];
      }
      champs = getNotDeletedItems(champs);
      setChampList(champs);
    }).catch((e)=>console.log("fetch champs error", e));
  }, [trackId]);  

  const loadTrackData = (trackId) => {
    API.graphql( {
      query: getTrack,
      variables:{
        id:trackId
      },
      authMode: 'API_KEY'
    }).then((t)=>{
      let track = {}
      if(t && t.data && t.data.getTrack){
        track = t.data.getTrack || {};
      }
      setTrackData(track);
    })
    .catch((e)=>console.log("fetch track data error", e));
  }

  const updateTrackData  = (field, value, datatype) => {
    let newTrackData = {
      ...trackData
    };

    if(DATATYPES.INT === datatype){
      value = value === "" ? "0" : value;
      value = parseInt(value);
    }

    if(DATATYPES.CHAMP === datatype){
      value = getChampData(value);
    }

    if(DATATYPES.BOOLEAN === datatype){
      value = value.checked || false ;
    }

    newTrackData[field] = value;
    setTrackData(newTrackData);
  }

  const getChampData = (id) =>{
    return champList.find((c)=> c.id === id);
  }

  const getFormValue = (field) => {
    return trackData[field] === undefined ? "" : trackData[field];
  }

  const saveTrack = (e) =>{
    e.preventDefault();
    e.stopPropagation();
    
    const operation = (trackData && trackData.id) ? updateTrack : createTrack;

    API.graphql({
      query: operation,
      variables:{
        "input": cleanUpData(trackData)
      },
      authMode: 'API_KEY'
    })
    .then(r =>{
      setAlert(
        (
          <ReactBSAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Datos Guardados"
            onConfirm={() => history.push(`/admin/admin-calendar/${getFormValue("champId")}`) }            
            confirmBtnBsStyle="info"
            btnSize=""
          />          
        )
      );
    }).catch(e =>{
      console.log("save error",e);
      setAlert(
        (
          <ReactBSAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="Datos no guardados"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="cancel"
          btnSize=""
        />
        )
      );
    });

  }

  return (
    <>
      {alert}
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  { trackId ? 'Modificar' : 'Agregar' } Pista
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Form action="#" onSubmit={(e)=>saveTrack(e)} className="form-horizontal" method="get">
                  <Row>
                    <Label sm="2">Numero Pista</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="text" value={getFormValue("order")} onChange={e => updateTrackData('order',e.target.value,DATATYPES.INT)} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Fecha Carrera</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="text" value={getFormValue("date")} onChange={e => updateTrackData('date',e.target.value)} />                        
                      </FormGroup>
                    </Col>
                  </Row>                  
                  <Row>
                    <Label sm="2">Pista</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="text" value={getFormValue("name")} onChange={e => updateTrackData('name',e.target.value)} />                         
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Clima</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="text" value={getFormValue("wheather")} onChange={e => updateTrackData('wheather',e.target.value)} />                       
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Vueltas</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="text" value={getFormValue("laps")} onChange={e => updateTrackData('laps',e.target.value,DATATYPES.INT)} />                     
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Desgaste Gasolina</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="text" value={getFormValue("gas")} onChange={e => updateTrackData('gas',e.target.value)} />                        
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Desgaste Llantas</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="text" value={getFormValue("tire")} onChange={e => updateTrackData('tire',e.target.value)} />                         
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Campeonato </Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="select" value={getFormValue("champId")} onChange={e => updateTrackData('champId', e.target.value)} >
                        <option >Seleccione uno</option>
                          {
                          champList.map((c)=>{
                            return (
                              <option  key={`champ-${c.id}`} value={c.id}>{c.name}</option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Fecha Prequaly</Label>
                    <Col sm="10">
                      <FormGroup check inline>
                          <Label check>
                            <Input 
                              checked={getFormValue("prequaly")}
                              onChange={e => updateTrackData('prequaly',e.target, DATATYPES.BOOLEAN)}
                              type="checkbox"
                            />
                            <span className="form-check-sign" /> &nbsp;
                          </Label>
                      </FormGroup>                                        
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Reclamos Activados</Label>
                    <Col sm="10">
                      <FormGroup check inline>
                          <Label check>
                            <Input 
                              checked={getFormValue("claimAble")}
                              onChange={e => updateTrackData('claimAble',e.target, DATATYPES.BOOLEAN)}
                              type="checkbox"
                            />
                            <span className="form-check-sign" /> &nbsp;
                          </Label>
                      </FormGroup>                                        
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Puntos Visibles</Label>
                    <Col sm="10">
                      <FormGroup check inline>
                          <Label check>
                            <Input 
                              checked={getFormValue("pointsVisible")}
                              onChange={e => updateTrackData('pointsVisible',e.target, DATATYPES.BOOLEAN)}
                              type="checkbox"
                            />
                            <span className="form-check-sign" /> &nbsp;
                          </Label>
                      </FormGroup>                                        
                    </Col>
                  </Row>                
                  <Row>
                    <Col sm={{size:'2', offset:'3'}}>
                      <Button color="success">
                        <span className="btn-label">
                          <i className="nc-icon nc-check-2" />
                        </span>
                        { trackId ? 'Modificar' : 'Agregar' }
                      </Button> 
                    </Col>
                    <Col sm={{size:'4'}}>
                      <Button
                        className="btn btn-danger" 
                        onClick={()=>{history.push(`/admin/admin-calendar/${getFormValue("champId")}`)}} color="danger">
                        <i className="nc-icon nc-simple-remove" />
                        Cancelar
                      </Button>
                    </Col>
                  </Row>                
                </Form>
              </CardBody>
            </Card>
          </Col>          
        </Row>
      </div>
    </>
  );
}

export default AddTrack;