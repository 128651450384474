import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {useParams} from "react-router-dom";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Table,
  Button
} from "reactstrap";
import { API } from "aws-amplify";
import { resultsByTrack } from "graphql/queries";
import { getNotDeletedItems } from "utils/Api";
import { formatRaceTime } from "utils/Constants";
import Select from "react-select";
import { listChamps } from "graphql/queries";
import { tracksByChamp } from "graphql/queries";
import { pointsByTrack } from "graphql/queries";

const TrackLobbyResults = (props) => {
  const { champId } = useParams();
  const [trackDrivers, setTrackDrivers] = useState([]);
  const [currentTrack, setCurrentTrack] = useState({});
  const [allChampList, setAllChampList] = useState([]);  
  const [allTrackList, setAllTrackList] = useState([]);
  const [driverPoints, setDriverPoints] = useState([]);  
  let history = useHistory();  

  useEffect(() => {
    fetchAllChampList();

    if(champId){
      fetchDriverList();
      fetchAllTracks();
    }
    if(currentTrack){
      fetchDriverPoints();
    }

  }, [champId, currentTrack]);

  const fetchAllChampList = () =>{
    API.graphql( {
      query: listChamps,
      authMode: 'API_KEY'
    }).then((c)=>{
      let champs = []
      if(c && c.data && c.data.listChamps){
        champs = c.data.listChamps.items || [];
      }
      champs = getNotDeletedItems(champs);
      setAllChampList(champs);
    }).catch((e)=>console.log("fetch champs error", e));
  }

  const fetchAllTracks = () =>{
    API.graphql( {
      query: tracksByChamp,
      variables:{
        "champId":champId,
        filter:{
          "prequaly":{
            "ne":true
          }
        }
      },
      authMode: 'API_KEY'
    }).then((t)=>{
      let tracks = [];
      if(t && t.data && t.data.tracksByChamp){
        tracks = t.data.tracksByChamp.items || [];
        tracks =  getNotDeletedItems(tracks);
      }
      setAllTrackList(tracks);
    }).catch((e)=>console.log("fetch track data error", e));
  }

  const fetchDriverPoints = () =>{
    API.graphql( {
      query: pointsByTrack,
      variables:{
        "trackId":currentTrack,
        "limit": 1500,
      },
      authMode: 'API_KEY'
    }).then((t)=>{
      let points = [];
      if(t && t.data && t.data.pointsByTrack){
        points = t.data.pointsByTrack.items || [];
        points = getNotDeletedItems(points);
      }
      setDriverPoints(points);
    }).catch((e)=>console.log("fetch track driver result error", e));
  }

  const fetchDriverList = () =>{
    API.graphql( {
      query: resultsByTrack,
      variables:{
        "trackId":currentTrack,
        "limit": 1500,
      },
      authMode: 'API_KEY'
    }).then((t)=>{
      let drivers = [];
      if(t && t.data && t.data.resultsByTrack){
        drivers = t.data.resultsByTrack.items || [];
        drivers = getNotDeletedItems(drivers);
      }
      setTrackDrivers(drivers);
    }).catch((e)=>console.log("fetch track driver result error", e));
  }

  const getDriverPoints = (driverId) =>{
    const currentDriverPoints = driverPoints.find(dp => dp.driverId === driverId );
    return (currentDriverPoints && currentDriverPoints.points) ? currentDriverPoints.points : 0;
  }

  function compareDrivers( a, b, field ) {
    let aValue = a[field];
    let bValue = b[field];
    if(field==="finalTime"){
      aValue = a.dq ? Number.MAX_SAFE_INTEGER : (aValue || Number.MAX_SAFE_INTEGER);
      bValue = b.dq ? Number.MAX_SAFE_INTEGER : (bValue || Number.MAX_SAFE_INTEGER);
    }
    if ( aValue < bValue ){
      return -1;
    }
    if ( aValue > bValue ){
      return 1;
    }
    return 0;
  }

  const getRaceTimeLabel = (result, field) => {
    return result.dq ? "DQ" : formatRaceTime(result[field]);
  }
  
  const renderDriverPerLobby = () =>{
    return trackDrivers 
    .sort((a, b)=> compareDrivers(a,b,"lobby") || compareDrivers(a,b,"finalTime"))
    .reduce((all,one,i) => {
      all[one.lobby] = [].concat((all[one.lobby]||[]),one); 
      return all
    }, [])
    .map((ld,i)=>{
      return (
        <Col key={`lobby-${i}`}md={{size:"10", offset:"1"}}>
          <Card>
            <CardHeader>
              <h5 className="title">Sala {i}</h5>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="12">
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-center">Posicion</th>
                        <th className="text-center">Piloto</th>
                        <th className="text-center">T. Carrera</th>
                        <th className="text-center">Penalties</th>
                        <th className="text-center">T. Final</th>
                        <th className="text-center">Puntos</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ld.map((e, i)=>{
                        return (        
                          <tr key={`track-${e.id}`}>
                            <td className="text-center">{i+1}</td>
                            <td className="text-center">{e.psnId}</td>
                            <td className="text-center">{getRaceTimeLabel(e,"raceTime")}</td>                          
                            <td className="text-center">{e.penalties ? formatRaceTime(e.penalties) : ""}</td>                          
                            <td className="text-center">{getRaceTimeLabel(e,"finalTime")}</td>                 
                            <td className="text-center">{getDriverPoints(e.driverId)}</td>                          
                          </tr>                         
                        )
                      })}
                    </tbody>
                  </Table>                   
                </Col>                    
              </Row>    
            </CardBody>
          </Card>  
        </Col>   
        );
    });    
  }

  const getSelectTrack = () => {
    const trackOptions = getTrackOptions();
    return trackOptions.find(d => d.value === currentTrack) || "";
  }

  function compareTrack( a, b ) {
    if ( a.order < b.order ){
      return -1;
    }
    if ( a.order > b.order ){
      return 1;
    }
    return 0;
  }

  const getTrackOptions = () =>{
    return allTrackList
    .sort((a,b)=>compareTrack(a,b))
    .map(c =>{
      return {value: c.id, label: `${c.order} - ${c.name}`};
    });
  }

  return (
    <>      
      <div className="content">
        <Card>
          <CardHeader>
              <h5 className="title">Resultados por Fecha</h5>
          </CardHeader>
          <CardBody>
            <p>
              Seleccione una fecha para cargar los resultados oficiales
            </p>
            <Row>
              <Col md="1"></Col>              
              <Col md="3">
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="singleSelect"
                  value={getSelectTrack()}
                  onChange={(option) =>{
                    setCurrentTrack(option.value);
                    }
                  }
                  options={getTrackOptions()}
                  placeholder="Fecha"
                />  
              </Col>
            </Row>
          </CardBody>
        </Card>
        <hr/>
        <Row>
          { trackDrivers.length> 0 && (
            <Col md={{size:"10", offset:"1"}}>
              <Button
                  onClick ={()=> history.push(`/admin/track-lobbies/${currentTrack}`)}
                  className="btn btn-info pull-right  "
                  id="tooltip8694850302"
                  size="sm"
                  type="button"
                >
                  Ver Orden de Salas Siguiente Fecha
              </Button> 
            </Col>
          )}
          {renderDriverPerLobby()}        
        </Row>
      </div>
    </>
  );
  
}

export default TrackLobbyResults;
