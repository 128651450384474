
import React, { useEffect, useLayoutEffect, useState } from "react";
import { champJoinsByDriver, pointsByChamp, champJoinsByChampAndType, tracksByChamp, listChamps, listTeams } from "graphql/queries";
import { useParams } from "react-router";
import { IMAGE_CLOUDFRONT } from "utils/Aws";

import {
  Table,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import { getUserData } from "utils/Session";
import { API } from "aws-amplify";
import { getNotDeletedItems } from "utils/Api";
import Select from "react-select";

const Teams = props => {
  const { champId } = useParams();
  const [driverResultList, setDriverResultList] = useState([]);
  const [champTeamList, setTeamList] = useState([]);
  const [teamChampStatus, setTeamChampStatus] = useState([]);
  const [allChampList, setAllChampList] = useState([]);
  const [champTracks, setChampTracks] = useState([]);
  const [userData, setUserData] = useState({});
  const [initialLoad, setInitialLoad] = useState(false);
  const [teamList, setAllTeamList] = useState([]);

  useEffect(() => {
    createTeamResult()
  }, [teamChampStatus.length, driverResultList.length, champTeamList.length, champTracks.length]);

  useLayoutEffect(() => {
    if (!userData.id) {
      fetchUserData();
    }
    if (champId) {
      fetchChampTracks(champId);
      fetchChampTeams(champId);
      fetchDriversResult(champId);
    }
    fetchAllChampList();
    fetchTeamList();
  }, [initialLoad, champId]);

  const fetchTeamList = () => {
    API.graphql({
      query: listTeams,
      authMode: 'API_KEY'
    }).then((d) => {
      let teams = []
      if (d && d.data && d.data.listTeams) {
        teams = d.data.listTeams.items || [];
      }
      teams = getNotDeletedItems(teams);

      setAllTeamList(teams.sort((a, b) => compareTeams(a, b, "name")));
    }).catch((e) => console.log("fetch teams error", e));
  }

  const fetchAllChampList = () => {
    API.graphql({
      query: listChamps,
      authMode: 'API_KEY'
    }).then((c) => {
      let champs = []
      if (c && c.data && c.data.listChamps) {
        champs = c.data.listChamps.items || [];
      }
      champs = getNotDeletedItems(champs);
      setAllChampList(champs);
    }).catch((e) => console.log("fetch champs error", e));
  }

  const fetchUserData = () => {
    getUserData()
      .then(data => {
        if (data.driverId) {
          fetchChampList(data.driverId);
          setUserData(data);
        }
      })
      .catch((e) => {
        console.log("Error getting user data", e);
      });
  }

  function compareOrder(a, b) {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  }

  function compareTeams(a, b, field) {
    let nameA = a[field] || "";
    let nameB = b[field] || "";

    if (nameA.toLowerCase() < nameB.toLowerCase()) {
      return -1;
    }
    if (nameA.toLowerCase() > nameB.toLowerCase()) {
      return 1;
    }
    return 0;
  }

  const compareTeamsPoints = (a, b) => {
    let nameA = a["totalPoints"] || 0;
    let nameB = b["totalPoints"] || 0;

    if (nameA > nameB) {
      return -1;
    }
    if (nameA < nameB) {
      return 1;
    }
    return 0;
  }

  const fetchChampTracks = (champId) => {
    API.graphql({
      query: tracksByChamp,
      variables: {
        "champId": champId,
        filter: {
          "prequaly": {
            "ne": true
          }
        }
      },
      authMode: 'API_KEY'
    })
      .then((t) => {
        let tracks = []
        if (t && t.data && t.data.tracksByChamp) {
          tracks = t.data.tracksByChamp.items || [];
        }
        tracks = getNotDeletedItems(tracks);
        setChampTracks(tracks.sort((a, b) => compareOrder(a, b)));
      }).catch((e) => console.log("fetch champs error", e));
  }

  const fetchChampTeams = (champId) => {
    API.graphql({
      query: champJoinsByChampAndType,
      variables: {
        "champId": champId,
        "limit": 1500,
        "type": {
          "eq": "team"
        },
        filter: {
          "status": {
            "eq": "accepted"
          }
        }
      },
      authMode: 'API_KEY'
    })
      .then((d) => {
        let teams = []
        if (d && d.data && d.data.champJoinsByChampAndType) {
          teams = d.data.champJoinsByChampAndType.items || [];
        }
        teams = getNotDeletedItems(teams);
        setTeamList(teams);
      }).catch((e) => console.log("fetch champs error", e));
  }

  const fetchDriversResult = (champId) => {
    API.graphql({
      query: pointsByChamp,
      variables: {
        "champId": champId,
        "limit": 1500,
      },
      authMode: 'API_KEY'
    })
      .then((p) => {
        let points = []
        if (p && p.data && p.data.pointsByChamp) {
          points = p.data.pointsByChamp.items || [];
        }
        points = getNotDeletedItems(points);
        setDriverResultList(points);
        setInitialLoad(true);
      }).catch((e) => console.log("fetch champs error", e));
  }

  const fetchChampList = (driverId) => {
    API.graphql({
      query: champJoinsByDriver,
      variables: {
        "driverId": driverId,
        filter: {
          "status": {
            "eq": "accepted"
          }
        }
      },
      authMode: 'API_KEY'
    })
      .then((c) => {
        let champs = []
        if (c && c.data && c.data.champJoinsByDriver) {
          champs = c.data.champJoinsByDriver.items || [];
        }
        champs = getNotDeletedItems(champs);
      }).catch((e) => console.log("fetch champs error", e));
  }

  const getTrackPoint = (track, trackPoints) => {
    if (track.pointsVisible)
      return (trackPoints && trackPoints.points) ? trackPoints.points : 0;
    return 0;
  }
  const getTeamTotalPoint = (driverResults) => {
    const tracksWithPointsVisible = champTracks.filter(ct => ct.pointsVisible);
    return driverResults.reduce((total, dr) => {
      if (tracksWithPointsVisible.find(t => t.id === dr.trackId)) {
        return total + (dr.points || 0);
      } else return total;
    }, 0);
  }

  const createTeamResult = () => {
    const champStatus = champTeamList.map(d => {
      const driverResults = driverResultList.filter(dr => dr.teamId === d.teamId && dr.champId === champId);
      const totalPoints = getTeamTotalPoint(driverResults);
      const trackResult = champTracks.map(ct => {
        const trackPoints = driverResults.find(dr => dr.trackId === ct.id);
        const driverNames = trackPoints ? trackPoints.driversInclude || "" : "";
        const points = getTrackPoint(ct, trackPoints);
        return {
          "trackId": ct.id,
          "points": points,
          "includeDriver": driverNames
        }
      });
      return {
        "teamId": d.teamId,
        "teamName": d.teamName,
        "totalPoints": totalPoints,
        "trackResult": trackResult
      }
    }).sort((a, b) => compareTeamsPoints(a, b) || compareTeams(a, b, "teamName"));
    setTeamChampStatus(champStatus);
    setInitialLoad(true);
  }

  const getTeamLogo = (teamId) => {
    let logo = "";
    if (teamList && teamList.length > 0) {
      const teamData = teamList.find(t => t.id == teamId);
      if (teamData) {
        logo = teamData && teamData.teamImage ? teamData.teamImage : "";
      }
    }
    return logo ? `${IMAGE_CLOUDFRONT}/${logo}` : logo;
  }

  const renderTeamsPodium = () => {
    if (!teamChampStatus || teamChampStatus.length === 0) {
      return null;
    }

    return teamChampStatus.slice(0, 3).map((d, i) => {
      return d.totalPoints != 0 ? (
        <Col sm={{ size: '4', order: (i == 1 ? "first" : "") }} className={`podium-p${i + 1}`} key={`driver-${i}`}>
          <div className="podium_position">P{i + 1}</div>
          <Col className="podium_image" sm={{ size: '10', offset: '1' }}>
            {getTeamLogo(d.teamId) && (
              <img src={getTeamLogo(d.teamId)} />
            )}
          </Col>
          <h3 className="text-center"><b>{d.totalPoints}</b></h3>
          <h5 className="text-center"><b>{d.teamName}</b></h5>
        </Col>
      ) : null;
    }
    );
  }

  const renderTeamsData = () => {
    if (!teamChampStatus || teamChampStatus.length === 0) {
      return null;
    }

    return teamChampStatus.map((d, i) => {
      return (
        <tr className={(userData && userData.teamId === d.teamId ? "currentUser" : "")} key={`driver-${i}`}>
          <td>P{i + 1}</td>
          <td>{d.teamName}</td>
          {
            d.trackResult.map(t => (
              <td key={`track-header-${d.teamId}-${t.trackId}`}>
                <div id={`id-track-header-${d.teamId}-${t.trackId}`}
                  className={`point-table__group-register ${t.includeDriver && "add-pointer-cursor"}`}>
                  {t.points}
                </div>
                {t.includeDriver && (<UncontrolledTooltip
                  delay={2}
                  placement="left"
                  target={`id-track-header-${d.teamId}-${t.trackId}`}
                >
                  {t.includeDriver.split(",").map(d => (<>{d}<br /></>))}
                </UncontrolledTooltip>
                )}
              </td>
            )
            )
          }
          <td className="text-right">{d.totalPoints}</td>
        </tr>
      )
    }
    );
  }

  const renderTrackHeaders = () => {
    return champTracks.map(t => (
      <th key={`track-header-${t.order}`}>Fecha {t.order}</th>
    )
    );
  }

  const getSelectChamp = () => {
    const champOption = getChampOptions();
    return champOption.find(d => d.value === champId) || "";
  }

  const getChampOptions = () => {
    return allChampList.map(c => {
      return { value: c.id, label: c.name };
    });
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="8">
                    <CardTitle tag="h4">Campeonato de Escuderias</CardTitle>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  {renderTeamsPodium()}
                </Row>
                <Table responsive>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Nombre Equipo</th>
                      {renderTrackHeaders()}
                      <th className="text-right">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {initialLoad && renderTeamsData()}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Teams;