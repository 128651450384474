
import React, { useState, useEffect } from "react";

import { API } from "aws-amplify";
import { listChamps } from "graphql/queries";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import { getNotDeletedItems } from "utils/Api";
import { IMAGE_CLOUDFRONT } from "utils/Aws";
import { deleteChamp } from "graphql/mutations";

const Champs = props => {
  const [champList, setChampList] = useState([]);
  const [alert, setAlert] = useState(null);
  let history = useHistory();

  useEffect(() => {
    fetchChampList();
  }, []);

  const fetchChampList = () => {
    API.graphql({
      query: listChamps,
      authMode: 'API_KEY'
    }).then((c) => {
      let champs = []
      if (c && c.data && c.data.listChamps) {
        champs = c.data.listChamps.items || [];
      }
      champs = getNotDeletedItems(champs);
      setChampList(champs);
    }).catch((e) => console.log("fetch champs error", e));
  }

  const deleteElement = (id, version) => {
    API.graphql({
      query: deleteChamp,
      variables: {
        input: {
          "id": id,
          "_version": version
        }
      },
      authMode: 'API_KEY'
    })
      .then(r => {
        setAlert(
          (
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Eliminado Exitosamente"
              onConfirm={() => {
                setAlert(null);
                fetchChampList();
              }
              }
              confirmBtnBsStyle="info"
              btnSize=""
            />
          )
        );
      }).catch(e => {
        console.log("save error", e);
        setAlert(
          (
            <ReactBSAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Datos no Eliminados"
              onConfirm={() => setAlert(null)}
              onCancel={() => setAlert(null)}
              confirmBtnBsStyle="cancel"
              btnSize=""
            />
          )
        );
      });

  }

  const getChampImage = (champID) => {
    return `${IMAGE_CLOUDFRONT}/champs/image/${champID}.jpg`;
  }

  const renderChampsList = () => {
    return champList.sort((a, b) => a.order < b.order).map((e) => {
      const champImage = getChampImage(e.id);
      return (
        <tr key={`champ-${e.id}`}>
          <td className="text-center">{e.name}
          </td>
          <td className="text-center">
            {e.champImage && <img className="admin-champ-image" src={champImage} />}
          </td>
          <td className="text-right">
            <Button
              className="btn-icon"
              color="normal"
              id={`tooltip36sgf20032323-${e.id}`}
              size="sm"
              type="button"
              onClick={() => history.push(`/admin/admin-calendar/${e.id}`)}
            >
              <i className="nc-icon nc-calendar-60" />
            </Button>{" "}
            <UncontrolledTooltip
              delay={2}
              target={`tooltip36sgf20032323-${e.id}`}
            >
              Calendario
          </UncontrolledTooltip>
            <Button
              className="btn-icon"
              color="normal"
              id={`tooltip36622000032323-${e.id}`}
              size="sm"
              type="button"
              onClick={() => history.push(`/admin/point-system/${e.id}`)}
            >
              <i className="nc-icon nc-money-coins" />
            </Button>{" "}
            <UncontrolledTooltip
              delay={2}
              target={`tooltip36622000032323-${e.id}`}
            >
              Systema de puntos
          </UncontrolledTooltip>
            <Button
              className="btn-icon"
              color="normal"
              id={`tooltip3662423232323-${e.id}`}
              size="sm"
              type="button"
              onClick={() => history.push(`/admin/champ-members/${e.id}`)}
            >
              <i className="fa fa-list" />
            </Button>{" "}
            <UncontrolledTooltip
              delay={2}
              target={`tooltip3662423232323-${e.id}`}
            >
              Miembros de Campeonato
            </UncontrolledTooltip>
            <Button
              className="btn-icon"
              color="success"
              id={`tooltip366246651-${e.id}`}
              size="sm"
              type="button"
              onClick={() => history.push(`/admin/edit-champ/${e.id}`)}
            >
              <i className="fa fa-edit" />
            </Button>{" "}
            <UncontrolledTooltip
              delay={2}
              target={`tooltip366246651-${e.id}`}
            >
              Editar
            </UncontrolledTooltip>
            <Button
              className="btn-icon"
              color="danger"
              id={`tooltip884055770-${e.id}`}
              size="sm"
              type="button"
              onClick={() => deleteElement(e.id, e._version)}
            >
              <i className="fa fa-times" />
            </Button>{" "}
            <UncontrolledTooltip
              delay={2}
              target={`tooltip884055770-${e.id}`}
            >
              Eliminar
            </UncontrolledTooltip>
          </td>
        </tr>
      );
    })
  }

  return (
    <>
      {alert}
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Campeonatos
                    <Button
                    onClick={() => history.push(`/admin/add-champ`)}
                    className="btn btn-success pull-right header-link"
                    color="success"
                    id="tooltip8694850302"
                    size="sm"
                    type="button"
                  >
                    <i className="fa fa-plus" /> Agregar
                    </Button>{" "}
                  <UncontrolledTooltip
                    delay={2}
                    target="tooltip8694850302"
                  >
                    Agregar
                    </UncontrolledTooltip>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th className="text-center">Nombre</th>
                      <th className="text-center">Imagen</th>
                      <th className="text-right">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderChampsList()}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Champs;