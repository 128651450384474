import React from "react";
import { useHistory } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Col,
  Row,
} from "reactstrap";

const ValidateAccount = props => {
  const history = useHistory();

  return (
    <>    
      <div className="login-page">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="4" md="6">
                <Card className="card-login">
                  <CardHeader>
                    <CardHeader>
                      <h3 className="header text-center">Cuenta Creada</h3>
                    </CardHeader>
                  </CardHeader>
                  <CardBody>
                    <p>
                      <b>
                        Su Cuenta fue creada, hemos enviado un correo electronico con un enlace para
                        activar tu cuenta
                      </b>
                    </p>                 
                    <Button 
                    color="facebook" 
                    block 
                    className="btn-round mb-3" 
                    onClick={() => history.push("/auth/login")}
                    >                        
                        Iniciar Sesion
                    </Button>
                  </CardBody>
                </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{                      
          backgroundImage: `url(${require("assets/img/bg/gt-champs/cars2.jpg")})`,          
          }}
        />
      </div>
      </>
    );
}

export default ValidateAccount;
