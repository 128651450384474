
import React, { useState, useEffect } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap"; 
import { API } from "aws-amplify";
import { listTeams } from "graphql/queries";
import { deleteTeam } from "graphql/mutations";
import { getNotDeletedItems } from "utils/Api";

const Teams = props => {

  const [teamList, setTeamList] = useState([]);
  const [alert, setAlert] = useState(null);
  let history = useHistory();

  useEffect(() => {
    fetchTeamList();
  }, []);

  const fetchTeamList = () =>{
    API.graphql( {
      query: listTeams,
      authMode: 'API_KEY'
    }).then((t)=>{
      let teams = []
      if(t && t.data && t.data.listTeams){
        teams = t.data.listTeams.items || [];
      }
      teams = getNotDeletedItems(teams);
      setTeamList(teams);
    }).catch((e)=>console.log("fetch teams error", e));
  }

  const deleteElement = (id, version) => {
    API.graphql({
      query: deleteTeam,
      variables:{
       input: {
         "id": id,
         "_version":version
       } 
      },
      authMode: 'API_KEY'
    })
    .then(r =>{
        setAlert(
          (
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Eliminado Exitosamente"
              onConfirm={() => {
                setAlert(null);
                fetchTeamList();
                }
              }            
              confirmBtnBsStyle="info"
              btnSize=""
            />          
          )
        );
    }).catch(e =>{
      console.log("save error",e);
      setAlert(
        (
          <ReactBSAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="Datos no Eliminados"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="cancel"
          btnSize=""
        />
        )
      );
    });
    
  }

  const renderTeamsList = () =>{
    return teamList.map((e)=>{
      console.log("track data",e);
      return (
        <tr key={`team-${e.id}`}>        
          <td className="text-center">{e.name}</td>          
          <td className="text-center">{e.country}</td>          
          <td className="text-center">{e.lider}</td>
          <td className="text-center">{e.instagram}</td>          
          <td className="text-right">
            <Button
              className="btn-icon"
              color="success"
              id="tooltip366246651"
              size="sm"
              type="button"
              onClick={() => history.push(`/admin/edit-team/${e.id}`)}
            >
              <i className="fa fa-edit" />
            </Button>{" "}
            <UncontrolledTooltip
              delay={2}
              target="tooltip366246651"
            >
              Editar
            </UncontrolledTooltip>
            <Button
              className="btn-icon"
              color="danger"
              id="tooltip884055770"
              size="sm"
              type="button"
              onClick ={() => deleteElement(e.id, e._version)}
            >
              <i className="fa fa-times" />
            </Button>{" "}
            <UncontrolledTooltip
              delay={2}
              target="tooltip884055770"
            >
              Eliminar
            </UncontrolledTooltip>                       
          </td>
        </tr> 
      );
    })    
  }

  return (
    <>
      {alert}
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Escuderias
                    <a
                      href="/admin/add-team"
                      className="btn btn-success pull-right"
                      color="success"
                      id="tooltip8694850302"
                      size="sm"
                      type="button"
                    >
                      <i className="fa fa-plus" /> Agregar
                    </a>{" "}
                    <UncontrolledTooltip
                      delay={2}
                      target="tooltip8694850302"
                    >
                      Agregar
                    </UncontrolledTooltip>       
                  </CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th className="text-center">Nombre Escuderia</th>
                      <th className="text-center">Pais</th>
                      <th className="text-center">Lider</th>
                      <th className="text-center">Instagram</th>
                      <th className="text-right">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderTeamsList()}                                                   
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Teams;