import React from "react";

export const showAlert = (alertRef, type, text)=>{
    var options = {};
    options = {
      place: "bc",
      message: (
        <div>
          <div>
            {text}
          </div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 4,
    };
    alertRef.current.notificationAlert(options);

  }