
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import { API } from "aws-amplify";
import { IMAGE_CLOUDFRONT } from "utils/Aws";
import { listChamps, getDriver, champJoinsByDriver, champJoinsByTeam } from "graphql/queries";
import { showAlert } from 'utils/Alert';

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  FormGroup,
  Input,
  Modal,
  Label,
  Button
} from "reactstrap";
import { getUserData } from "utils/Session";
import { getNotDeletedItems } from "utils/Api";
import { DATATYPES } from "utils/Constants";
import { createChampJoin } from "graphql/mutations";
import { listTeams } from "graphql/queries";
const alertRef = React.createRef();

const JoinChamp = props => {
  const [champList, setChampList] = useState([]);
  const [driverChamps, setDriverChamps] = useState([]);
  const [teamChamps, setTeamChamps] = useState([]);
  const [userData, setUserData] = useState({});
  const [joinData, setJoinData] = useState({});
  const [driverData, setDriverData] = useState({});
  const [teamData, setTeamData] = useState({});
  const [loadingData, setLoadingData] = useState(true);
  const [openJoinModal, setOpenJoinModal] = useState(false);
  const [selectedChamp, setSelectedChamp] = useState();
  const PENDIENTE_LABEL = "Pendiente";
  const ACEPTED_LABEL = "Aceptado";
  const NO_REQUEST_LABEL = "";
  const history = useHistory();

  useEffect(() => {
    fetchChampList();
    getUserData().then(ud => {
      setUserData(ud);
      getDriverData(ud.driverId);
      getTeamData(ud.username);
      fetchDriverChampJoins(ud.driverId);
    });
  }, []);

  const getTeamData = (username) => {
    API.graphql({
      query: listTeams,
      variables: {
        filter: {
          leadId: {
            "eq": username
          }
        }
      },
      authMode: 'API_KEY'
    }).then((t) => {
      if (t && t.data && t.data.listTeams && t.data.listTeams.items) {
        const teams = getNotDeletedItems(t.data.listTeams.items);
        if (teams.length > 0) {
          setTeamData(teams[0]);
          if (teams[0].id) {
            fetchTeamChampJoins(teams[0].id);
          }
        }
      }
      setLoadingData(false);
    })
      .catch((e) => {
        console.log("Error getting team data", e);
        setLoadingData(false);
      });
  }

  const fetchDriverChampJoins = (driverId) => {
    API.graphql({
      query: champJoinsByDriver,
      variables: {
        "limit": 500,
        "driverId": driverId
      },
      authMode: 'API_KEY'
    })
      .then((cd) => {
        let driverChamps = []
        if (cd && cd.data && cd.data.champJoinsByDriver) {
          driverChamps = cd.data.champJoinsByDriver.items || [];
        }
        driverChamps = getNotDeletedItems(driverChamps);
        setDriverChamps(driverChamps);
      }).catch((e) => console.log("fetch champs error", e));
  }

  const getChampImage = (champID) => {
    return `${IMAGE_CLOUDFRONT}/champs/image/${champID}.jpg`;
  }

  const fetchTeamChampJoins = (teamId) => {
    API.graphql({
      query: champJoinsByTeam,
      variables: {
        "limit": 500,
        "teamId": teamId
      },
      authMode: 'API_KEY'
    })
      .then((cd) => {
        let teamChamps = []
        if (cd && cd.data && cd.data.champJoinsByTeam) {
          teamChamps = cd.data.champJoinsByTeam.items || [];
        }
        teamChamps = getNotDeletedItems(teamChamps);
        setTeamChamps(teamChamps);
      }).catch((e) => console.log("fetch team champs error", e));
  }

  const getDriverData = (driverId) => {
    API.graphql({
      query: getDriver,
      variables: {
        id: driverId
      },
      authMode: 'API_KEY'
    }).then(d => {
      let driver = {};
      if (d && d.data && d.data.getDriver) {
        driver = d.data.getDriver || {};
      }
      setDriverData(driver);
    });
  }

  const fetchChampList = () => {
    API.graphql({
      query: listChamps,
      variables: {
        filter: {
          "status": {
            "eq": "open"
          }
        }
      },
      authMode: 'API_KEY'
    })
      .then((c) => {
        let champs = []
        if (c && c.data && c.data.listChamps) {
          champs = c.data.listChamps.items || [];
        }
        champs = getNotDeletedItems(champs);
        setChampList(champs);
      }).catch((e) => console.log("fetch champs error", e));
  }

  const driverAlreadyJoined = (champId) => {
    return driverChamps.filter(dc => dc.champId === champId && (dc.status === "accepted" || dc.status === "pending")).length > 0;
  }

  const teamAlreadyJoined = (champId) => {
    if (!teamData.id) {
      return false;
    }

    return teamChamps.filter(dc => dc.champId === champId && (dc.status === "accepted" || dc.status === "pending")).length > 0;
  }

  const requestJoin = (champIdFromList) => {
    if (!driverData || !driverData.psnId) {
      showAlert(alertRef, "danger", "Antes de solicitar unirse al campeonato actualice su PSn Id en su perfil");
      return;
    }

    if (driverAlreadyJoined(selectedChamp) || teamAlreadyJoined(selectedChamp)) {
      showAlert(alertRef, "warning", "Usted ya envio una solicitud a este campeonato");
      return;
    }

    const { joinTeam, joinDriver } = joinData;

    if (teamData.id && (!joinTeam && !joinDriver)) {
      showAlert(alertRef, "warning", "Por favor seleccione una opcion para unirse al campeonato (Piloto y/o Escuderia)");
      return;
    }

    const champID = selectedChamp || champIdFromList;

    let promises = [];
    if (joinTeam) {
      promises.push(
        API.graphql({
          query: createChampJoin,
          variables: {
            "input": {
              champId: champID,
              teamId: teamData.id,
              status: "pending",
              type: "team",
              teamName: teamData.name,
            }
          },
          authMode: 'API_KEY'
        })
      )
    }
    if (joinDriver || champIdFromList) {
      promises.push(
        API.graphql({
          query: createChampJoin,
          variables: {
            "input": {
              champId: selectedChamp || champIdFromList,
              driverId: userData.driverId,
              status: "pending",
              type: "driver",
              name: driverData.name,
              psnId: driverData.psnId
            }
          },
          authMode: 'API_KEY'
        })
      )
    }

    Promise.all(promises)
      .then((j) => {
        if (joinTeam || joinDriver) {
          toggleJoinModal();
        }
        showAlert(alertRef, "success", "Se ha enviado la solicitud de ingreso al campeonato correctamente");
        getTeamData(userData.username);
        fetchDriverChampJoins(userData.driverId);
      }).catch(e => {
        console.log("Error requesting team join", e);
        showAlert(alertRef, "danger", "No fue posible enviar la solicitud de ingreso al campeonato correctamente");
      })
  }

  const renderChampsList = () => {
    if (champList.length === 0) {
      return (
        <Col md="4">
          <Card>
            <CardBody>
              <b>No hay campeonatos Disponibles</b>

            </CardBody>
          </Card>
        </Col>
      )
    }
    return champList.sort((a, b) => a.order < b.order).map((e) => {
      const status = getRequestStatus(e.id);
      
      return (
        <Col key={`champ-${e.id}`} md="4">
          <Card>
            {e.champImage && <img src={getChampImage(e.id)}></img>}
            <CardBody>
              <h5>{e.name}</h5>
              <p>Inicio {e.startDate}</p>
              <p>{status && "Estado Solicitud: "} <span className={`request-status-${status}`}>{status}</span></p>
            </CardBody>
            <CardFooter>
              {!loadingData && (
                <>
                  {getDriverChampActions(e, status)}
                </>
              )}
            </CardFooter>
          </Card>
        </Col>
      );
    })
  }

  const getRequestStatus = (champId) => {
    const driverPending = driverChamps.filter(dc => dc.champId === champId && dc.status === "pending").length > 0;
    const teamPending = teamChamps.filter(dc => dc.champId === champId && dc.status === "pending").length > 0;
    const driverAccepted = driverChamps.filter(dc => dc.champId === champId && dc.status === "accepted").length > 0;
    const teamAccepted = teamChamps.filter(dc => dc.champId === champId && dc.status === "accepted").length > 0;

    if (driverPending || teamPending) {
      return PENDIENTE_LABEL;
    }
    if (teamAccepted || driverAccepted) {
      return ACEPTED_LABEL;
    }
    return NO_REQUEST_LABEL
  }

  const getDriverChampActions = (champ, status) => {
    return (
      <>
        {status === NO_REQUEST_LABEL && (
          <>
            <Button
              className="btn"
              color="success"
              id={`champ-${champ.id}`}
              size="sm"
              type="button"
              onClick={() => {
                if (teamData.id) {
                  openJoinChampModal(champ.id)
                } else {
                  requestJoin(champ.id);
                }
              }
              }
            >
              <i className="fa fa-sign-in" /> Unirse
          </Button>{" "}
            <UncontrolledTooltip
              delay={2}
              target={`champ-${champ.id}`}
            >
              Unirse
          </UncontrolledTooltip>
          </>
        )}
        {status === ACEPTED_LABEL && (
          <>
            <Button
              className="btn"
              color="success"
              id={`champ-${champ.id}`}
              size="md"
              type="button"
              onClick={() => history.push(`/admin/champ/dashboard/${champ.id}`)
              }
            >
              <i className="nc-icon nc-alert-circle-i" /> Ver Informacion Campeonato
          </Button>{" "}
          </>
        )}
      </>
    );
  }


  const openJoinChampModal = (champId) => {
    setSelectedChamp(champId);
    toggleJoinModal();
  }

  const toggleJoinModal = () => {
    setOpenJoinModal(!openJoinModal);
  }

  const updateJoinData = (field, value, datatype) => {
    let newjoinData = {
      ...joinData
    };

    if (DATATYPES.BOOLEAN === datatype) {
      value = value.checked || false;
    }

    newjoinData[field] = value;
    setJoinData(newjoinData);
  }

  const getFormValue = (field) => {
    return joinData[field] === undefined ? "" : joinData[field];
  }

  return (
    <>
      <div className="higest-z-index">
        <NotificationAlert ref={alertRef} />
      </div>
      <Modal
        isOpen={openJoinModal}
        toggle={toggleJoinModal}
      >
        <div className="modal-header justify-content-center">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleJoinModal()}
          >
            <i className="nc-icon nc-simple-remove" />
          </button>
          <h4 className="title title-up">Unirse a Campeonato</h4>
        </div>
        <div className="modal-body">
          <p>
            Por favor seleccione si va a unirse al campeonato como piloto y/o como escuderia.
            Posteriormente el administrador del campeonato debe aceptar tu solicitud
          </p>
          <Row>
            <Col sm="1" xs="2">
              <FormGroup check inline>
                <Label check>
                  <Input
                    checked={getFormValue("joinDriver")}
                    onChange={e => updateJoinData('joinDriver', e.target, DATATYPES.BOOLEAN)}
                    type="checkbox"
                  />
                  <span className="form-check-sign" /> &nbsp;
                  </Label>
              </FormGroup>
            </Col>
            <Label sm="6" xs="10">Ingresar Como piloto</Label>
          </Row>
          <Row>
            <Col sm="1" xs="2">
              <FormGroup check inline>
                <Label check>
                  <Input
                    checked={getFormValue("joinTeam")}
                    onChange={e => updateJoinData('joinTeam', e.target, DATATYPES.BOOLEAN)}
                    type="checkbox"
                  />
                  <span className="form-check-sign" /> &nbsp;
                  </Label>
              </FormGroup>
            </Col>
            <Label sm="6" xs="10">Ingresar Como Escuderia</Label>
          </Row>
        </div>
        <div className="modal-footer">
          <div className="left-side">
            <Button
              className="btn-link"
              color="success"
              data-dismiss="modal"
              type="button"
              onClick={() => requestJoin()}
            >
              Enviar Solicitud
            </Button>
          </div>
          <div className="divider" />
          <div className="right-side">
            <Button
              className="btn-link"
              color="danger"
              type="button"
              onClick={() => toggleJoinModal()}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </Modal>
      <div className="content">
        <Row>
          <Col md="12">
            <h4>Campeonatos</h4>
          </Col>
          {renderChampsList()}
        </Row>
      </div>
    </>
  );
}

export default JoinChamp;