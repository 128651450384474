import React, {useState} from "react";
import { useHistory } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import { showAlert } from '../../utils/Alert';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
} from "reactstrap";

import { Auth } from 'aws-amplify'
const alertRef = React.createRef();

const PasswordRest = props => {
  const history = useHistory();
  const [formData, setFormData] = useState({});
  const [resetPasswordForm, setResetPasswordForm] = useState(false); 

  const setFormValue = (fieldName , e) =>{    
    formData[fieldName] = e.target.value;
    setFormData(formData);    
  }  

  const resetUserPassword = (e) =>{
    e.preventDefault();
    e.stopPropagation();
    
    if(resetPasswordForm){
      updateUserPassword();
    }else{
      sendConfirmationCode();
    }
  }

  const sendConfirmationCode = () => {
    if(!formData["username"] ) {
      showAlert(alertRef, "danger", "Ingrese el email de su cuenta antes de continuar")
    }else{
      const trimUserName = formData["username"].replace(/\s/g, '');
      Auth.forgotPassword(trimUserName)
      .then(r =>{      
        setResetPasswordForm(true);
        showAlert(alertRef, "success", "Se ha enviado el correo con su codigo de verificacion")  
      })
      .catch(e=>{        
        if(e && e.code === "UserNotConfirmedException"){
          showAlert(alertRef, "danger", "Por favor active su cuenta, con el enlace que se le envio por correo electronico")
        } 
        else if(e && e.code === "UserNotFoundException"){
          showAlert(alertRef, "danger", "No existe un usuario con el correo electrónico ingresado")
        } 
        else{
          showAlert(alertRef, "danger", "No fue posible enviar la informacion para recuperar su contraseña");
        }
      });
    }
  }

  const updateUserPassword = () =>{
    const {password, verificacion , repassword} = formData;

    if(!verificacion ||  !password || !repassword){
      showAlert(alertRef, "danger", "Por favor ingrese la informacion solicitada");
      return;
    }

    if(password !== repassword){
      showAlert(alertRef, "danger", "La confirmacion de contraseña y la contraseña no son iguales");
      return;
    }
    const trimUserName = formData["username"].replace(/\s/g, '');

    Auth.forgotPasswordSubmit(trimUserName,verificacion,password)
      .then(r =>{      
        showAlert(alertRef, "success", "Su contraseña fue actualizada correctamente");
        setTimeout(() => {
          history.push("/auth/login");
        }, 1500);  
      })
      .catch(e=>{  
        console.log("error updating the password", e);
        if(e.name ==="InvalidParameterException"){
          showAlert(alertRef, "danger", "La contraseña debe tener al menos 8 caracteres");
        }else if(e.name ==="CodeMismatchException"){
          showAlert(alertRef, "danger", "El codigo de verificacion no es correcto");
        }else{          
          showAlert(alertRef, "danger", "No fue posible actualizar su contraseña");
        }
      });


  }

  const getFormFields = () =>{
    if(resetPasswordForm){
      return (
        <>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="nc-icon nc-alert-circle-i" />
              </InputGroupText>
            </InputGroupAddon>
            <Input 
            placeholder="Codigo Verificacion"
            name="verification" 
            type="text"
            autoComplete="off"
            onChange={(e)=>setFormValue("verificacion",e)} />
          </InputGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="nc-icon nc-key-25" />
              </InputGroupText>
            </InputGroupAddon>
            <Input 
            placeholder="Contraseña" 
            type="password"
            name="pass" 
            autoComplete="off"
            onChange={(e)=>setFormValue("password",e)} />
          </InputGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="nc-icon nc-key-25" />
              </InputGroupText>
            </InputGroupAddon>
            <Input 
            placeholder="Confirme la Contraseña" 
            type="password"
            name="repass" 
            autoComplete="off"
            onChange={(e)=>setFormValue("repassword",e)} />
          </InputGroup>        
          <Button
            block
            className="btn-round mb-3"
            color="success"
            type="submit"
          >
              Actualizar Contraseña 
          </Button> 
        </>                 
      );

    }else{
      return (
        <>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="nc-icon nc-single-02" />
              </InputGroupText>
            </InputGroupAddon>
            <Input 
            placeholder="Email" 
            type="text" 
            autoComplete="off"
            onChange={(e)=>setFormValue("username",e)} />
          </InputGroup>
          <p>Si su cuenta existe en nuestra plataforma le enviaremos un correo electrónico con la información para recuperar su contraseña.</p>
          <Button
            block
            className="btn-round mb-3"
            color="success"
            type="submit"
          >
              Recuperar Contraseña 
          </Button> 
        </>                 
      );
    }
  }

  return (
    <>
      <NotificationAlert ref={alertRef} />
      <div className="login-page">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <Form action="#" onSubmit={(e) => resetUserPassword(e)} className="form" method="">
                <Card className="card-login">
                  <CardHeader>
                    <CardHeader>
                      <h3 className="header text-center">Recuperar Contraseña</h3>
                    </CardHeader>
                  </CardHeader>
                  <CardBody>
                    {getFormFields()}
                  </CardBody>                  
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{                      
          backgroundImage: `url(${require("assets/img/bg/gt-champs/cars2.jpg")})`,          
          }}
        />
      </div>
      </>
    );
}

export default PasswordRest;
