
import React, {useEffect, useLayoutEffect, useState} from "react";
import {useParams} from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import { showAlert } from 'utils/Alert';
import { API } from "aws-amplify";
import { createPoint } from "graphql/mutations";
import { getTrack, pointsByTrack } from "graphql/queries";
import { useHistory } from "react-router-dom";
import { countryList } from 'utils/Constants';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { getNotDeletedItems } from "utils/Api";
import { champJoinsByChampAndType } from "graphql/queries";
import { driversByCountry } from "graphql/queries";
const alertRef = React.createRef();

const TrackNationPoints = props => {
  const [trackData, setTrackData] = useState({});
  const [pointsData, setPointsData] = useState([]);
  const [nationPoints, setNationPoints] = useState([]);
  const [contryCode, setCountry] = useState(0);
  const [nationPointsData, setNationPointsData] = useState({});
  
  const {trackId } = useParams();
  let history = useHistory();

  useEffect(() => {
    if(trackId){
      getTrackData(trackId);
    }
    if(contryCode){
      loadTrackPoint(contryCode);
    }
  }, [trackId, contryCode]); 

  useLayoutEffect(() => {
    updateNationPointsData();   
  }, [nationPoints.length]); 

  const updateNationPointsData = () =>{
    const points = nationPoints.reduce((a,np)=> a + np.points,0);
    const psnIds = nationPoints.map(np => `${np.psnId}=${np.points}`).join(", ");
    
    setNationPointsData({
      points,
      trackId,
      champId: trackData.champId,
      nationCode: contryCode,
      driversInclude:psnIds
    });
  }

  const getCountryDriversFilter = async (countryCode) =>{
    let drivers = []
    let driverData = await API.graphql( {
      query: driversByCountry,
      variables:{
        "country": countryCode, 
        "limit": 1500,
      },
      authMode: 'API_KEY'
    });
    if(driverData && driverData.data && driverData.data.driversByCountry){
      drivers = driverData.data.driversByCountry.items || [];
      drivers = getNotDeletedItems(drivers);
    }

    return drivers.map(e=>{
      return {
        "driverId":{
          "eq":e.id
        }
      }
    })
    
    
  }

  const loadTrackPoint = (contryCode) => {
    getCountryDriversFilter(contryCode).then(filters => {
      if(filters.length){
        API.graphql( {
          query: pointsByTrack,
          variables:{        
            "limit": 1500,
            "trackId":trackId,
            "filter":{
              "or":filters
            }
          },      
          authMode: 'API_KEY'
        })
        .then((d)=>{    
          let points = []
          if(d && d.data && d.data.pointsByTrack){
            points = d.data.pointsByTrack.items || [];
            points = getNotDeletedItems(points);
          }
          setPointsData(points.sort((a,b) => compareNations(a,b)));
          setNationPoints([]);
        }).catch((e)=>console.log("fetch champs error", e));
      }
    });    
  }

  function compareNations( a, b ) {
    if ( a.points > b.points ){
      return -1;
    }
    if ( a.points < b.points ){
      return 1;
    }
    return 0;
  }  

  const getTrackData = (trackId) => {
    API.graphql({
      query: getTrack,
      variables:{
        "id": trackId
      },
      authMode: 'API_KEY'
    }).then((ps)=>{
      let track = {};
      if(ps && ps.data && ps.data.getTrack){
        track = ps.data.getTrack || {};
      }
      setTrackData(track);
    }).catch((e)=>console.log("fetch track data error", e));    
  }

  const saveResult = (e) =>{
    e.preventDefault();
    e.stopPropagation();

    API.graphql({
      query: createPoint,
      variables:{
        "input": nationPointsData
      },
      authMode: 'API_KEY'
    })
    .then((ps)=>{
      showAlert(alertRef, "success", "Puntos Generados Correctamente" );          
    }).catch(e=>{
      console.log("error result data", e);
      showAlert(alertRef, "danger", "No fue posible generar los puntos" );
    });    
  }

  const tableHeaders = () =>{
    return (
      <Row key="headers">
        <Col sm="1">{""}</Col>
        <Col sm="2"><h6>Piloto</h6></Col>
        <Col sm="2"><h6>Puntos</h6></Col>    
        <Col sm="2"><h6>Tomar en cuenta</h6></Col>    
      </Row>
    );
  } 

  const updateNationPoints = (input, driverId, points, psnId) => { 
    let newNationPoints =[...nationPoints];

    if(input.checked){
      newNationPoints.push({
        driverId,
        points,
        psnId});
    }else{
      newNationPoints = newNationPoints.filter(tp => tp.driverId != driverId);
    }
    setNationPoints(newNationPoints);
  }

  const isDriverSelected = (driverId) =>{
    const driverPoints = nationPoints.find(tp => tp.driverId == driverId);
    return driverPoints != null; 
  }

  const getResultEntries = () =>{
    let rows =[];
    if(pointsData.length){
      rows.push(tableHeaders()); 
    }
    pointsData.forEach((e,i) => {
      rows.push(
        (
        <Row key={`position${i}`}>
          <Col sm="1">{""}</Col>
          <Col sm="2">{e.psnId}</Col>
          <Col sm="2">{e.points}</Col>   
          <Col sm="1">
            <FormGroup check inline>
                <Label check>
                  <Input 
                    checked={isDriverSelected(e.driverId)}
                    onChange={(ev)=>updateNationPoints(ev.target, e.driverId, e.points, e.psnId)}
                    type="checkbox"
                  />
                  <span className="form-check-sign" /> {""};
                </Label>
            </FormGroup>                           
          </Col> 
        </Row>
        )
      );
    });
    return rows;
  }

  return (
    <>
      <NotificationAlert ref={alertRef} />
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Generar Puntos Para Pais</CardTitle>
              </CardHeader>
              <CardBody>
                <Form action="#" onSubmit={(e)=> saveResult(e)} className="form-horizontal" method="get">
                  <Row>
                    <Label sm="2">Pais</Label>  
                    <Col sm="4">
                      <FormGroup>
                        <Input
                          type="select"
                          onChange={(v)=>setCountry(v.target.value)}
                        > 
                          <option>Seleccione uno</option>
                          {Object.keys(countryList).map(countrycode => (
                            <option key={`option-${countrycode}`} value={countrycode}>{countryList[countrycode]}</option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>                                
                  </Row>
                  <hr/>
                  <h4> Resultado(s)</h4>
                  {getResultEntries()}
                  <hr/>
                  <Row>
                    <Col sm="1"></Col>
                    <Col sm="11">
                      <h6> Puntos Pais  
                        <span className="team-points btn btn-primary">{nationPointsData.points || 0}</span>
                      </h6>
                    </Col>
                  </Row>                 
                  <Row>
                    <Col sm={{size:'2', offset:'3'}}>
                      <Button color="success">
                        <span className="btn-label">
                          <i className="nc-icon nc-check-2" />
                        </span>
                          Guardar
                      </Button> 
                    </Col>
                    <Col sm={{size:'4'}}>
                      <Button 
                        className="btn btn-danger" 
                        onClick={()=> 
                          history.push("/admin/admin-calendar/"+(trackData && trackData.champId ? trackData.champId: ""))
                        }
                        color="danger">
                        <i className="nc-icon nc-simple-remove" />
                          Cerrar
                      </Button>
                    </Col>
                  </Row>                
                </Form>
              </CardBody>
            </Card>
          </Col>          
        </Row>
      </div>
    </>
  );
}

export default TrackNationPoints;