
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {useParams } from "react-router-dom";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap"; 
import { API } from "aws-amplify";
import { getNotDeletedItems } from "utils/Api";
import { driversByTeam } from "graphql/queries";
import { updateDriver } from "graphql/mutations";

const TeamMembers = props => {
  const history = useHistory();
  const [driverList, setDriverList] = useState([]);
  const [initialLoad, setInitialLoad] = useState(false);
  const [alert, setAlert] = useState(null);
  const { id:teamId } = useParams();

  const getTeamId = () =>{
    return teamId || props.teamId;
  }

  useEffect(() => {
    fetchDriverList();
  },[initialLoad, props.teamId, props.reloadMembers]);

  const fetchDriverList = () =>{
    API.graphql( {
      query: driversByTeam,
      variables:{
        "teamId": getTeamId(), 
        "limit": 1500,
      },
      authMode: 'API_KEY'
    }).then((d)=>{
      let drivers = []
      if(d && d.data && d.data.driversByTeam){
        drivers = d.data.driversByTeam.items || [];
      }
      drivers = getNotDeletedItems(drivers);
      setDriverList(drivers);
      setInitialLoad(true);
    }).catch((e)=>console.log("fetch team member error", e));
  }

  const deleteElement = (id, version) => {
    API.graphql({
      query: updateDriver,
      variables:{
       input: {
        "id": id,
        "teamId" : null,
        "_version":version
       } 
      },
      authMode: 'API_KEY'
    })
    .then(r =>{
      setAlert(
        (
          <ReactBSAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Expulsion Exitosamente"
            onConfirm={() => {
              setAlert(null);
              fetchDriverList();
              }
            }            
            confirmBtnBsStyle="info"
            btnSize=""
          />          
        )
      );
    }).catch(e =>{
      console.log("save error",e);
      setAlert(
        (
          <ReactBSAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="Error al expulsar al piloto"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="cancel"
          btnSize=""
        />
        )
      );
    });    
  }

  const renderDriversList = () =>{
    return driverList.map((e)=>{
      return (
        <tr key={`driver-${e.id}`}>        
          <td className="text-center">{e.name}</td>          
          <td className="text-center">{e.psnId}</td>         
          <td className="text-right">            
            <Button
              className="btn-icon"
              color="danger"
              id={`driver-eject-${e.id}`}
              size="sm"
              type="button"
              onClick ={() => deleteElement(e.id, e._version)}
            >
              <i className="fa fa-times" />
            </Button>{" "}
            <UncontrolledTooltip
              delay={2}
              target={`driver-eject-${e.id}`}
            >
              Expulsar
            </UncontrolledTooltip>                       
          </td>
        </tr> 
      );
    })    
  }

  return (
    <>
      {alert}
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Lista de Pilotos
                  <Button
                      onClick ={()=> history.push(`/admin/team-join-request/${getTeamId()}/`)}
                      className="btn btn-info pull-right header-link"
                      id="tooltip8694850302"
                      size="sm"
                      type="button"
                    >
                      Solicitudes de Ingreso
                  </Button>
                </CardTitle>                
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th className="text-center">Nombre Piloto</th>
                      <th className="text-center">PSN ID</th>
                      <th className="text-right">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderDriversList()}                                                   
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default TeamMembers;