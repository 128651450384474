
import React, { useEffect, useLayoutEffect, useState } from "react";
import { pointsByChamp, champJoinsByChampAndType, tracksByChamp } from "graphql/queries";
import { useParams } from "react-router";

import {
  Table,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import { getUserData } from "utils/Session";
import { API } from "aws-amplify";
import { getNotDeletedItems } from "utils/Api";

const Drivers = props => {
  const { champId } = useParams();
  const [driverResultList, setDriverResultList] = useState([]);
  const [champDriverList, setChampDriverList] = useState([]);
  const [driverChampStatus, setDriverChampStatus] = useState([]);
  const [champTracks, setChampTracks] = useState([]);
  const [userData, setUserData] = useState({});
  const [initialLoad, setInitialLoad] = useState(false);

  useEffect(() => {
    createDriverResult()
  }, [driverChampStatus.length, driverResultList.length, champDriverList.length, champTracks.length]);

  useLayoutEffect(() => {
    if (!userData.id) {
      fetchUserData();
    }
    if (champId) {
      fetchChampTracks(champId);
      fetchChampDrivers(champId);
      fetchDriversResult(champId);
    }
  }, [initialLoad, champId]);

  const fetchUserData = () => {
    getUserData()
      .then(data => {
        if (data.driverId) {
          setUserData(data);
        }
      })
      .catch((e) => {
        console.log("Error getting user data", e);
      });
  }

  function compareOrder(a, b) {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  }

  const compareDrivers = (a, b, field) => {
    let nameA = a[field] || "";
    let nameB = b[field] || "";

    if (nameA.toLowerCase() < nameB.toLowerCase()) {
      return -1;
    }
    if (nameA.toLowerCase() > nameB.toLowerCase()) {
      return 1;
    }
    return 0;
  }
  const compareDriversPoints = (a, b) => {
    let nameA = a["totalPoints"] || 0;
    let nameB = b["totalPoints"] || 0;

    if (nameA > nameB) {
      return -1;
    }
    if (nameA < nameB) {
      return 1;
    }
    return 0;
  }

  const fetchChampTracks = (champId) => {
    API.graphql({
      query: tracksByChamp,
      variables: {
        "champId": champId,
        filter: {
          "prequaly": {
            "ne": true
          }
        }
      },
      authMode: 'API_KEY'
    })
      .then((t) => {
        let tracks = []
        if (t && t.data && t.data.tracksByChamp) {
          tracks = t.data.tracksByChamp.items || [];
        }
        tracks = getNotDeletedItems(tracks);
        setChampTracks(tracks.sort((a, b) => compareOrder(a, b)));
      }).catch((e) => console.log("fetch champs error", e));
  }

  const fetchChampDrivers = (champId) => {
    API.graphql({
      query: champJoinsByChampAndType,
      variables: {
        "champId": champId,
        "limit": 1500,
        "type": {
          "eq": "driver"
        },
        filter: {
          "status": {
            "eq": "accepted"
          }
        }
      },
      authMode: 'API_KEY'
    })
      .then((d) => {
        let drivers = []
        if (d && d.data && d.data.champJoinsByChampAndType) {
          drivers = d.data.champJoinsByChampAndType.items || [];
        }
        drivers = getNotDeletedItems(drivers);
        setChampDriverList(drivers);
      }).catch((e) => console.log("fetch champs error", e));
  }

  const fetchDriversResult = (champId) => {
    API.graphql({
      query: pointsByChamp,
      variables: {
        "champId": champId,
        "limit": 1500,
      },
      authMode: 'API_KEY'
    })
      .then((p) => {
        let points = []
        if (p && p.data && p.data.pointsByChamp) {
          points = p.data.pointsByChamp.items || [];
        }
        points = getNotDeletedItems(points);
        setDriverResultList(points);
        setInitialLoad(true);
      }).catch((e) => console.log("fetch champs error", e));
  }

  const getTrackPoint = (track, trackPoints) => {
    if (track.pointsVisible)
      return (trackPoints && trackPoints.points) ? trackPoints.points : 0;
    return 0;
  }
  const getDriverTotalPoint = (driverResults) => {
    const tracksWithPointsVisible = champTracks.filter(ct => ct.pointsVisible);
    return driverResults.reduce((total, dr) => {
      if (tracksWithPointsVisible.find(t => t.id === dr.trackId)) {
        return total + (dr.points || 0);
      } else return total;
    }, 0);
  }

  const createDriverResult = () => {
    const champStatus = champDriverList.map(d => {
      const driverResults = driverResultList.filter(dr => dr.driverId === d.driverId && dr.champId === champId);
      const totalPoints = getDriverTotalPoint(driverResults);
      const trackResult = champTracks.map(ct => {
        const trackPoints = driverResults.find(dr => dr.trackId === ct.id);
        const points = getTrackPoint(ct, trackPoints);
        return {
          "trackId": ct.id,
          "points": points
        }
      });
      return {
        "driverId": d.driverId,
        "psnId": d.psnId,
        "totalPoints": totalPoints,
        "trackResult": trackResult
      }
    }).sort((a, b) => compareDriversPoints(a, b) || compareDrivers(a, b, "psnId"));
    setDriverChampStatus(champStatus);
  }

  const renderDriverData = () => {
    if (!driverChampStatus || driverChampStatus.length === 0) {
      return null;
    }

    return driverChampStatus.map((d, i) => {
      return (
        <tr className={(userData && userData.driverId === d.driverId ? "currentUser" : "")} key={`driver-${i}`}>
          <td>P{i + 1}</td>
          <td>{d.psnId}</td>
          {
            d.trackResult.map(t => (
              <td key={`track-header-${d.driverId}-${t.trackId}`}>{t.points}</td>
            )
            )
          }
          <td className="text-right">{d.totalPoints}</td>
        </tr>
      )
    }
    );
  }

  const renderTrackHeaders = () => {
    return champTracks.map(t => (
      <th key={`track-header-${t.order}`}>Fecha {t.order}</th>
    )
    );
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="8">
                    <CardTitle tag="h4">Campeonato de Pilotos</CardTitle>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Piloto</th>
                      {renderTrackHeaders()}
                      <th className="text-right">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {initialLoad && renderDriverData()}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Drivers;