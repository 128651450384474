import React, { useState, useEffect} from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLogin } from '../../utils/Session';

const PublicRoute = ({component: Component, restricted, ...rest}) => {
    const [isUserLoggedIn, setLoginState] = useState();
    useEffect(() => {
        isLogin().then(isLoggedIn => setLoginState(isLoggedIn));
      }, []);
    return  isUserLoggedIn === undefined ? null : (        
        <Route {...rest} render={props => (
            isUserLoggedIn ?                
            <Component {...props} />
            : <Redirect to="/auth/login" />
        )} />
    );
};

export default PublicRoute;